export const SET_KYC = 'SET_KYC';
export const SET_ADDRESS = 'SET_ADDRESS';
export const INIT = {
    address: '',
    kyc: {
        loading: true,
        status: '',
        wallet: {
            address: '',
            chain: '',
        },
    },
};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ADDRESS:
            return { ...state, address: payload };
        case SET_KYC:
            return { ...state, kyc: { ...payload, loading: false } };
        default:
            return state;
    }
}

export default reducer;
