import { CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import Button from 'components/Button';
import useTransactions from 'hooks/useTransactions';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChain } from 'redux/actions/chain';
import { setSnackbar } from 'redux/actions/snackbar';
import { ContractContext } from 'service/provider';
import { addPolygonToMetamask } from 'utils/add-to-metamask';
import { MATIC_CHAIN } from 'utils/variables';
import useStyles from './styles';

const Profile = ({ setConnection, setShowTx }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { pendingTransactions } = useTransactions();
    const { chain, theme } = useSelector(store => store);
    const { wallet, loadingContractInfo, userLoading } = useContext(ContractContext);

    const switchToMatic = async () => {
        if (window.ethereum) {
            try {
                await addPolygonToMetamask();
                dispatch(setChain(MATIC_CHAIN))
                wallet.reset();
                dispatch(setSnackbar({ message: 'Successfully switched to Polygon network. You may now connect your wallet.', type: 'success' }))
            } catch (err) { dispatch(setSnackbar({ message: 'Unable to switch networks. Please try manually'})) }
        } else dispatch(setSnackbar({ message: 'Ethereum provider not found.'}))
    }

    const disconnectWallet = (ev) => {
        ev.stopPropagation();
        wallet.reset();
    };

    if (wallet.status === 'connected' && wallet.account) {
        if (pendingTransactions.length > 0)
            return (
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={clsx(classes.profile, classes.pendingTxContainer)}
                    onClick={() => setShowTx(true)}
                >
                    <Grid item>
                        <CircularProgress size={10} className={classes.whiteText} />
                        &nbsp;&nbsp;
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" className={classes.whiteText}>
                            {pendingTransactions.length} Pending
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ marginTop: 4 }} className={classes.whiteText}>
                            <ChevronRightIcon fontSize="small" />
                        </Typography>
                    </Grid>
                </Grid>
            );
        else
            return (
                <span className={classes.profile} onClick={() => setShowTx(true)}>
                    {wallet.account.shortenAddress()}
                    {!loadingContractInfo && !userLoading && (
                        <Tooltip title="Disconnect Wallet" placement="bottom-end" arrow>
                            <Close className={classes.disconnect} onClick={disconnectWallet} />
                        </Tooltip>
                    )}
                </span>
            );
    } else {
        if (wallet.chainId !== chain) return (
            <Button color="primary" variant="contained" onClick={() => switchToMatic()}>
                Switch Network
            </Button> 
        )
        else return (
            <Button
                color="primary"
                variant="contained"
                override={theme.mode === 'dark' ? "blue" : 'supernova'}
                onClick={() => setConnection({ open: true, connect: wallet.connect })}
            >
                Connect
            </Button>
        );
    }
}

export default Profile;
