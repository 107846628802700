import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto'
        }
    }
}));

export default useStyles;
