import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
    },
    logo: {
        width: '100%',
        maxWidth: '700px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
    link: {
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'underline'
    }
}));

export default useStyles;
