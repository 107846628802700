import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    banner: {
        padding: 0,
    },
    textLink: {
        cursor: 'pointer',
    },
    icon: {
        filter: theme.mode === 'supernova' ? 'sepia(20%) saturate(3000%) brightness(95%)' : undefined,
    }
}));

export default useStyles;
