import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.2rem',
        paddingBottom: theme.spacing(2)
    },
    emptyText: {
        color: theme.brand.GrayChateau,
        fontSize: '1rem',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    collection: {
        maxWidth: 1500,
        borderRadius: 10,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: `2px solid ${theme.brand.border}`,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            filter: 'brightness(0.8)',
        }
    },
    nftName: {
        fontWeight: 300,
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    nft: {
        width: '100%',
        borderRadius: 10,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            boxShadow: `0px 0px 5px ${theme.brand.colliderBlue}`,
        }
    },
    nftContainer: {
        cursor: 'pointer',
    },
    selected: {
        boxShadow: `0px 0px 5px ${theme.brand.colliderOrange}`,
        '&:hover': {
            boxShadow: `0px 0px 5px ${theme.brand.colliderOrange}`,
        }
    }
}));

export default useStyles;
