import React, { useState, useEffect, useContext } from 'react';
// Modules
import { useLocation } from 'react-router-dom';
import { ContractContext } from 'service/provider';
// Components
import { Slide, Hidden } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import NavDrawer from './NavDrawer';
import Button from 'components/Button';
import NavigationBar from './NavigationBar';
import ConnectWalletModal from 'components/Modals/ConnectWalletModal';
// Styles
import useStyles from './styles';
import errorTranslate from 'utils/error-translators';
import { useSelector } from 'react-redux';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Navigation({ children, ...props }) {
    const classes = useStyles();
    const location = useLocation();
    const { theme } = useSelector(store => store);
    const [connection, setConnection] = useState({
        open: false,
        connect: null,
    });

    const { wallet } = useContext(ContractContext);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    useEffect(() => {
        setMobileDrawerOpen(false);
    }, [location]);

    return (
        <div className={classes.root}>
            <Hidden lgUp>
                <NavigationBar onMenuClicked={() => setMobileDrawerOpen(true)} />
            </Hidden>
            <NavDrawer
                open={mobileDrawerOpen}
                onOpen={() => setMobileDrawerOpen(true)}
                onClose={() => setMobileDrawerOpen(false)}
            />
            <main className={classes.content}>
                <Slide direction="down" in={!!wallet.error} mountOnEnter unmountOnExit>
                    <div className={classes.error}>
                        <Alert variant="filled" severity={'error'} className={classes.error}>
                            {errorTranslate(wallet?.error?.message ?? 'Something went wrong... Please refresh and try again!', wallet.reset)}
                        </Alert>
                    </div>
                </Slide>
                <Slide
                    direction="down"
                    in={
                        wallet.status !== 'connected' && !window.ethereum?.selectedAddress
                            ? true
                            : false
                    }
                    mountOnEnter
                    unmountOnExit
                >
                    <div className={classes.connect}>
                        <Button
                            color="primary"
                            variant="contained"
                            override={theme.mode === 'supernova' ? "supernova" : 'blue'}
                            onClick={() => setConnection({ open: true, connect: wallet.connect })}
                        >
                            Connect Your Wallet
                        </Button>
                    </div>
                </Slide>
                {children}
            </main>

            <ConnectWalletModal
                open={connection.open && !wallet.error}
                onClose={() => setConnection({ open: false })}
                connect={connection.connect}
                wallet={wallet}
            />
        </div>
    );
}

export default Navigation;
