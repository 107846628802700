import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    deposit: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '1.5rem',
        textTransform: 'uppercase',
    },
    getStarted: {
        fontSize: '1.2rem',
        textAlign: 'center',
        lineHeight: 'normal',
        padding: theme.spacing(1),
        textTransform: 'uppercase',
        color: theme.brand.GrayChateau,
    },
    colliderInput: {
        display: 'flex',
        alignItems: 'center',
        
        width: '250px',
        height: '250px',
        
        backgroundColor: '#162735',
        transition: 'border 0.5s ease',
        border: `5px solid ${theme.brand.colliderBlue}`,
    },
    pointer: {
        cursor: 'pointer',
    },
    nft: {
        width: '100%',
    },
    active: {
        filter: 'brightness(50%)',
    },
    colliding: {
        border: `5px solid ${theme.brand.GrayChateau}`,
    }
}));

export default useStyles;
