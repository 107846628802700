import React from 'react';

import useStyles from './styles';
import InnerPage from './InnerPage';

const Page = ({ children, header, ...props }) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root} {...props}>
            <InnerPage classes={{ root: classes.inner }}>
                {header}
                {children}
            </InnerPage>
        </div>
    );
};

export default Page;
