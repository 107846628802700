import { useState, useEffect } from 'react';

const Countdown = ({ end }) => {
    const [components, setComponents] = useState({ h: '0', m: '0', s: '0' });

    useEffect(() => {
        const interval = setInterval(() => {
            const timeUntilEnd = new Date(end - new Date().getTime()).toISOString().substr(11, 8);
            const comps = timeUntilEnd.split(':');
            setComponents({
                h: +comps[0],
                m: +comps[1],
                s: +comps[2],
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [end]);

    return `${components.h}h ${components.m}m ${components.s}s`;
};

export default Countdown;
