import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RedirectRoute = ({ toPath, fromPath }) => {
    return (
        <Route
            exact
            path={fromPath}
            render={() => (
                <Redirect
                    to={{
                        pathname: toPath,
                        search: window.location.search,
                    }}
                />
            )}
        />
    );
};

export default RedirectRoute;
