import React, { useContext, useState, useEffect } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import Card from 'components/Card';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TOKENS_DATA } from 'utils/variables';
import CardBody from 'components/Card/CardBody';
import CustomTable from 'components/CustomTable';
import CardTitle from 'components/Card/CardTitle';
import tokens from 'containers/Accelerator/tokens';
import { ContractContext } from 'service/provider';
import { Typography, Tooltip } from '@material-ui/core';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import WithdrawDivsModal from 'components/Modals/WithdrawDivsModal';

const TableHeaders = ['Token', 'Symbol', 'Rewards', 'Value (USD)', ''];

const VentureTable = (props) => {
    const history = useHistory();
    const classes = useStyles(props);
    const { user, userLoading } = useContext(ContractContext);
    const { accelerator: { tokenPrices } } = useSelector(store => store) // using wBTC from here since dex was giving wrong number for some reason

    const [divs, setDivs] = useState([]);
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState(null)

    useEffect(() => {
        setDivs(user.ventureDivs);
    }, [user?.ventureDivs]);

    async function handleWithdraw(token) {
        setToken(token)
        setOpen(true);
    }

    function handleUseInAccelerator(token) {
        history.push(`/accelerator/${token.tokenSymbol}`, { usingDivs: true })
    }

    return (
        <Card classes={{ root: classes.root }}>
            <CardTitle classes={{ root: classes.cardTitle }}>
                <Typography variant="h3">My Rewards</Typography>
            </CardTitle>
            <CardBody
                classes={{
                    root: clsx({
                        [classes.banner]: !userLoading && divs.length === 0,
                    }),
                }}
            >
                <CustomTable
                    pagination={false}
                    count={divs.length}
                    initialOrderIndex={0}
                    headers={TableHeaders}
                    classes={{ root: classes.table }}
                    rows={(userLoading ? Array.from(new Array(4)) : divs).map((item, index) => {
                        if (!item) return { columns: SkeletonTableRow({count: 5}) };
                        const symbol = item.tokenSymbol.toLowerCase();
                        return {
                            columns: [
                                <Tooltip
                                    arrow
                                    title={TOKENS_DATA[symbol]?.tooltip}
                                >
                                    <span
                                        className={classes.textLink}
                                        style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                                        onClick={() =>
                                            window.open(
                                                TOKENS_DATA[symbol]?.url
                                            )
                                        }
                                    >
                                        <img alt="reward icon" src={TOKENS_DATA[symbol]?.icon} width={25} className={classes.icon} />&nbsp;&nbsp;{item?.tokenName?.replace("(PoS)", '')}{symbol === "axn" ? " (Sell Tax)" : ""}
                                    </span>
                                </Tooltip>,
                                <Tooltip
                                    arrow
                                    title={TOKENS_DATA[symbol]?.tooltip}
                                >
                                    <span
                                        className={classes.textLink}
                                        onClick={() =>
                                            window.open(
                                                TOKENS_DATA[symbol]?.url
                                            )
                                        }
                                    >
                                        {item.tokenSymbol}
                                    </span>
                                </Tooltip>,
                                <Tooltip arrow title={item.interestEarnedToken.toFixed(symbol === "axn" ? 2 : 8)}>
                                    <span>
                                        {parseFloat(item.interestEarnedToken).toLocaleString("en-US", {
                                            minimumFractionDigits: symbol === "axn" ? 0 : 8,
                                            maximumFractionDigits: symbol === "axn" ? 2 : 8,
                                        })}
                                    </span>
                                </Tooltip>,
                                <Tooltip arrow title={`This amount is based on the current price of ${symbol} and will fluctuate.`}>
                                    <span>
                                        {((item.interestEarnedToken * (symbol === "axn" ? tokenPrices.axn : tokenPrices.wBTC)) || item.interestEarnedUSDC).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </span>
                                </Tooltip>,
                                <HorizontalCell>
                                    <Button
                                        fullWidth
                                        size="small"
                                        override="translucent"
                                        onClick={() => handleWithdraw(item)}
                                        disabled={parseFloat(item.interestEarnedToken) === 0}
                                    >
                                        Withdraw
                                    </Button>
                                    {symbol !== "axn" && token?.divToken && (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                fullWidth
                                                size="small"
                                                override="translucent"
                                                onClick={() => handleUseInAccelerator(item)}
                                                disabled={
                                                    !tokens.find(t => t.symbol.toLowerCase() === symbol) ||
                                                    +item.interestEarnedToken === 0
                                                }
                                            >
                                                Use in Accelerator
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </HorizontalCell>,
                            ],
                        };
                    })}
                />
            </CardBody>

            <WithdrawDivsModal data={divs} open={open} onClose={() => setOpen(false)} token={token} />
        </Card>
    );
};

export default VentureTable;
