import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    next: {
        display: 'flex',
        alignItems: 'center',
    },
    nextIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1.5rem',
    },
    approvalIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '1.2rem',
    },
    backIcon: {
        marginRight: theme.spacing(0.5),
        fontSize: '1.5rem',
    }
}));

export default useStyles;
