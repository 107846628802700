import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 300
    },
   bold: {
       fontWeight: 500
   }
}));

export default useStyles;
