import React, { useContext, useMemo } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router';
import { ContractContext } from 'service/provider';
import { COLLIDER_COLLECTION } from 'utils/variables';
import { Grid, Grow, Typography } from '@material-ui/core';
import ParticleCard from './ParticleCard/ParticleCard';

const ParticleCollection = ({ nft, setNFT, colliding, collided }) => {
    const classes = useStyles();
    const history = useHistory();

    const { user, userLoading } = useContext(ContractContext);

    const userNFTs = useMemo(() => {
        if (user) {
            return user.nfts.filter(n => n.type === 'particle').sort((a, b) => b.metadata.particles - a.metadata.particles);
        } else return []
    },[user]);

    const goToAccelerator = () => history.push('/accelerator');
    const goToOpensea = () => window.open(COLLIDER_COLLECTION, '_blank');
    const selectNFT = (nft) => { if (!colliding && !collided) setNFT(nft) }

    return (
        <div className={classes.collection}>
            <Typography className={classes.title}>Available Particle NFTs</Typography>
            
            {userNFTs.length === 0 && (
                <div className={classes.empty}>
                    <Typography className={classes.emptyText}>
                        No Axion Particle NFTs detected! <br />
                        {!user ? (userLoading ? "Loading Particle NFTs..." : "Please connect your wallet.") : (
                            <React.Fragment>
                                Participate in the <span className={classes.link} onClick={goToAccelerator}>Accelerator</span> to earn Particle NFTs <br />
                                or buy them on the <span className={classes.link} onClick={goToOpensea}>Opensea Marketplace</span>.
                            </React.Fragment>
                        )}
                    </Typography>
                </div>
            )}

            {userNFTs.length > 0 && (
                <Grid container spacing={2}>
                    {userNFTs.map((n, i) => (
                        <Grow in={true} unmountOnExit key={n.metadata.id} timeout={{ enter: (i + 1) * 250 }}>
                            <Grid key={`${n.name}_${i}`} item lg={2} md={3} sm={4} xs={6} className={classes.nftContainer} onClick={() => selectNFT(n)}>
                                <ParticleCard nft={n} selected={n.metadata.id === nft?.metadata?.id} />
                            </Grid>
                        </Grow>
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default ParticleCollection;
