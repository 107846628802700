import { CHAIN } from "utils/variables";
export const SET_CHAIN = 'SET_CHAIN';
export const INIT = CHAIN;

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_CHAIN:
            return payload;
        default:
            return state;
    }
}

export default reducer;
