import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import useTransactions from 'hooks/useTransactions';
import { useHistory } from 'react-router';
import { openTx } from 'utils/open-etherscan';
import useStyles from './styles';
import Lottie from 'react-lottie';
import animation from './animation.json';

const StakeConfirmation = ({  stakeInfo, bonus, setInterfaceType }) => {
    const classes = useStyles();
    const history = useHistory();
    const { transactions } = useTransactions();

    const handleRedirectToStakes = () => {
        setInterfaceType('basic')
        history.push('/stake', { scroll: true })
    }

    const handleRedirectToCollider = () => {
        setInterfaceType('basic')
        history.push('/collider')
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="space-between" style={{height: '100%'}}>
                <Grid item>
                    <Typography className={classes.title} align="center">Transaction Successfully Completed!</Typography>
                    <Typography className={classes.description}>
                        Congratulations! Your stake has successfully been created and saved into the blockchain.
                        You can view and follow your new investment over on the "Stake" page by clicking the button below!
                        You also earned an Axion Particles NFT that can be&nbsp;
                        <span className={classes.collider} onClick={() => handleRedirectToCollider()}>redeemed in the Collider</span>&nbsp;
                        for a chance to mint a bonus stake!
                    </Typography>     
                </Grid>
                <Grid item>
                    <Lottie
                        speed={0.2} 
                        width={300}
                        height={300} 
                        options={{
                            loop: true,
                            animationData: animation
                        }} 
                    />
                </Grid>

                <Grid item>
                    <Grid container justify="space-evenly" spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Button fullWidth variant="outlined" onClick={() => handleRedirectToStakes()}>View Stake</Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Button fullWidth variant="outlined" onClick={() => handleRedirectToCollider()}>Engage the Collider</Button>
                        </Grid>
                    </Grid>
                    {transactions[0] && (
                        <Typography className={classes.openTx} onClick={() => openTx(transactions[0].id)} align="center">
                            Need more information? Click here to view on Polygonscan.
                        </Typography>
                    )}
                </Grid>
               
            </Grid>
        </div>
    );
};

export default StakeConfirmation;
