import React, { useRef } from 'react';

import classNames from 'classnames';
import { sum, uniqueId } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AxnValueTooltip from 'components/AxnValueTooltip';
// Styles
import styles from './styles';
import { useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const SharesBarChartDesktop = ({ classes, values }) => {
    const id = useRef(uniqueId());
    const { theme } = useSelector(store => store);
    const total = sum(values.map(({ axn }) => axn));

    return (
        <div className={classes.root}>
            <div className={classes.bar}>
                {values.map(({ axn, date, paid }, i) => {
                    return (
                        <div
                            key={`${id.current}-segment-${i}`}
                            className={classNames(
                                classes.segment,
                                { [`${classes.up}`]: i % 2 !== 0 },
                                { [`${classes.down}`]: i % 2 === 0 }
                            )}
                            style={{
                                backgroundColor: theme.brand.BPDColors[i],
                                flexBasis: `${(axn / total) * 100}%`,
                            }}
                        >
                            <div
                                className={classNames(classes.line, {
                                    [`${classes.lineUp}`]: i % 2 !== 0,
                                    [`${classes.lineDown}`]: i % 2 === 0,
                                })}
                                style={{ backgroundColor: theme.brand.BPDColors[i] }}
                            ></div>
                            <div
                                className={classNames(classes.dotContainer, {
                                    [`${classes.dotTop}`]: i % 2 !== 0,
                                    [`${classes.dotBottom}`]: i % 2 === 0,
                                })}
                            >
                                <div
                                    className={classes.dot}
                                    style={{ backgroundColor: theme.brand.BPDColors[i] }}
                                ></div>
                                <Typography
                                    variant="overline"
                                    className={classes.bpd}
                                    style={{ color: theme.brand.BPDColors[i] }}
                                >
                                    BPD {i + 1}
                                </Typography>
                                <div
                                    className={classNames(classes.info, {
                                        [`${classes.infoTop}`]: i % 2 !== 0,
                                        [`${classes.infoBottom}`]: i % 2 === 0,
                                    })}
                                >
                                    <div className={classes.infoLine}>
                                        <Typography className={classNames(classes.axion, { [classes.paid]: paid} )}>
                                            <AxnValueTooltip value={axn} suffix="" fixed={0} /><span className={classes.axionLabel}> AXN</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.infoLine}>
                                        <Typography className={classNames(classes.smallLabel, { [classes.paid]: paid })}>
                                            Date {date}{paid && <CheckCircleOutlineIcon className={classes.check} />}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {i === values.length - 1 && (
                                <div className={classes.bigPayDay}>
                                    <Typography className={classes.smallLabel}>
                                        Total Bigpaydays:
                                    </Typography>
                                    <Typography className={classes.bigPayDayAmt}>
                                        <AxnValueTooltip value={total} fixed={0} suffix="" />
                                    </Typography>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(SharesBarChartDesktop);
