import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bpdItemVertical: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1.5),
    },
    bpd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            alignItems: 'center',
        },

        '& > div': {
            borderRight: `1px solid ${theme.brand.border}`,
        },
        '& > div:last-child': {
            borderRight: `none`,
        },
    },
    bpdItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginRight: 5,
        paddingRight: 5,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
        },
    },
    check: {
        fontSize: 18,
        marginRight: 4,
        color: theme.brand.JavaGreen,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    close: {
        fontSize: 18,
        marginRight: 4,
        color: theme.brand.error,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    bpdTextInactive: {
        fontSize: 12,
        color: theme.brand.GrayChateau,
    },
    bpdTextActive: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    bpdText: {
        fontSize: 12,
    },
}));

export default useStyles;
