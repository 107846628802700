import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),        
    },
    searchIcon: {
        color: theme.brand.SecondaryText
    },
    input: {
        color: theme.brand.SecondaryText
    },
    filters: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    menu: {
        padding: 0,
        backgroundColor: `${theme.brand.CardBG}F2`,
    },
    menuItem: {
        padding: 0
    },
    popover: {
        width: '200px',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: `${theme.brand.CardBG}F2`,
    }
}));

export default useStyles;
