import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    inner: {},
}));

export default useStyles;
