import axios from 'axios';
import { ETHEREUM_TOKEN, CONTRACT_INFO } from 'utils/variables';

/** Gets the price in USD of any token from Coingecko
 * @param {object} token The token
 * @returns {Promise<any>} The current price in USD
 */
export const getPrice = async (token) => {
    if (!token) return 0;
    if (token.address === ETHEREUM_TOKEN.tokenAddress || token.address === CONTRACT_INFO.Tokens.WETH) {
        const tokenID = token.address === ETHEREUM_TOKEN.tokenAddress ? "matic-network" : "ethereum";
        const response = await axios(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenID}&vs_currencies=usd`);
        return response.data[tokenID].usd;
    } else {
        const network = 'polygon-pos';
        const response = await axios(`https://api.coingecko.com/api/v3/simple/token_price/${network}?contract_addresses=${token.address}&vs_currencies=usd`);
        const data = response.data[token.address.toLowerCase()];
        if (data) return data.usd;
        else return 0;
    }
};
