import React, { useMemo, useContext } from 'react';
import Vote from '../Vote';
import gfm from 'remark-gfm';
import useStyles from './styles';
import Results from '../Results';
import VotersTable from '../VotersTable';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import VoteInformation from '../VoteInformation';
import VoterInformation from '../VoterInformation';
import { ContractContext } from 'service/provider';
import { ArrowBack, InfoOutlined } from '@material-ui/icons';
import { Button, Typography, Card, Grid, Tooltip } from '@material-ui/core';

const ProposalDetail = ({ proposal, voteData, refetchVotes }) => {
    const classes = useStyles();
    const history = useHistory();
    const { wallet, ethWeb3, user } = useContext(ContractContext);
    const isOver = useMemo(() => proposal.end <= Date.now() / 1000, [proposal]);

    const open = (url) => window.open(url, '_blank');

    const hasVoted = useMemo(() => {
        if (voteData.done && wallet && voteData.voters[wallet.account]) return true;
        else return false;
    }, [wallet, voteData]);

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                onClick={() => history.push('/voting')}
                className={classes.button}
            >
                <ArrowBack fontSize="small" />
                Back to Proposals
            </Button>

            <Grid container spacing={2} justify="space-evenly">
                <Grid item md={9} sm={12}>
                    <Card classes={{ root: classes.card }}>
                        <div className={classes.cardHeader}>
                            <Typography variant="h1" className={classes.bold}>
                                {proposal.title}
                            </Typography>
                        </div>

                        <div className={classes.cardBody}>
                            <ReactMarkdown
                                remarkPlugins={[gfm]}
                                components={{
                                    img: ({ node, ...props }) => (
                                        <img
                                            alt=""
                                            {...props}
                                            href={node.properties.href}
                                            className={classes.img}
                                        />
                                    ),
                                    p: ({ node, ...props }) => (
                                        <Typography className={classes.p} {...props} />
                                    ),
                                    h1: ({ node, ...props }) => (
                                        <Typography variant="h1" {...props} />
                                    ),
                                    h2: ({ node, ...props }) => (
                                        <Typography variant="h2" {...props} />
                                    ),
                                    h3: ({ node, ...props }) => (
                                        <Typography variant="h3" {...props} />
                                    ),
                                    thead: ({ node, ...props }) => (
                                        <Typography {...props} className={classes.tableBorder} />
                                    ),
                                    tbody: ({ node, ...props }) => (
                                        <Typography {...props} className={classes.tableBorder} />
                                    ),
                                    table: ({ node, ...props }) => (
                                        <table {...props} className={classes.table} />
                                    ),
                                    a: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            className={classes.a}
                                            onClick={() => open(node.properties.href)}
                                        />
                                    ),
                                }}
                            >
                                {proposal.body}
                            </ReactMarkdown>
                        </div>
                    </Card>
                    <br />

                    {!isOver && (
                        <>
                            <Card classes={{ root: classes.card }}>
                                <div className={classes.cardHeader}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h2">Place Your Vote</Typography>
                                        </Grid>
                                        {!ethWeb3 && (
                                            <Grid item>
                                                <Typography variant="h4" color="error">
                                                    You must connect your wallet before voting!
                                                </Typography>
                                            </Grid>
                                        )}
                                        {ethWeb3 && user && (
                                            <Grid item>
                                                <Typography variant="h4" color="textSecondary">
                                                    Wallet Status:{' '}
                                                    {user.DaoShares > 0 ? "Eligible" : "Ineligible"}{' '}
                                                    <Tooltip
                                                        arrow
                                                        enterDelay={0}
                                                        placement="top"
                                                        title="Must have over 1M AXN staked for 1825+ days"
                                                    >
                                                        <InfoOutlined fontSize="small" />
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>

                                <Vote
                                    proposal={proposal}
                                    refresh={refetchVotes}
                                    results={voteData}
                                    shares={user?.DaoShares || 0}
                                />
                            </Card>
                            <br />
                        </>
                    )}

                    {isOver && <VotersTable proposal={proposal} shareCounts={voteData.voters} />}
                </Grid>

                <Grid item md={3} sm={12}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Card classes={{ root: classes.card }}>
                                <div className={classes.cardHeader}>
                                    <Typography variant="h2">Information</Typography>
                                </div>

                                <div className={classes.cardBody}>
                                    <VoteInformation proposal={proposal} results={voteData} />
                                </div>
                            </Card>
                            {hasVoted && (
                                <>
                                    <br />
                                    <Card classes={{ root: classes.card }}>
                                        <div className={classes.cardHeader}>
                                            <Typography variant="h2">Your Vote</Typography>
                                        </div>

                                        <div className={classes.cardBody}>
                                            <VoterInformation
                                                proposal={proposal}
                                                results={voteData}
                                                address={wallet.account}
                                                shares={user?.DaoShares?.toLocaleString() || 0}
                                            />
                                        </div>
                                    </Card>
                                </>
                            )}
                        </Grid>

                        <Grid item>
                            <Card classes={{ root: classes.card }}>
                                <div className={classes.cardHeader}>
                                    <Typography variant="h2">Results</Typography>
                                </div>

                                <div className={classes.cardBody}>
                                    <Results
                                        web3={ethWeb3}
                                        results={voteData}
                                        refresh={refetchVotes}
                                        choices={proposal.choices.map((c) => c.trim())}
                                    />
                                </div>

                                {!isOver && (
                                    <div className={classes.cardBody}>
                                        <Typography align="center">
                                            The results will be revealed once voting closes.
                                        </Typography>
                                    </div>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ProposalDetail;
