import React from 'react';
import useStyles from './styles';
import Button from 'components/Button';
import { RiSendPlaneLine } from 'react-icons/ri';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';

const SharedActionButton = ({ variant, user, loading, onClick, disabled }) => {
    const classes = useStyles();
    const { theme } = useSelector(theme => theme);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const icons = {
        stake: <RiSendPlaneLine className={classes.approvalIcon} />,
        continue: <BiRightArrowAlt className={classes.nextIcon} />,
        back: <BiLeftArrowAlt className={classes.backIcon} />,
        approve: <RiSendPlaneLine className={classes.approvalIcon} />,
    };

    const texts = {
        back: 'Back',
        stake: 'Stake',
        approve: 'Approve',
        continue: 'Continue',
    };

    return (
        <Button
            fullWidth
            color="primary"
            variant="contained"
            loading={loading}
            disabled={disabled}
            onClick={() => onClick()}
            className={classes.button}
            override={theme.mode === 'dark' ? "blue" : 'supernova'}
        >
            <div className={classes.next}>
                {variant === 'back' ? (
                    <>
                        {icons[variant]}
                        {texts[variant]}
                    </>
                ) : variant === 'continue' ? (
                    <>
                        {texts[variant]}
                        {icons[variant]}
                    </>
                ) : user ? (
                    <>
                        {texts[variant]}
                        {icons[variant]}
                    </>
                ) : isSmall ? (
                    'No Wallet'
                ) : (
                    'Connect Your Wallet'
                )}
            </div>
        </Button>
    );
};

export default SharedActionButton;
