import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomTable from 'components/CustomTable';
import Card from 'components/Card';
import CardTitle from 'components/Card/CardTitle';
import CardBody from 'components/Card/CardBody';
// styles
import styles from './styles';

const SkeletonTableCard = ({ classes, columns = 6, ...props }) => {
    return (
        <Card classes={{ root: classes.root }}>
            <CardTitle>
                <Skeleton variant="rect" height={20} width={120} className={classes.root} />
            </CardTitle>
            <CardBody>
                <CustomTable
                    classes={{ root: classes.table }}
                    headers={Array.from(new Array(columns)).map(() => (
                        <Skeleton variant="rect" height={20} width={120} className={classes.root} />
                    ))}
                    pagination={false}
                    rows={[]}
                />
                <Skeleton variant="rect" height={30} className={classes.fullWidth} />
                <Skeleton variant="rect" height={30} className={classes.fullWidth} />
                <Skeleton variant="rect" height={30} className={classes.fullWidth} />
                <Skeleton variant="rect" height={30} className={classes.fullWidth} />
            </CardBody>
        </Card>
    );
};

export default withStyles(styles)(SkeletonTableCard);
