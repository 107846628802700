import { APR, DEX } from "./variables";
import tokens from "containers/Accelerator/tokens";

export const DISCLAIMERS = {
    //////////////////
    // ACCELERATOR //
    ////////////////
    ACCELERATOR: {
        key: 'disclaimer_accelerator_v3',
        title: 'Axion Accelerator',
        explain: [
            <span style={{ fontWeight: 300 }}>Welcome to the future of Crypto! The Accelerator allows users to purchase and stake Axion (AXN) in a single transaction, allowing you to instantly start earning passive income via liquid Bitcoin rewards and guaranteed APR's on your AXN.</span>,
            <span style={{ fontWeight: 500 }}>The accelerator accepts: {tokens.map(t => t.symbol).join(', ')}</span>,
            <span style={{ fontWeight: 300 }}>Get up to 20.8% extra principal AXN plus up to 305% additional Shares based on staking duration. By purchasing on this page, your tokens are used to buy AXN which is then staked in exchange for Shares. Your shares determine your distribution of rewards from the daily reward pool.</span>,
        ],
        warnings: [],
        extraInfoSubtitle: '',
        extraInfo: [],
    },

    ////////////
    // STAKING
    ///////////
    STAKING: {
        key: 'disclaimer_staking_v5',
        title: 'Staking Information',
        subtitle: 'Stake your Axion and earn revenue from the staking contract.',
        explain: [
            <span style={{ fontWeight: 300 }}>You will earn liquid wBTC rewards from the daily Accelerators and amazingly, those can be withdrawn at any time. You will also earn a minimum {APR}% annual interest which accrues and can be withdrawn upon maturity of the stake.</span>,
            <span style={{ fontWeight: 300 }}>In order to submit the transaction, you will need some MATIC in your wallet to pay the Polygon Network fees (gas).</span>,
        ],
        warnings: [
            <span style={{ fontWeight: 300 }}>
                Please read the{' '}
                <span
                    onClick={() => window.open('https://axion.network/whitepaper.pdf')}
                    style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: 500 }}
                >
                    whitepaper
                </span>{' '}
                and be sure you understand staking before committing to a stake. Once a stake is
                made, it is final. Withdrawing your stake early will result in penalties as per the
                whitepaper.
            </span>,
        ],
        extraInfoSubtitle: null,
        extraInfo: [],
    },

    ///////////
    // DEX
    ///////////
    DEX: {
        key: 'disclaimer_dex',
        title: `${DEX} Information`,
        subtitle: `Buy Axion directly from the market using Polygon's ${DEX}.`,
        explain: [
            `On this page, we are embedding the ${DEX} website inside a frame.`,
            `If this is the first time you have used ${DEX}, there will be a popup warning about our token address, which you will need to accept. After that, you will need to connect your wallet using the 'connect to a wallet' button at the top right.`,
            `We are not responsible for any transactions carried out on ${DEX}. You can go directly to their website by using the link at the bottom of the page. We have simply embedded it to make it easier for you to access.`,
        ],
        warnings: [],
        extraInfoSubtitle: null,
        extraInfo: [],
    },
};
