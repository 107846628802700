import { SECONDS_IN_DAY, BPD_AMOUNTS, APR, PERIOD, BPD_TIMES } from 'utils/variables';

const totalAXNDivs = (principal, days) => {
    return ((principal * APR) / 36500) * days;
};

const totalVCADivs = (axnUSD, shares, globalShares) => {
    const tokensInVCA = 4025000000; // per week
    const percentageOfPool = shares / (globalShares || 1);
    const divs = tokensInVCA * 0.1 * axnUSD * percentageOfPool;

    return {
        // weekly: divs,
        monthly: (divs * 52) / 12,
        yearly: divs * 52,
    };
};

const totalBPD = (start, end, shares, bpdShareArray) => {
    if (shares === 0 || shares === '0') return { amount: 0, bpds: 0 };

    end = parseInt(end);
    start = parseInt(start);

    const bpds = [];
    const days = (end - start) / SECONDS_IN_DAY;

    BPD_TIMES.forEach((time, idx) => {
        const eligible = 
            days >= PERIOD && // Over 350 days
            end >= parseInt(time) && // Stake ENDS after this BPD starts
            start <= parseInt(time); // Stake STARTS before this BPD starts
        
        if (eligible) bpds.push(idx)
    })

    let bpdAmount = 0;
    if (bpds.length === 0) return { amount: 0, bpds: 0 };
    if (bpds.length === 1) return { bpds: 1, amount: (shares / bpdShareArray[bpds[0]]) * BPD_AMOUNTS[bpds[0]]};

    for (let i = bpds[0]; i <= bpds[bpds.length - 1]; i++)
        bpdAmount += (shares / bpdShareArray[i]) * BPD_AMOUNTS[i];

    return {
        amount: bpdAmount,
        bpds: bpds.length,
    };
};

export const estimateDivs = {
    axn: totalAXNDivs,
    vca: totalVCADivs,
    bpd: totalBPD
};
