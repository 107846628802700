import React from 'react';
import moment from 'moment';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { BPD_TIMES, PERIOD, SECONDS_IN_DAY } from 'utils/variables';

const BPDChecker = ({ days }) => {
    const classes = useStyles();

    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + parseInt(days || 1) * SECONDS_IN_DAY * 1000);

    return (
        <div className={classes.bpd}>
            {BPD_TIMES.map((startTime, index) => {
                const check =
                    parseInt(days) >= PERIOD &&
                    endDate.getTime() / 1000 >= parseInt(startTime) &&
                    startDate.getTime() / 1000 <= parseInt(startTime);

                return (
                    <div className={classes.bpdItem} key={`bpd-${index}`}>
                        <Typography className={classes.bpdText}>
                            {check ? (
                                <Check className={classes.check} />
                            ) : (
                                <Close className={classes.close} />
                            )}
                            BPD {index + 1}
                        </Typography>
                        <Typography className={classes.bpdText}>
                            {moment(parseInt(startTime) * 1000).format('L')}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default BPDChecker;
