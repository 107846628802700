import React, { useContext, useMemo } from 'react';
import { numberWithCommas } from 'utils/string';
import { Typography, Zoom } from '@material-ui/core';
import StatCard from 'components/StatCard';
import DEXLogo from 'assets/images/apeeswaplogo.png';
import useStyles from './styles';
import { DEX } from 'utils/variables';
import { ContractContext } from 'service/provider';

const PageTitle = () => {
    const classes = useStyles();
    const { contractInfo } = useContext(ContractContext);

    const axnPerETH = useMemo(() => {
        let axionPerEth = 0;
        if (contractInfo?.axion) axionPerEth = contractInfo?.axion?.axnPerETH;

        return axionPerEth;
    }, [contractInfo]);

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Buy from {DEX}
                </Typography>
                <Typography className={classes.body} variant="h6">
                    In the event the accelerator bonus runs out early in the day, you can use our
                    integration on this page to purchase AXN directly from {DEX}.
                    <br className="break-on-large" />
                    While the UI on this page looks different from the official {DEX} UI, you are in
                    fact interacting with {DEX}'s contracts.
                    <br className="break-on-large" />
                    If you prefer, you can click the button below our UI to be taken to {DEX}'s site
                    and purchase through their UI instead.
                </Typography>
            </div>
            <Zoom in={axnPerETH > 0}>
                <div className={classes.barStats}>
                    <StatCard
                        gradient={'secondary'}
                        title={`${DEX} Price`}
                        body={`1 ETH = ${numberWithCommas(axnPerETH, 0)} AXN`}
                        notes={''}
                        img={DEXLogo}
                    />
                </div>
            </Zoom>
        </div>
    );
};

export default PageTitle;
