import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 500,
    },
    textfield: {
        color: theme.brand.NormalText
    },
    maxLength: {
        marginTop: theme.spacing(0.5),
        fontWeight: 300,
    },
}));

export default useStyles;
