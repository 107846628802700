const styles = (theme) => ({
    root: {
        width: '100%',
    },
    fullWidth: {
        width: '100%',
        marginTop: 10,
    },
});

export default styles;
