import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(4),
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'justify',
        }
    },
    title: {
        fontSize: 42,
        fontWeight: '500',

        textAlign: 'left !important',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center !important',
        }
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    barTitle: {
        width: '100%',
    },
    brOnLarge: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }
}));

export default useStyles;
