import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    const componentStyles = {
        root: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            paddingBottom: theme.spacing(5),

            borderImageSlice: '0 0 1 0',
            borderBottom: `2px solid black`,
            borderImage: `linear-gradient(to right, ${theme.brand.primary} 0%, ${theme.brand.accent} 100%)`,

            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
                marginBottom: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
        },
        accelerateImage: {
            width: '75%',
        },
        supernovaGlow: {
            filter: 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))',
        },
        accelerate: {
            fontWeight: 300,
            fontSize: '2rem',
            textAlign: 'center',
            fontStyle: 'italic',
            color: theme.brand.SecondaryText,
        },
        rootRow2: {
            marginBottom: theme.spacing(2),

            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
            },

            '& ul': {
                listStyle: 'none',
                margin: 0,
                padding: 0,
            },
        },
        subtitle: {
            fontWeight: 100,
            fontSize: '1.6rem',
            color: theme.brand.SecondaryText,

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.25rem',
            },
        },
        newToAxionButton: {
            borderRadius: 3,
            cursor: 'pointer',
            display: 'inline-block',
            padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
            marginTop: theme.spacing(2),
            backgroundColor: theme.brand.primary,
            transition: 'all .3s ease',

            '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.25)',
            },

            [theme.breakpoints.down('sm')]: {
                width: '90%',
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        newToAxionText: {
            color: theme.brand.NormalText,
            fontWeight: 300,
            fontSize: '1rem',
            letterSpacing: '1px',
            display: 'inline',
        },
        center: {
            textAlign: 'center',
        },
        heading: {
            fontSize: '1.6rem',
            fontWeight: 300,
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(2),
        },
        bonusSubheading: {
            display: 'block',
            fontSize: '0.75rem',
        },
        subHeading: {
            fontWeight: 300,
            fontSize: '1.2rem',
        },
        bold: {
            fontWeight: 500,
        },
        stat: {
            fontWeight: 500,
            fontSize: '1.2rem',
            marginBottom: theme.spacing(2),
        },
        colliderText: {
            fontWeight: 700,
            color: theme.brand.colliderOrange,
        },
        colliderButton: {
            marginLeft: theme.spacing(1),
            backgroundColor: theme.brand.colliderOrange,
        }
    };

    return { ...componentStyles };
});

export default useStyles;
