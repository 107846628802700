export const NavigationBarHeight = 70;
export const drawerWidth = 200;

const sharedColors = {
    error: '#c30d1e',
    Tropaz: '#2a669c',
    warning: "@#ffa310",
    JavaGreen: '#0fc1ac',
    colliderBlue: '#1AC4D2',
    teal: 'rgb(1, 152, 218)',
    colliderOrange: '#FF7611',
    orange: 'rgb(244,119,46)',
    ScrollBarActive: '#06b1a6',
    border: 'rgba(255,255,255,0.1)',
    RoyalBlue: 'rgba(60,68,177,1.0)',
    GrayChateau: 'rgba(255, 255, 255, 0.7)',
    translucent: 'rgba(255, 255, 255, 0.08)',
    translucentBorder: `rgba(255, 255, 255, 0.23)`,
}

const _default = {
    primary: '#176ebf',
    accent: '#0ECCC3',
    
    cardBG: '#143756',
    background: '#091b2b',
    background2: '#143756',
    textPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
}

const _supernova = {
    primary: '#fb7906',
    accent: '#c2390e',
    
    cardBG: '#4a1f05',
    background: '#271410',
    background2: '#852c11',
    textPrimary: '#fcec77',
    textSecondary: '#FFEC9F'
}

export const darkColors = {
    _id: 'dark',
    ...sharedColors,
   
    axion: _default.primary,
    primary: _default.primary,
    ChartLine: _default.primary,
    
    accent: _default.accent,
    ScrollBar: _default.accent,
    ScrollBarHover: _default.accent,

    Body: _default.background,
    NavigationBackground: _default.background,
    NavigationDrawerHeader: _default.background2,   
    
    CardBG: _default.cardBG,
    NormalText: _default.textPrimary,
    SecondaryText: _default.textSecondary,
    BPDColors: ['#80ccff', '#4db8ff', '#1aa3ff', '#008ae6', '#006bb3']
};


export const supernovaColors = {
    _id: 'supernova',
    ...sharedColors,

    axion: _supernova.primary,
    primary: _supernova.primary,
    ChartLine: _supernova.primary,
    
    accent: _supernova.accent,
    ScrollBar: _supernova.accent,
    ScrollBarHover: _supernova.accent,

    Body: _supernova.background,
    NavigationBackground: _supernova.background,
    NavigationDrawerHeader: _supernova.background2,
    
    CardBG: _supernova.cardBG,
    NormalText: _supernova.textPrimary,
    SecondaryText: _supernova.textSecondary,
    BPDColors: [ '#f1ab3e', '#d88f32', '#a55a1c', '#934a15', '#78320b' ]
};

export const darkPalette = {
    primary: {
        main: '#fff',
    },
    secondary: {
        main: '#fff',
    },
    error: {
        main: darkColors.error,
    },
};

export const supernovaPalette = {
    primary: {
        main: supernovaColors.primary,
    },
    secondary: {
        main: supernovaColors.accent,
    },
    error: {
        main: supernovaColors.error,
    }
};