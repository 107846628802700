export function secondsToDDHHMM(s) {
    let d = parseInt(s / (24 * 3600));

    s = s % (24 * 3600);
    let h = parseInt(s / 3600);

    s %= 3600;
    let m = parseInt(s / 60);

    if (d < 0) d = 0;
    if (h < 0) h = 0;
    if (m < 0) m = 0;

    return {
        d: Math.floor(d),
        h: Math.floor(h),
        m: Math.floor(m),
    };
}
