import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const PageTitle = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Wallet Verification
                </Typography>
                <Typography className={classes.body} variant="h6">
                    This page is used to initiate a signing transaction so the Axion team can verify your ownership of this wallet.
                    <br className="break-on-large" />
                    If someone on the Axion team did not send you a link to this page, then there's nothing you need to do on this page.
                </Typography>
            </div>
        </div>
    );
};

export default PageTitle;
