import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useStyles from './styles';
import { secondsToDDHHMM } from './util';
import { Typography, useMediaQuery } from '@material-ui/core';

const DayCountdown = ({ end, suffix, ...props }) => {
    const classes = useStyles(props);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [components, setComponents] = useState({ d: 0, h: 0, m: 0 });

    useEffect(() => {
        checkTime();

        const interval = setInterval(() => {
            checkTime();
        }, 1000 * 60); // every minute

        return () => clearInterval(interval);

        // eslint-disable-next-line
    }, [end]);

    const checkTime = () => {
        setComponents({ ...secondsToDDHHMM(moment(end).diff(moment(), 'seconds')) });
    };

    if (components.d === 0 && components.h === 0 && components.m === 0) return null;
    return (
        <div>
            {components.d !== 0 && (
                <Typography className={classes.timerText} variant="h4">
                    {components.d}&nbsp;{components.d === 1 ? 'Day' : 'Days'},&nbsp;
                </Typography>
            )}

            <Typography className={classes.timerText} variant="h4">
                {components.h}&nbsp;{components.h === 1 ? 'Hour' : 'Hours'},&nbsp;
            </Typography>

            <Typography className={classes.timerText} variant="h4">
                {components.m}&nbsp;{isSmall ? "Min" : (components.m === 1 ? 'Minute' : 'Minutes')}
                {suffix && ` ${suffix}`}
            </Typography>
        </div>
    );
};

export default DayCountdown;
