const styles = (theme) => ({
    root: {},
    body: {
        fontSize: 14,
        color: theme.brand.NormalText,
        fontWeight: 400,
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    head: {
        borderBottom: `1px solid ${theme.brand.border}`,
        color: theme.brand.SecondaryText,
        whiteSpace: 'nowrap',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
    },
    paginationRoot: {
        borderBottomWidth: 0,
    },

    tableSortLabel: {
        fontSize: 16,
        // color: theme.palette.common.white,
        '&:hover': {
            // color: theme.palette.common.white,
        },
    },
    tableSortLabelActive: {
        // color: `${theme.palette.common.white} !important`,
        textDecoration: 'underline',
    },
    info: {
        marginLeft: theme.spacing(1),
        fontSize: 20,
    },
    expandContainer: {
        padding: 0,
        border: 'none',
    },
    noData: {
        fontSize: 16,
        textAlign: 'center',
        paddingTop: theme.spacing(5),
    }
});

export default styles;
