import React from 'react';
import useStyles from './styles';

import { useDispatch } from 'react-redux';
import copyPromise from 'utils/copy-promise';
import CloseIcon from '@material-ui/icons/Close';
import useTransactions from 'hooks/useTransactions';
import SlideUp from 'components/Transitions/SlideUp';
import { setSnackbar } from 'redux/actions/snackbar';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CallMadeIcon from '@material-ui/icons/CallMade';
import providers from '../ConnectWalletModal/providers';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { openTx, openAddress } from 'utils/open-etherscan';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
    Grid,
    Button,
    Dialog,
    Typography,
    CircularProgress,
    useMediaQuery,
} from '@material-ui/core';

const TransactionsModal = ({ open, wallet, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { transactions, clearTransactions } = useTransactions(true);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const copyAddress = async () => {
        try {
            await copyPromise(wallet.account);
            dispatch(setSnackbar({ message: 'Address copied', type: 'success' }));
        } catch (err) {
            dispatch(setSnackbar('Failed to copy address'));
        }
    };

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="xs"
            onClose={onClose}
            disableScrollLock
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <Grid
                container
                alignItems="center"
                justify="space-between"
                className={classes.dialogTitle}
            >
                <Grid item>
                    <Typography variant="h3">Account</Typography>
                </Grid>
                <Grid item>
                    <div onClick={() => onClose()} className={classes.clear}>
                        <CloseIcon fontSize="small" />
                    </div>
                </Grid>
            </Grid>

            <div className={classes.addressBox}>
                <Typography variant="h4" className={classes.wallet}>
                    {providers.find((p) => p.id === wallet?.connector)?.name ?? 'Your'} Wallet
                    <br />
                    <span className={classes.walletAddress} onClick={() => copyAddress()}>
                        {wallet.account?.shortenAddress()}
                    </span>
                </Typography>
                <Grid container justify="space-evenly">
                    <Grid item xs={6}>
                        <Button onClick={() => copyAddress()}>
                            <FileCopyIcon className={classes.buttonIcon} />
                            Copy{isSmall ? '' : ' Full Address'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => openAddress(wallet.account)}>
                            <OpenInNewIcon className={classes.buttonIcon} />
                            {isSmall ? '' : 'View on '}Polygonscan
                        </Button>
                    </Grid>
                </Grid>
            </div>

            <Grid
                container
                alignItems="center"
                justify="space-between"
                className={classes.dialogTitle}
            >
                <Grid item>
                    <Typography variant="h3">Recent Transactions</Typography>
                </Grid>
                {transactions.length > 0 && (
                    <Grid item>
                        <div onClick={() => clearTransactions()} className={classes.clear}>
                            <Typography variant="h6" className={classes.undoTransform}>
                                (clear)
                            </Typography>
                        </div>
                    </Grid>
                )}
            </Grid>

            <div className={classes.contentContainer}>
                {transactions.length === 0 && (
                    <div className={classes.emptyTransactions}>
                        <Typography variant="h5" align="center" className={classes.undoTransform}>
                            No recent transactions...
                        </Typography>
                    </div>
                )}

                <div className={classes.transactions}>
                    {transactions.slice(0, 4).map((t, idx) => (
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            spacing={1}
                            key={idx}
                        >
                            <Grid item xs={11}>
                                <Typography
                                    variant="h5"
                                    display="inline"
                                    onClick={() => openTx(t.id)}
                                    className={classes.transaction}
                                >
                                    {t.description} <CallMadeIcon className={classes.openArrow} />
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <center>
                                    {t.pending ? (
                                        <CircularProgress size="1rem" />
                                    ) : !t.failed ? (
                                        <CheckCircleOutlineIcon className={classes.success} />
                                    ) : (
                                        <ErrorOutlineIcon className={classes.failed} />
                                    )}
                                </center>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </div>
        </Dialog>
    );
};

export default TransactionsModal;
