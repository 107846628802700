import React from 'react';

import useStyles from './styles';

const InnerPage = ({ children, ...props }) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root} {...props}>
            {children}
        </div>
    );
};

export default InnerPage;
