import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './styles'
import NFTCard from './NFTCard';
import PageTitle from './PageTitle';
import Toolbar from './Toolbar/Toolbar';
import Page from 'components/Page/Page';
import Meta from 'components/util/Meta';
import { ContractContext } from 'service/provider';
import NFTModal from 'components/Modals/NFTModal/';
import wallet from 'assets/images/crypto-wallets.png';
import Background from 'components/Background/Background';
import { Grid, Grow, Typography, useMediaQuery } from '@material-ui/core';

const hiddenIDs = [];
const hiddenFilters = ['Status', 'rarity', 'Particles', 'Withdrawn', 'Shares', 'Amount of AXN', 'Duration'];

const Collection = () => {
    const classes = useStyles();
    
    const [nfts, setNfts] = useState([]);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState(null);
    const [selected, setSelected] = useState(null);
    const [activeFilters, setActiveFilters] = useState(null);
    const { user, userLoading } = useContext(ContractContext);

    const [gridSize, setGridSize] = useState(3);
    const isLG = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isXS = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSM = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const isMD = useMediaQuery(theme => theme.breakpoints.only('md'));

    useEffect(() => {
        if (isXS) setGridSize(12);
        else if (isSM) setGridSize(6);
        else if (isMD) setGridSize(4);
        else if (isLG) setGridSize(3);
    }, [isXS, isSM, isMD, isLG]);

    const userNFTs = useMemo(() => {
        if (user) {
            return user.nfts.filter(nft => !hiddenIDs.includes(nft.metadata.id));
        } else return []
    },[user]);

    useEffect(() => {
        if ( (!activeFilters || !filters) && user?.nfts?.length > 0) {
            let data = {};
            let filters = {};
            let nftData = userNFTs;

            nftData.forEach(nft => {
                if (nft.balance > 0) {
                    nft.metadata.properties.forEach(prop => {
                        const type = prop.trait_type;
                        const value = prop.value;
                        if (!data[type]) {
                            if (!hiddenFilters.includes(type)) {
                                filters[type] = [];
                                data[type] = [value];
                            }
                        }
                        else if (!data[type].includes(value)) {
                            data[type].push(value);
                        }
                    })
                }
            })

            setNfts(nftData);
            setFilters(data);
            setActiveFilters(filters);
        }

        // eslint-disable-next-line
    }, [user?.nfts])

    useEffect(() => {
        if (search) {
            let val = search.toLowerCase().trim();
            setNfts(userNFTs.filter(d => d.metadata.name.toLowerCase().includes(val)))
        } else setNfts(userNFTs);

        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        let noFilter = true;
        let filteredNfts = userNFTs;

        Object.keys(activeFilters || {}).forEach(type => {
            if (activeFilters[type].length) {
                noFilter = false;
                let val = activeFilters[type];
                filteredNfts = filteredNfts.filter(
                    d => d.metadata.properties.some(
                        prop => prop.trait_type === type && val.includes(prop.value)
                    )
                )
            }
        })

        if (noFilter) setNfts(userNFTs);
        else setNfts(filteredNfts);

        // eslint-disable-next-line
    }, [activeFilters])

    const clearActiveFilters = () => {
        let filters = {...activeFilters};
        Object.keys(filters).forEach(type => filters[type] = []);
        setActiveFilters(filters);
    }

    const handleClick = ({ balance, metadata, type }) => {
        setSelected({ ...metadata, type, balance })
    }

    return (
        <React.Fragment>
            <Background />
            <Meta title={`Axion | NFT Collection`} />
            <Page header={<PageTitle />}>
                {!user ? (
                    <div>
                        <center>
                            <img alt="connect wallet" className={classes.connectImage} src={wallet} />
                            {userLoading ? (
                                <Typography variant="h2">Loading your NFTs...</Typography>
                            ) : (
                                <Typography variant="h2">Please connect your wallet.</Typography>
                            )}
                        </center>
                    </div>
                ) : (
                    <>
                        <Toolbar 
                            search={search} 
                            filters={filters}
                            gridSize={gridSize}
                            setSearch={setSearch} 
                            changeGridSize={setGridSize}
                            activeFilters={activeFilters}
                            clearFilters={clearActiveFilters}
                            setActiveFilters={setActiveFilters}
                        />

                        <Grid 
                            container
                            justify={ // Center the items if the grid size is not a factor of 12
                                [...Array(13).keys()]
                                .filter(i=> 12 % i === 0)
                                .includes(gridSize) ? 
                                'flex-start' : 'space-evenly'
                            }
                        >
                            {userNFTs.map(({ balance, metadata, type }, i) => {
                                if (balance === 0) return null;
                                return (
                                    <Grow 
                                        unmountOnExit
                                        key={`${type}-${metadata.id}`} 
                                        timeout={{ enter: (i + 1) * 250, exit: 250, appear: 250 }} 
                                        in={nfts.filter(n => n.metadata.id === metadata.id && n.type === type).length > 0} 
                                    >
                                        <Grid key={metadata.id} item xs={gridSize} onClick={() => handleClick({ balance, metadata, type })}>
                                            <NFTCard type={type} metadata={metadata} balance={balance} gridSize={gridSize} />
                                        </Grid>
                                    </Grow>
                                )
                            })}
                        </Grid>
                    </>
                )}
            </Page>

            <NFTModal 
                nft={selected} 
                activeFilters={activeFilters}
                open={selected ? true : false} 
                onClose={() => setSelected(null)} 
                setActiveFilters={setActiveFilters}
            />
        </React.Fragment>
    );
};

export default Collection;
