import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
        },
        [theme.breakpoints.down('xs')]: {
            // textAlign: 'justify',
        }
    },
    title: {
        fontSize: 32,
        fontWeight: '500',

        textAlign: 'left !important',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center !important',
        }
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
    link: {
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-evenly',
        },

        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
            gap: theme.spacing(2),
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    skrtLogo: {
        width: '100%',
        maxWidth: '250px',
    },
    status: {
        marginTop: theme.spacing(1),
        fontWeight: '500',
    },
    green: {
        backgroundColor: `${theme.brand.JavaGreen} !important`,
    },
    red: {
        backgroundColor: `${theme.brand.error} !important`,
    },
    yellow: {
        backgroundColor: `#ffa310 !important`,
    },
    yellowText: {
        color: `#ffa310 !important`,
    },
    skrtBlue: {
        color: "#54c1fc",
        fontStyle: 'italic',
        marginTop: theme.spacing(1),
    },
    transparent: {
        outline: `2px solid #54c1fc`,
        backgroundColor: "transparent",
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            paddingTop: theme.spacing(1),
        },
    },
    badge: {
        width: 16,
        height: 16,
        borderRadius: '50%',
    },
    actionBtn: {
        marginTop: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    }
}));

export default useStyles;
