import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    profile: {
        color: 'rgba(255,255,255,0.7)',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: '8px 16px',
        borderRadius: 30,
        fontSize: 14,
        backgroundColor: theme.brand.axion,
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
    },
    gas: {
        marginTop: 10,
        padding: '4px 16px',
    },
    gasGreen: {
        backgroundColor: '#4b8e4b',
    },
    gasOrange: {
        backgroundColor: '#e89228',
    },
    gasRed: {
        backgroundColor: '#cc3d3d',
    },
    mr6: {
        marginRight: theme.spacing(0.75),
    },
}));

export default useStyles;
