import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: theme.mixins.bar.height,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme._shadows.bar,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            flexDirection: 'column',
            borderRadius: 0,
        },
        marginBottom: theme.spacing(3),
    },
    barTitle: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center !important',
        },
    },
    barPaper: {
        borderRadius: '0.5rem',
        height: 70,
        width: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.brand.CardBG,
        marginRight: theme.spacing(2),
    },
    barIcon: {
        margin: 'auto',
        fontSize: 34,
    },
    title: {
        fontSize: 42,
        fontWeight: '500',
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },

    /** Stats */
    barStats: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            // flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 12,
        },
    },
    graph: {
        height: 200,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    stats: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    },
    stat: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '34%',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },

    /** Margins */
    mr: {
        marginRight: theme.spacing(2),
    },
}));

export default useStyles;
