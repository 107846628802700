import { makeStyles } from '@material-ui/core/styles';
import AxionColliderBG from 'assets/images/AxionColliderBackground.jpg';

const useStyles = makeStyles((theme) => ({
    collider: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0,
        borderRadius: 10,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        background: `url(${AxionColliderBG})`,
        boxShadow: `inset 0 0 5px ${theme.brand.colliderBlue}`,

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
        }
    },
    particles: {
        zIndex: 0,
        opacity: 1,
        width: '100%',
        height: '100%',
        userSelect: 'none',
        position: 'absolute',
        transition: 'opacity 0.5s ease-in-out',
    },
    pulsate: {
        animation: '$pulsate 3s ease infinite',
    },
    colliderItem: {
        zIndex: 2,
        maxWidth: '100%',
        alignItems: 'center',
    },
    flexPipes: {
        '@media (max-width: 1500px)': {
            flex: 0.3,
        }
    },
    pipes: {
        zIndex: 2,
        width: '100%',
    },
    pipesGlow: {
        filter: 'drop-shadow(0px 0px 6px rgba(53,229,229,0.4))'
    }, 
    pipesPulsate: {
        animation: '$pulsatePipes 2s ease-in infinite',
    },
    card: {
        padding: theme.spacing(2),
        borderRadius: 10,
        border: `3px solid #FFF`,
        backgroundColor: '#162735',
    },
    settings: {
        overflow: 'hidden',
    },
    colliding: {
        overflow: 'hidden',
    },
    collisionResult: {
        overflow: 'hidden',
        minHeight: '200px',
    },
    hideSmall: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    flex: {
        flex: 0.6,
        [theme.breakpoints.down('md')]: {
            flex: 0.7
        },
        [theme.breakpoints.down('sm')]: {
            flex: 0.9,
        },
        [theme.breakpoints.down('xs')]: {
            flex: 1,
        }
    },
    spinny: {
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        bottom: 0,
        width: '40%',
        opacity: 0.2,
        marginTop: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 'auto',
        position: 'absolute',
        filter: 'hue-rotate(175deg)',
        transition: 'all 0.5s ease-in-out',
    },
    slowRotate: {
        animation: '$rotate 50s linear infinite',
    },
    fastRotate: {
        animation: '$rotate 10s linear infinite',
    },
    off: {
        opacity: 0.1,
        animation: '$rotate 100s linear infinite',
    },
    hidden: {
        opacity: 0,
    },
    greenBorder: {
        border: `3px solid ${theme.brand.JavaGreen}`,
    },
    redBorder: {
        border: `3px solid ${theme.brand.error}`,
    },
    '@keyframes pulsate': {
        '0%':   { boxShadow: `inset 0 0 5px ${theme.brand.colliderBlue}` },
        '50%':  { boxShadow: `inset 0 0 5px #0e838c` },
        '100%':  { boxShadow: `inset 0 0 5px ${theme.brand.colliderBlue}` },
    },
    '@keyframes pulsatePipes': {
        '0%':   { filter: 'drop-shadow(0px 0px 6px rgba(53,229,229,0.4))' },
        '50%':  { filter: 'drop-shadow(0px 0px 12px rgba(53,229,229,0.4))' },
        '100%':  { filter: 'drop-shadow(0px 0px 6px rgba(53,229,229,0.4))' },
    },
    '@keyframes rotate': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
}));

export default useStyles;
