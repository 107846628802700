import React, { useEffect } from 'react';
import useStyles from './styles';
import { Close } from '@material-ui/icons';
import { openTx } from 'utils/open-etherscan';
import CheckIcon from '@material-ui/icons/Check';
import { Slide, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeAlertbar } from 'redux/actions/alertbar';

const AlertBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const alertbar = useSelector(({ alertbar }) => alertbar);

    useEffect(() => {
        if (alertbar.txid) {
            const timer = setTimeout(() => {
                handleClose();
            }, alertbar.seconds * 1000);

            return () => {
                clearTimeout(timer);
            };
        }

        // eslint-disable-next-line
    }, [alertbar.txid, alertbar.seconds]);

    const handleClose = () => {
        dispatch(closeAlertbar());
    };

    return (
        <main className={classes.content}>
            <Slide direction="down" in={alertbar.txid ? true : false} mountOnEnter unmountOnExit>
                <div className={classes.connect}>
                    <Typography className={classes.message}>
                        <CheckIcon className={classes.icon} />
                        {alertbar.message}&nbsp;
                        <span className={classes.link} onClick={() => openTx(alertbar.txid)}>
                            View on Polygonscan
                        </span>
                    </Typography>
                    <Close className={classes.closeIcon} onClick={() => handleClose()} />
                </div>
            </Slide>
        </main>
    );
};

export default AlertBar;
