import Contract from './contract';
import BN from 'bn.js';
import BigNumber from 'bignumber.js';
import { MaxUint256 } from '@ethersproject/constants';
import { ZERO_ADDRESS, _1E18, WETH_ADDRESS } from 'utils/variables';
import ABI from '../abi.json';
import { addTx, completedTx } from 'redux/actions/transactions';
import { store } from '../../redux/config';
const dispatch = store.dispatch;

class MineManager extends Contract {
    /** Getters */
    async getInfo() {
        const [addresses, nfts] = await Promise.all([
            this.getMineAddresses(),
            this.getNftAddresses(),
        ]);
        const mines = await this.getMineData(addresses);

        return { addresses, nfts, mines };
    }

    async getNftAddresses() {
        const result = await Promise.all([
            Contract.contracts.MineManager.methods.liqRepNFTAddress().call(),
            Contract.contracts.MineManager.methods.OG5555_25NFTAddress().call(),
            Contract.contracts.MineManager.methods.OG5555_100NFTAddress().call(),
        ]);

        return {
            liqRepNFTAddress: result[0],
            OG5555_25NFTAddress: result[1],
            OG5555_100NFTAddress: result[2],
        };
    }

    async getMineAddresses() {
        const mineAddresses = await Contract.contracts.MineManager.methods
            .getMineAddresses()
            .call();
        return mineAddresses.filter((address) => address !== ZERO_ADDRESS);
    }

    async getMineData(addresses = []) {
        const mines = {};
        await Promise.all(
            addresses.map(async (address) => {
                const contract = Contract.getContract(ABI.Mine.ABI, address);
                const info = await contract.methods.mineInfo().call();
                const mine = await this.getMine(address, info);

                mines[address] = { mine, contract, info };
            })
        );

        return mines;
    }

    /** Get Miner Details for */
    checkIsManager() {
        return Contract.contracts.MineManager.methods.isManager().call({ from: Contract.account });
    }

    async getMinerNftBalances() {
        const result = {
            og25NFT: 0,
            og100NFT: 0,
            liqNFT: 0,
            multiplier: 0,
        };
        const nfts = Contract.mining.nfts;

        try {
            const balances = await Promise.all([
                this.getNftTokenBalance(nfts.OG5555_25NFTAddress),
                this.getNftTokenBalance(nfts.OG5555_100NFTAddress),
                this.getNftTokenBalance(nfts.liqRepNFTAddress),
            ]);

            result.og25NFT = +balances[0];
            result.og100NFT = +balances[1];
            result.liqNFT = +balances[2];

            if (result.og25NFT > 0) result.multiplier++;
            if (result.og100NFT > 0) result.multiplier++;
            if (result.liqNFT > 0) result.multiplier++;

            return result;
        } catch (err) {}
    }

    async getMinerLpTokenBalance(lpTokenAddress) {
        const token = this.getContract(ABI.ERC20.ABI, lpTokenAddress);
        const balance = await token.methods.balanceOf(Contract.account).call();

        return {
            wei: balance,
            bn: new BN(balance),
        };
    }

    async getNftTokenBalance(address) {
        const token = this.getContract(ABI.ERC721.ABI, address);
        return await token.methods.balanceOf(Contract.account).call();
    }

    async getMinerPendingReward(mineAddress) {
        const reward = await Contract.mining.mines[mineAddress].contract.methods
            .getPendingReward()
            .call({ from: Contract.account });

        return new BN(reward);
    }

    async getMinerLpDeposit(mineAddress) {
        const minerInfo = await Contract.mining.mines[mineAddress].contract.methods
            .minerInfo(Contract.account)
            .call();

        return new BN(minerInfo.lpDeposit);
    }

    /** Get mine and function helpers */
    async getMine(address, info) {
        try {
            const balance = await Contract.contracts.AXN.methods.balanceOf(address).call();

            const mineInfo = info;
            const poolTokens = await this.getPoolTokens(mineInfo.lpToken);
            const blockReward = new BigNumber(mineInfo.blockReward);

            const pairERC20Contract = this.getContract(ABI.ERC20.ABI, mineInfo.lpToken);

            const lpTokenBalance = new BigNumber(
                await pairERC20Contract.methods.balanceOf(address).call()
            );

            const { apy, lpTokenUsdPrice, axnTokenPrice } = await this.getMineApyAndLpTokenUsdPrice(
                mineInfo.lpToken,
                blockReward,
                lpTokenBalance
            );

            const rewardBalance = new BigNumber(balance);
            const estDaysLeft = Contract.web3.utils.fromWei(
                rewardBalance.div(blockReward.times(6500)).times(_1E18).dp(0).toString(10)
            );

            const mine = {
                apy,
                blockReward,
                address,
                base: poolTokens.base,
                market: poolTokens.market,
                lpToken: mineInfo.lpToken,
                startBlock: +mineInfo.startBlock,
                rewardBalance,
                lpTokenBalance,
                lpTokenUsdPrice,
                axnTokenPrice,
                estDaysLeft,
            };

            return mine;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async getPoolTokens(lpTokenAddress) {
        if (!Contract.web3.utils.isAddress(lpTokenAddress)) {
            throw new Error('Invalid address');
        }

        const uniPairContract = this.getContract(ABI.UniswapPair.ABI, lpTokenAddress);

        // Get market token symbol
        const address = await uniPairContract.methods.token1().call();
        const contract = this.getContract(ABI.ERC20.ABI, address);
        const market = await contract.methods.symbol().call();

        return {
            base: 'AXN',
            market: market === 'WETH' ? 'ETH' : market,
        };
    }

    async getMineApyAndLpTokenUsdPrice(lpTokenAddress, blockReward, mineLpTokenBalance) {
        if (mineLpTokenBalance.isZero())
            return {
                apy: new BigNumber(0),
                lpTokenUsdcPrice: new BigNumber(0),
                axnTokenPrice: new BigNumber(0),
            };

        const pairContract = this.getContract(ABI.UniswapPair.ABI, lpTokenAddress);

        const tokenData = await Promise.all([
            pairContract.methods.getReserves().call(),
            pairContract.methods.token0().call(),
            pairContract.methods.token1().call(),
            pairContract.methods.totalSupply().call(),
        ]);

        const tokenValues = await Promise.all([
            this.getReserveValueInUsd(tokenData[1], tokenData[0].reserve0),
            this.getReserveValueInUsd(tokenData[2], tokenData[0].reserve1),
        ]);

        const lpTokenUsdPrice = tokenValues[0].reserve
            .plus(tokenValues[1].reserve)
            .div(tokenData[3]);

        const axnTokenPrice = tokenValues.find((x) => x.isAxn)?.tokenPrice || 0.0002;
        const lpTokenPriceInAxn = lpTokenUsdPrice.div(axnTokenPrice);
        const lpSupplyValueInAxn = lpTokenPriceInAxn.times(mineLpTokenBalance);

        return {
            apy: blockReward.times(6500).times(365).times(100).div(lpSupplyValueInAxn).dp(2),
            lpTokenUsdPrice,
            axnTokenPrice,
        };
    }

    async getReserveValueInUsd(address, reserve) {
        if (address === Contract.contracts.AXN.options.address) {
            const tokenPrice = new BigNumber(store.getState().axion.currentPriceUsd);

            return {
                tokenPrice,
                reserve: new BigNumber(reserve).times(tokenPrice),
                isAxn: true,
            };
        } else if (address === WETH_ADDRESS) {
            const tokenPrice = new BigNumber(store.getState().axion.ethUsd);

            return {
                tokenPrice,
                reserve: new BigNumber(reserve).times(tokenPrice),
                isAxn: false,
            };
        }

        const tokenContract = this.getContract(ABI.ERC20.ABI, address);
        const tokenDecimals = await tokenContract.methods.decimals().call();
        const token_1eDecimals = Math.pow(10, tokenDecimals).toString();
        const tokenPrice = (await this.getWethToTokenAmountsOut(token_1eDecimals, address))[1];

        return {
            tokenPrice,
            reserve: new BigNumber(reserve).times(tokenPrice),
            isAxn: false,
        };
    }

    /** Helper Functions */
    adjustApy(mine, nftBalance) {
        nftBalance.bonusApy = mine.apy
            .times(new BN(Contract.web3.utils.toWei(`${nftBalance.multiplier / 10}`)))
            .div(new BN(_1E18));
    }

    calculateBlockReward(rewardAmount, startBlock, endBlock) {
        const blocks = endBlock - startBlock;

        return new BN(rewardAmount).div(blocks);
    }

    /** Miner actions */
    async depositLPTokens(mineAddress, lpTokenAddress, amount) {
        const isApproved = await this.isTokenApproved(new BN(amount), mineAddress, lpTokenAddress);

        if (!isApproved) {
            const token = this.getContract(ABI.ERC20.ABI, lpTokenAddress);
            await token.methods
                .approve(mineAddress, MaxUint256)
                .send({ from: Contract.account })
                .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
                .on('transactionHash', (id) =>
                    dispatch(addTx({ id, description: 'Approve LP Tokens' }))
                );
        }

        return Contract.mining.mines[mineAddress].contract.methods
            .depositLPTokens(amount)
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: 'Deposit LP Tokens' }))
            );
    }

    withdrawLPTokens(mineAddress, amount) {
        return Contract.mining.mines[mineAddress].contract.methods
            .withdrawLPTokens(amount)
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: 'Withdraw LP Tokens' }))
            );
    }

    withdrawReward(mineAddress) {
        return Contract.mining.mines[mineAddress].contract.methods
            .withdrawReward()
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: 'Withdraw Mining Rewards' }))
            );
    }

    withdrawAll(mineAddress) {
        return Contract.mining.mines[mineAddress].contract.methods
            .withdrawAll()
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: 'Withdraw LP Tokens & Rewards' }))
            );
    }

    /** Mine Manager Functions (Not used as we are not handling this) */
    async createMine(lpTokenAddress, rewardAmount, blockReward, startBlock) {
        const isApproved = await this.checkApproval(
            rewardAmount,
            Contract.contracts.MineManager.options.address
        );

        if (!isApproved) {
            await Contract.contracts.AXNContract.methods
                .approve(Contract.contracts.MineManager.options.address, MaxUint256)
                .send({ from: Contract.account })
                .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
                .on('transactionHash', (id) =>
                    dispatch(addTx({ id, description: 'Mining Approval' }))
                );
        }

        const res = await Contract.contracts.MineManager.methods
            .createMine(lpTokenAddress, rewardAmount, blockReward, startBlock)
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Create Mine' })));

        await this.getMineAddresses();
        await this.getMineData();

        return res;
    }
}

export default MineManager;
