export const SET_ALERTBAR = 'SET_ALERTBAR';
export const CLOSE_ALERTBAR = 'CLOSE_ALERTBAR';
export const INIT = { message: '', txid: '', seconds: 15 };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ALERTBAR:
            return { ...state, ...payload };
        case CLOSE_ALERTBAR:
            return { ...state, txid: '' };
        default:
            return state;
    }
}

export default reducer;
