import { CONTRACT_INFO, ETHEREUM_TOKEN } from 'utils/variables';

// Logos
import wbtc from 'assets/images/coinIcons/wbtc.svg';
import wbtcW from 'assets/images/coinIcons/wbtc_w.svg';

import eth from 'assets/images/coinIcons/eth.svg';
import ethW from 'assets/images/coinIcons/eth_w.svg';

import usdc from 'assets/images/coinIcons/usdc.svg';
import usdcW from 'assets/images/coinIcons/usdc_w.svg';

import dai from 'assets/images/coinIcons/dai.svg';
import daiW from 'assets/images/coinIcons/dai_w.svg';

import link from 'assets/images/coinIcons/link.svg';
import linkW from 'assets/images/coinIcons/link_w.svg';

import matic from 'assets/images/coinIcons/matic.svg';
import maticW from 'assets/images/coinIcons/matic_w.svg';

const tokens = [
    // {
    //     decimals: 18,
    //     symbol: "MATIC",
    //     logo: matic,
    //     logoDark: maticW,
    //     minStake: 0,
    //     name: "Matic",
    //     address: ETHEREUM_TOKEN.tokenAddress,
    //     website: 'https://polygon.technology/',
    //     decimalsToDisplay: 0,
    //     prefillAmount: '500',
    // },
    // {
    //     decimals: 18,
    //     symbol: "WETH",
    //     logo: eth,
    //     logoDark: ethW,
    //     minStake: 0,
    //     name: "Wrapped Ethereum",
    //     address: CONTRACT_INFO.Tokens.WETH,
    //     website: 'https://weth.io/',
    //     decimalsToDisplay: 4,
    //     prefillAmount: '1',
    // },
    {
        decimals: 6,
        symbol: 'USDC',
        logo: usdc,
        logoDark: usdcW,
        minStake: 200,
        name: 'USD Coin',
        address: CONTRACT_INFO.Tokens.USDC,
        website: 'https://www.centre.io/usdc',
        decimalsToDisplay: 2,
        prefillAmount: '2500',
    },
    // {
    //     decimals: 18,
    //     symbol: 'DAI',
    //     logo: dai,
    //     logoDark: daiW,
    //     minStake: 200,
    //     name: 'Dai Stablecoin',
    //     address: CONTRACT_INFO.Tokens.DAI,
    //     website: 'https://makerdao.com/en/',
    //     decimalsToDisplay: 2,
    //     prefillAmount: '2500',
    // },
    // {
    //     divToken: true, // Can use liquid div balance
    //     decimals: 8,
    //     symbol: 'wBTC',
    //     logo: wbtc,
    //     logoDark: wbtcW,
    //     minStake: 0,
    //     name: 'Wrapped Bitcoin',
    //     address: CONTRACT_INFO.Tokens.WBTC,
    //     website: 'https://wbtc.network/',
    //     decimalsToDisplay: 6,
    //     prefillAmount: '0.25',
    // },
    // {
    //     decimals: 18,
    //     symbol: 'LINK',
    //     logo: link,
    //     logoDark: linkW,
    //     minStake: 0,
    //     name: 'ChainLink',
    //     address: CONTRACT_INFO.Tokens.LINK,
    //     website: 'https://chain.link/',
    //     decimalsToDisplay: 2,
    //     prefillAmount: '100',
    // }
];

export const getToken = (params) => {
    let token = params.token?.toLowerCase();
    if (token) {
        const found = tokens.find((t) => t.address.toLowerCase() === token || t.symbol.toLowerCase() === token);
        if (found) return found;
        else return tokens[0];
    } else return tokens[0];
};
export default tokens;
