export const SET_ACCELERATOR = 'SET_ACCELERATOR';
export const ACCELERATOR_ERROR = 'ACCELERATOR_ERROR';
export const INIT = {
    loading: true,
    tokenPrices: {},
};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_ACCELERATOR:
            return { ...state, ...payload, loading: false };
        case ACCELERATOR_ERROR:
        default:
            return state;
    }
}

export default reducer;
