import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import AxionLogo from 'assets/images/axnLogo.svg';
import LogoSupernova from 'assets/images/axn_logo_supernova.svg';

const Logo = () => {
    const classes = useStyles();
    const { theme } = useSelector(store => store);

    return (
        <a href="https://axion.network" target="_blank" rel="noreferrer">
            <img 
                alt="logo"
                src={theme.mode === 'supernova' ? LogoSupernova : AxionLogo} 
                className={clsx(classes.logoIcon, { [classes.supernovaIcon]: theme.mode === 'supernova' })} 
            />
        </a>
    )
}

export default Logo;
