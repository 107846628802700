import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import clsx from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const SkeletonPageStats = ({ classes, ...props }) => {
    return (
        <div className={classes.root}>
            <Hidden smDown>
                <Skeleton variant="rect" height={180} width={180} className={classes.donut} />
            </Hidden>
            <div className={classes.stats}>
                <div className={classes.row}>
                    <Skeleton
                        variant="rect"
                        height={20}
                        width={120}
                        className={clsx(classes.mr, classes.stat)}
                    />
                    <Skeleton
                        variant="rect"
                        height={20}
                        width={120}
                        className={clsx(classes.stat)}
                    />
                </div>
                <Hidden smDown>
                    <Skeleton
                        variant="rect"
                        height={20}
                        width={120}
                        className={clsx(classes.stat)}
                    />
                </Hidden>
            </div>
        </div>
    );
};

export default withStyles(styles)(SkeletonPageStats);
