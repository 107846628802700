import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    logoIcon: {
        height: 75,
        width: 75,
        marginBottom: theme.spacing(2),
    },
    supernovaIcon: {
        filter: 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))'
    }
}));

export default useStyles;
