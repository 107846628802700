export const SET_LAUNCHES = 'SET_LAUNCHES';
export const LAUNCH_ERROR = 'LAUNCH_ERROR';
export const INIT = {
    alerts: [],
    launches: [],
};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_LAUNCHES:
            return payload;
        case LAUNCH_ERROR:
        default:
            return state;
    }
}

export default reducer;
