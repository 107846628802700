import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { useHistory } from 'react-router';
import { BsCaretUp } from 'react-icons/bs';
import { ContractContext } from 'service/provider';
import { addAXNToMetamask } from 'utils/add-to-metamask';
import { Collapse, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';

const NavItem = ({ name, tooltip, pathname, Icon, location, disabled, subItems, external }) => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const { wallet } = useContext(ContractContext)

    const hasSubItems = subItems.length > 0;
    const isAccelerator = pathname === '/accelerator';
    const submenuItemActive = subItems.some(item => item.pathname === location.pathname);
    const isActive = isAccelerator ? location.pathname.includes(pathname) : location.pathname === pathname;

    useEffect(() => {
        setOpen(submenuItemActive);

        // eslint-disable-next-line
    }, [location.pathname]);

    const openPath = (path, isExternal) => {
        if (isExternal) {
            let address = wallet?.account || ""
            window.open(pathname + address, '_blank')
        } else {
            history.push(path);
        }
    }

    let component = (
        <ListItem
            button
            key={`${pathname}`}
            onClick={hasSubItems ? () => setOpen(!open) : () => openPath(pathname, external)}
            classes={{ root: clsx(
                classes.listItem, {
                    [classes.hover]: !hasSubItems,
                    [classes.disabledItem]: disabled,
                    [classes.activeItem]: isActive || (!open && submenuItemActive),
                }
            )}}
        >
            <ListItemIcon>
                <Icon
                    active={isActive}
                    className={clsx(classes.menuIcons, {
                        [classes.activeIcon]: isActive,
                        [classes.atom]: isAccelerator,
                    })}
                />
            </ListItemIcon>

            <ListItemText
                primary={name}
                classes={{ primary: clsx({[classes.activeText]: location.pathname === pathname })}}
            />

            {hasSubItems && <BsCaretUp className={clsx(classes.openMenuIcon, { [classes.open]: open })} />}
        </ListItem>
    );

    if (tooltip) {
        component = (
            <Tooltip arrow title={tooltip} placement="right">
                {component}
            </Tooltip>
        )
    }

    if (hasSubItems) {
        return (
            <>     
                {component}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className={classes.subMenu}>
                        {subItems.map(item => {
                            if (item.hidden) return null;
                            const active = item.pathname === location.pathname;
                            let onClick = () => openPath(item.pathname, item.external);

                            if (item.isMetamask)
                                onClick = () => addAXNToMetamask()

                            return (
                                <ListItem
                                    button
                                    onClick={() => onClick()}
                                    key={`subMenu_${item.pathname}`}
                                    classes={{ root: clsx(classes.subMenuListItem, classes.hover, { [classes.activeItem]: active })}}
                                >
                                    <ListItemIcon>
                                        <item.Icon active={`${isActive}`} className={clsx(classes.menuIcons, { [classes.activeIcon]: active })}/>
                                    </ListItemIcon>

                                    <ListItemText 
                                        primary={item.name} 
                                        classes={{ 
                                            primary: clsx({
                                                [classes.activeText]: location.pathname === item.pathname 
                                            })
                                        }}
                                    />
                                </ListItem>
                            )
                        })}
                    </div>
                </Collapse>
            </>
        )
    }

    return component;
};

export default NavItem;
