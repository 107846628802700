const styles = (theme) => ({
    root: {
        minHeight: 36,
        color: theme.brand.NormalText
    },
    loader: {
        height: 20,
        width: 20,
    },
    white: {
        color: 'white',
    },
    blue: {
        color: theme.brand.axion,
    },
    supernova: {
        color: '#472903',
        filter: theme.mode === 'supernova' && 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))',
    },
    whiteContained: {
        ...theme.buttons.contained.white,
    },
    translucent: {
        ...theme.buttons.contained.translucent,
    },
});

export default styles;
