import { combineReducers } from 'redux';

// Import reducers here
import user from './user';
import theme from './theme';
import chain from './chain';
import events from './events';
import settings from './settings';
import alertbar from './alertbar';
import launches from './launches';
import snackbar from './snackbar';
import accelerator from './accelerator';
import transactions from './transactions';
import notifications from './notifications';

const rootReducer = combineReducers({
    user,
    theme,
    chain,
    events,
    settings,
    alertbar,
    snackbar,
    launches,
    accelerator,
    transactions,
    notifications,
});

export default rootReducer;
