import React from 'react';
import useStyles from './styles';
import Card from 'components/Card';

const YoutubeVideo = ({ videoID }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <iframe
                frameBorder="0"
                title="youtube embed"
                allowFullScreen={true}
                className={classes.iframe}
                src={`https://www.youtube.com/embed/${videoID}?modestbranding=1&rel=0`}
            />
        </Card>
    );
};

export default YoutubeVideo;
