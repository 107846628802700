import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    promotion: {
        color: "#ffda6f",
        border: "1px solid rgba(255,255,255,1)",
        backgroundColor: "rgba(255,255,255,0.05)",
        borderRadius: "4px",
        padding: "1%",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    nft: {
        fontWeight: 500,
        // fontWeight: "bold",
        // textDecoration: "underline"
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    colliderOrange: {
        color: theme.brand.colliderOrange,
    },
    white: {
        color: theme.brand.NormalText,
    },
    gold: {
        color: "#ffd700",
    },
    silver: {
        color: "#c0c0c0",
    }
}));

export default useStyles;
