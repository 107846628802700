import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    inputs: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${theme.brand.border}`,
            borderRight: 'none',
        },
    },
    balance: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    stat: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    borderRight: {
        borderRight: `1px solid ${theme.brand.border}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    bonusText: {
        fontWeight: 500,
        verticalAlign: 'top',
        color: theme.brand.JavaGreen,
    },
    logoSwitcher: {
        marginBottom: theme.spacing(1),
    },
    stakeFooter: {
        padding: theme.spacing(2),
    },
    summarySub: {
        fontWeight: 200,
        fontSize: '0.8rem',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    textboxSpacer: {
        margin: theme.spacing(2),
    }
}));

export default useStyles;
