import React, { useContext, useMemo } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import Card from 'components/Card';
import { ImFire } from 'react-icons/im';
import { BiDollar } from 'react-icons/bi';
import { FiRefreshCw } from 'react-icons/fi';
import { Functions } from '@material-ui/icons';
import { FaFunnelDollar } from 'react-icons/fa';
import { ContractContext } from 'service/provider';
import { AXN_BURN_ADDRESS_LINK } from 'utils/variables';
import { Avatar, Grid, Typography, useMediaQuery, CircularProgress } from '@material-ui/core';

const QuickStats = () => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { user, contractInfo, loadingContractInfo } = useContext(ContractContext);

    const stats = useMemo(() => {
        if (!loadingContractInfo) {
            const totalBurned = contractInfo?.axion?.totalBurned;
            const totalBurnedUSD = totalBurned * contractInfo?.axion?.usdPerAXN;

            const stakedSupply = contractInfo?.axion?.totalStaked;
            let circSupply = contractInfo?.axion?.totalSupply;
            circSupply -= totalBurned;
            const totalAXN = circSupply + stakedSupply;

            const circPercent = Math.round((circSupply / totalAXN) * 100);
            const stakedPercent = Math.round((stakedSupply / totalAXN) * 100);

            const total5555 = contractInfo?.axion?.totalStaked5555;
            const total5555Percent = Math.round((total5555 / totalAXN) * 100);

            return [
                {
                    icon: <Functions />,
                    title: 'Total Supply',
                    subtitle: 'Total amount of AXN in existance',
                    stat: `${totalAXN?.numberWithCommas(0) || 0} AXN`,
                },
                {
                    icon: <FiRefreshCw />,
                    title: 'Circulating Supply',
                    subtitle: 'Total amount of AXN that is circulating (not staked)',
                    stat: `${circPercent}%`,
                    substat: circSupply?.numberWithCommas(0) || 0,
                },
                {
                    icon: <BiDollar />,
                    title: 'Total Staked',
                    subtitle: 'Total amount of AXN that is staked (locked up)',
                    stat: `${stakedPercent}%`,
                    substat: stakedSupply?.numberWithCommas(0) || 0,
                },
                {
                    icon: <FaFunnelDollar />,
                    title: 'Staked for Max Shares',
                    subtitle: 'Total amount of AXN staked for 15.2 years (5,555 days)',
                    stat: `${total5555Percent}%`,
                    substat: total5555?.numberWithCommas(0) || 0,
                },
                {
                    icon: <ImFire />,
                    title: 'Total AXN Burned',
                    subtitle: 'Total amount of AXN burned (started August 16th, 2021)',
                    substat: `$${totalBurnedUSD.numberWithCommas(2)}`,
                    stat: `${totalBurned?.numberWithCommas(0) || 0} AXN`,
                    onClick: () => window.open(AXN_BURN_ADDRESS_LINK),
                },
            ];
        } else return [];

        // eslint-disable-next-line
    }, [user, contractInfo, loadingContractInfo]);

    return (
        <Card glossy>
            <div className={classes.mainTitle}>
                <Typography variant="h3">Overview</Typography>
                <Typography variant="subtitle1">Axion token basic statistics</Typography>
            </div>

            <div style={{ padding: '6px' }}>
                {stats.length === 0 && (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                        <Typography variant="h4" style={{ marginTop: '2%' }}>
                            Loading Stats...
                        </Typography>
                    </div>
                )}
                {stats.map((s, idx) => (
                    <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                        key={`stat_${idx}`}
                        className={classes.statRow}
                    >
                        <Grid item xs={8} container alignItems="center" alignContent="center">
                            <Grid item xs={1}>
                                <Avatar className={clsx(classes.icon, classes.supernovaGlow)}>
                                    {s.icon}
                                </Avatar>
                            </Grid>
                            <Grid item xs={11}>
                                <div className={classes.titleContainer}>
                                    <Typography className={classes.title} variant="h4">
                                        {s.title}
                                    </Typography>
                                    {!isMobile && (
                                        <Typography className={classes.subtitle}>
                                            {s.subtitle}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            onClick={s.onClick ?? null}
                            className={clsx({ [classes.link]: s.onClick })}
                        >
                            <div className={classes.statContainer}>
                                <Typography className={classes.stat} variant="h4">
                                    {s.stat}
                                </Typography>
                                <Typography className={classes.substat}>{s.substat}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </Card>
    );
};

export default QuickStats;
