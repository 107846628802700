import axios from 'axios';
import { KYC_API } from 'utils/variables';

/** Gets the KYC status for a particular wallet
 * @param {string} address The address to check
 * @returns {Promise<any>} Returns an object which contains a KYC status string and a wallet object {address, chain}
 */
export const getKYCStatus = async (address) => {
    const chain = "MATIC";
    try {
        const response = await axios.post(`${KYC_API}?address=${address}&chain=MATIC`, { address, chain }, {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
