import { setAlertbar } from './actions/alertbar';
import { UPDATE_TRANSACTION } from './reducers/transactions';
import { put, takeLatest, select, all } from 'redux-saga/effects';

/** UPDATE_TRANSACTION Saga */
function* transactionListener() {
    yield takeLatest(UPDATE_TRANSACTION, function* ({ payload }) {
        const txs = yield select(({ transactions }) => transactions);
        const tx = txs.find((t) => t.id === payload);

        if (tx) {
            yield put(
                setAlertbar({
                    txid: payload,
                    message: `Transaction Complete: ${tx.description}`,
                })
            );
        }
    });
}

// Connect & export all sagas
function* rootSaga() {
    yield all([transactionListener()]);
}

export default rootSaga;
