// React
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI
import { withStyles } from '@material-ui/core/styles';
// Components
import SkeletonListItem from './SkeletonListItem';
import SkeletonTableColumn from './SkeletonTableColumn';
import SkeletonPageStats from './SkeletonPageStats';
import SkeletonText from './SkeletonText';
import SkeletonTableCard from './SkeletonTableCard';

// Styles
import styles from './styles';

const Skeleton = ({ type = null, ...props }) => {
    return type !== null ? (
        <>
            {type === 'listItem' && <SkeletonListItem {...props} />}
            {type === 'tableColumn' && <SkeletonTableColumn {...props} />}
            {type === 'pageStats' && <SkeletonPageStats {...props} />}
            {type === 'text' && <SkeletonText {...props} />}
            {type === 'cardTable' && <SkeletonTableCard {...props} />}
        </>
    ) : (
        <SkeletonListItem {...props} />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
};

export default withStyles(styles)(Skeleton);
