import { SECONDS_IN_DAY } from './variables';

function getStakeInfo(amount, days, shareRate) {
    amount = isNaN(amount) ? 0 : +amount;
    days = isNaN(days) ? 1 : Math.max(1, +days);

    /** Calculate Shares lpb and total shares */
    const shares = amount / (shareRate ?? 1);
    const lpb = (amount * (days - 1)) / 1820 / (shareRate ?? 1);
    const totalShares = (amount * (1819 + days)) / (1820 * (shareRate ?? 1));

    /** Get Start and end date based on inputs */
    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + days * SECONDS_IN_DAY * 1000);

    return { shares, lpb, totalShares, startDate, endDate, days, amount };
}

export default getStakeInfo;
