import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import useStyles from './styles.js';
import Meta from '../../components/util/Meta';
import PageTitle from './PageTitle/PageTitle';
import ColliderHistory from './ColliderHistory';
import ParticleCollider from './ParticleCollider';
import ParticleCollection from './ParticleCollection';
import Background from 'components/Background/Background';
import ToggleAnimationCheckbox from 'components/ToggleAnimationCheckbox';

// Drag and drop
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Collider = (props) => {
    const classes = useStyles();
    const [nft, setNFT] = useState(null);
    const [collided, setCollided] = useState(false);
    const [colliding, setColliding] = useState(false);

    useEffect(() => {
        if (props.location.state?.selected)
            setNFT(props.location.state.selected);
    }, [props.location.state])

    const setSelectedNFT = n => {
        if (nft && nft.metadata.id === n?.metadata?.id) {
            setNFT(null);
            return;
        } 
        
        setNFT(n);
    }

    return (
        <React.Fragment>
            <Background />
            <ToggleAnimationCheckbox speed={3} />
            <Meta title={`Axion | Collider`} />
            <Page header={<PageTitle />}>
                <DndProvider backend={HTML5Backend}>
                    <div className={classes.colliderContainer}>
                        <ParticleCollider 
                            nft={nft} 
                            collided={collided}
                            colliding={colliding} 
                            setNFT={setSelectedNFT} 
                            setCollided={setCollided}
                            setColliding={setColliding} 
                        />
                        <ParticleCollection 
                            nft={nft} 
                            collided={collided}
                            colliding={colliding} 
                            setNFT={setSelectedNFT} 
                        />
                        <ColliderHistory />
                    </div>
                </DndProvider>
            </Page>
        </React.Fragment>
    );
};

export default Collider;
