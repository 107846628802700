import React, { useContext, useState } from 'react';

// Modules
import useStyles from './styles';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { SOCIAL_LINKS } from 'utils/variables';
import { ContractContext } from 'service/provider';

// Components
import Profile from './Profile';
import Logo from 'components/Logo';
import MenuItems from './MenuItems';
import GasTracker from './GasTracker';
import LiveTicker from './LiveTicker';
import ThemeSwitcher from 'components/ThemeSwitcher';
import TransactionModal from 'components/Modals/TransactionsModal';
import ConnectWalletModal from 'components/Modals/ConnectWalletModal';
import { Drawer, SwipeableDrawer, Hidden, Tooltip } from '@material-ui/core';

function NavDrawer({ open, onOpen, onClose }) {
    const classes = useStyles();

    const [showTx, setShowTx] = useState(false);
    const { theme } = useSelector(store => store);
    const { wallet, user } = useContext(ContractContext);
    const [connection, setConnection] = useState({ open: false, connect: null });

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className={classes.top}>
                    <div className={classes.wallet}>
                        <Logo />
                        <Profile setConnection={setConnection} setShowTx={setShowTx} />
                        <GasTracker />
                    </div>
                    <div className={classes.ticker}>
                        <LiveTicker />
                    </div>
                    <MenuItems />
                </div>

                <div style={{ width: '100%' }}>
                    <div className={classes.bottom}>
                        {user?.hasNFT && user?.hasNFT("SUPERNOVA") && (
                            <div className={classes.themeSwitcher}>
                                <ThemeSwitcher />
                            </div>
                        )}
                        <div className={classes.bottomRow}>
                            {SOCIAL_LINKS.map(({ title, link, Icon }, idx) => (
                                <Tooltip arrow title={title} placement="top" key={`${title}-idx`}>
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <Icon fill={theme.brand.NormalText} className={classes.social}/>
                                    </a>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Hidden mdDown>
                <Drawer variant="permanent" classes={{ paper: classes.drawer }}>
                    {renderContent()}
                </Drawer>
            </Hidden>
            
            <Hidden lgUp>
                <SwipeableDrawer
                    open={open}
                    anchor={'left'}
                    onOpen={onOpen}
                    onClose={onClose}
                    classes={{ paper: classes.drawer }}
                >
                    {renderContent()}
                </SwipeableDrawer>
            </Hidden>

            <TransactionModal open={showTx} wallet={wallet} onClose={() => setShowTx(false)} />
            <ConnectWalletModal open={connection.open} onClose={() => setConnection({ open: false })} connect={connection.connect} wallet={wallet} />
        </React.Fragment>
    );
}

export default withRouter(NavDrawer);
