import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    result: {
        marginBottom: 30,
    },
    resultContainer: {
        '& > div:last-child': {
            marginBottom: 10,
        },
    },
    subText: {
        fontStyle: 'italic',
    },
    progress: {
        borderRadius: 4,
        padding: '0.5%',
        margin: '1% 0',
    },
    errorText: {
        fontSize: '0.8em',
    },
}));

export default useStyles;
