import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { ANIMATION_KEY } from 'containers/Accelerator/variables';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

const ToggleAnimationCheckbox = ({ speed = 1 }) => {
    const classes = useStyles();
    const [particlesDisabled, setParticlesDisabled] = useState(false);
 
    const setAnimationSpeed = (s) => {
        if (window.pJSDom) {
            for (let i = 0; i < window.pJSDom.length; i++) {
                window.pJSDom[i].pJS.particles.move.speed = s;
                window.pJSDom[i].pJS.particles.opacity.anim.enable = s === 0;
            }
        }
    }

    useEffect(() => {
        const disabled = localStorage.getItem(ANIMATION_KEY) === '1';
        if (disabled) {
            setAnimationSpeed(0);
            setParticlesDisabled(true);
        }
    }, [])

    const handleAnimateParticles = (_, animated) => {
        if (!animated) {
            setAnimationSpeed(speed);
            setParticlesDisabled(false);
            localStorage.removeItem(ANIMATION_KEY);
        } else {
            setAnimationSpeed(0);
            setParticlesDisabled(true);
            localStorage.setItem(ANIMATION_KEY, '1');
        }
    };

    return (
        <div className={classes.toggleAnimations}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={particlesDisabled}
                        onChange={handleAnimateParticles}
                        size="small"
                        color="primary"
                    />
                }
                label={
                    <Typography className={classes.toggleLabel}>
                        Disable Particle Animations
                    </Typography>
                }
            />
        </div>
    );
}

export default ToggleAnimationCheckbox;
