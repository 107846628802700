import axios from 'axios';
import Contract from './contract';
import { store } from 'redux/config';
import { base, routes } from 'api/requests';
import { addTx, completedTx } from 'redux/actions/transactions';
import { ETH_CHAIN, MATIC_CHAIN, BSC_CHAIN } from 'utils/variables';

const dispatch = store.dispatch;

class Vesting extends Contract {

    /** Get all vesting items for the current user 
      * from all current chains (Ethereum, Polygon and BSC) 
      */
    async getUserVestings() {
        let ethVestings = [];
        let bscVestings = [];
        let polygonVestings = [];

        // Get vestings from Ethereum
        try { ethVestings = await this.getUserItemsEth() } 
        catch (err) { console.log("Unble to get ETH vestings", err) }

        // Get vestings from Polygon
        try { polygonVestings = await this.getUserItemsPolygon() } 
        catch (err) { console.log("Unble to get Polygon vestings", err) }

        // Get vestings from BSC
        try { bscVestings = await this.getUserItemsBSC() }
        catch (err) { console.log("Unble to get BSC vestings", err) }

        return [...polygonVestings, ...ethVestings, ...bscVestings];
    }

    async getUserItemsPolygon() {
        try {
            const userLength = await this.getUserVestsLength(MATIC_CHAIN);
            if (userLength === '0') return [];

            const registeredRecords = await this.getAvailableVestsContract(userLength, MATIC_CHAIN);
            return this.formatVesting(registeredRecords, MATIC_CHAIN);
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    async getUserItemsBSC() {
        try {
            const userLength = await this.getUserVestsLength(BSC_CHAIN);
            if (userLength === '0') return [];

            const registeredRecords = await this.getAvailableVestsContract(userLength, BSC_CHAIN);
            return this.formatVesting(registeredRecords, BSC_CHAIN);
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    async getUserItemsEth() {
        let items = [];

        try {
            const [number, dbRecords] = await Promise.all([
                this.getUserVestsLength(ETH_CHAIN),
                this.getAvailableVestsAPI(),
            ]);

            // No vests
            if (number === '0' && dbRecords.length === 0) return [];

            const registeredRecords = await this.getAvailableVestsContract(number, ETH_CHAIN);
            if (registeredRecords.length !== dbRecords.length) {
                const globalItems = await this.getGlobalItems(ETH_CHAIN);
                if (globalItems.length === 0) return [];

                // Loop through db records to find out which records are registered
                // If not registered, use a combination of globalItems and dbRecords
                dbRecords.forEach((dbr) => {
                    const registered = registeredRecords.find((rv) => rv.item.name === dbr.name);
                    if (registered) items.push(registered);
                    else {
                        items.push({
                            record: dbr,
                            item: globalItems.find((item) => item.name === dbr.name),
                        });
                    }
                });
            } else items = registeredRecords;

            return this.formatVesting(items, ETH_CHAIN);
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    async getGlobalItems(chain) {
        return this._getContract(chain).methods.getAllItems().call();
    }

    async getAvailableVestsContract(num,chain) {
        return this._getContract(chain).methods.getUserItems(Contract.account, 0, num).call();
    }

    async getAvailableVestsAPI() {
        const request = await axios.get(`${base}${routes.getVestings}?address=${Contract.account}`);
        return request.data.Items;
    }

    async getUserVestsLength(chain) {
        return this._getContract(chain).methods.getUserVestsLength(Contract.account).call();
    }

    async formatVesting(items, chain) {
        let vests = [];
        const now = Date.now() / 1000;

        for (const { item, record } of items) {
            let tokenInfo = await this[
                chain === ETH_CHAIN ? 'getTokenInfoETH' : 'getTokenInfo'
            ](item.token, chain);

            // Item
            const _network = chain;
            const vestingName = item.name;
            const startTime = +item.startTime;
            const cliffTime = +item.cliffTime;
            const bonusUnlockTime = +item.bonusUnlockTime;
            const unlockDelay = +item.timeBetweenUnlocks;

            // Record
            const userTotalAmount = +record.amount;
            const percentBonus = +record.percentBonus;
            const bonusWithdrawn = record.bonusWithdrawn;
            const withdrawals = +record.withdrawals || 0;
            const percentInitial = +record.percentInitial;
            const totalWithdrawn = +record.totalWithdrawn || 0;
            const percentPerWd = +record.percentAmountPerWithdraw;
            const bonusAmount = (userTotalAmount / 100) * percentBonus;

            // Figure out how many withdrawals are allowed
            const maxWd = percentPerWd === 0 ? 1 : Math.floor((100 - percentInitial) / percentPerWd);
            const maxAllowedWd = Math.floor((now - (startTime + cliffTime)) / unlockDelay + 1);

            const add = withdrawals !== 0 ? 1 : 0;
            const minAllowed = Math.min(maxWd, maxAllowedWd);
            const allowedWd = Math.max(0, minAllowed - withdrawals + add);

            // Figure out how much the user can currently claim
            const isAllAvailable = now > startTime && percentInitial === 100 && withdrawals === 0;
            const availableAmount = Math.min(userTotalAmount, ((percentPerWd * userTotalAmount) / 100) * allowedWd);
            let available = availableAmount;

            // Add the initial amount if withdraws are 0
            if (percentInitial !== 0 && withdrawals === 0) 
                available += (percentInitial * userTotalAmount) / 100;

            // 0 if havent started yet
            if (now < startTime || available < 0) 
                available = 0;

            // No vesting, all up front
            if (isAllAvailable) 
                available = userTotalAmount;

            // Figure out when the next claim time is
            let nextClaim = startTime + cliffTime + maxAllowedWd * unlockDelay;

            // Timer to start if not started yet
            if (now < startTime)
                nextClaim = startTime;

            // If all is available to claim, set next claim to now
            if (totalWithdrawn + available === userTotalAmount)
                nextClaim = 0;

            // If the user has withdrawn the total amount, set nextClaim to bonusUnlockTime
            if (totalWithdrawn === userTotalAmount) 
                nextClaim = bonusUnlockTime;

            // If everyting is available, set nextClaim to 0
            if (isAllAvailable && bonusAmount === 0) 
                nextClaim = 0;

            // All bonus to claimed if withdrawn
            let claimed = totalWithdrawn;
            if (bonusWithdrawn) claimed += bonusAmount;

            let vest = {
                maxWd,
                startTime,
                allowedWd,
                unlockDelay,
                withdrawals,
                bonusWithdrawn,
                bonusUnlockTime,
                name: vestingName,
                address: item.token,
                token: tokenInfo.name,
                symbol: tokenInfo.symbol,
                timeUntilNext: nextClaim,
                decimals: tokenInfo.decimals,
                claimed: claimed / 10 ** tokenInfo.decimals,
                available: available / 10 ** tokenInfo.decimals,
                bonusAmount: bonusAmount / 10 ** tokenInfo.decimals,
                allocation: userTotalAmount / 10 ** tokenInfo.decimals,

                _network,
                _rawRecord: record,
                _available: availableAmount / 10 ** tokenInfo.decimals,
            };

            vests.push(vest);
        }

        return vests;
    }

    async addSelfVester(item) {
        const info = item._rawRecord;
        return this._getContract(ETH_CHAIN, true).methods.addVesterCryptography(
                info.signature,
                info.name,
                info.percentInitial,
                info.percentAmountPerWithdraw,
                info.percentBonus,
                info.amount
            )
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: `Withdrawal for ${item.name}` }))
            );
    }

    withdraw(item) {
        if (item._rawRecord.signature) return this.addSelfVester(item);
        else {
            return this._getContract(null, true).methods.withdraw(item.name)
                .send({ from: Contract.account })
                .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
                .on('transactionHash', (id) =>
                    dispatch(addTx({ id, description: `Withdrawal for ${item.name}` }))
                );
        }
    }

    withdrawBonus(item) {
        return this._getContract(null, true).methods.bonus(item.name)
            .send({ from: Contract.account })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) =>
                dispatch(addTx({ id, description: `Withdraw Bonus for ${item.name}` }))
            );
    }

    _getContract(chain, write = false) {
        if (!chain) chain = store.getState().chain;

        if (chain === ETH_CHAIN) {
            // Need a different contract for making the tx because
            // the infura provider we are using doesnt support signing tx.
            // Need to use the web3 provider (e.g: from metamask) to sign and send the tx
            if (write) return Contract.contracts.VestingETH;
            return Contract.contracts.eth.Vesting;
        }
        if (chain === BSC_CHAIN) {
            if (write) return Contract.contracts.VestingBSC;
            return Contract.contracts.bsc.Vesting;
        }
        else return Contract.contracts.Vesting;
    }
}

export default Vesting;
