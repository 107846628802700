import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';

const Background = ({ bg = '4', bottom = false, styles }) => {
    const bgKey = `bg${bg}`;
    const classes = useStyles();

    return (
        <div
            style={styles}
            id="background-image"
            className={clsx({
                [classes.root]: true,
                [classes[bgKey]]: true,
                [classes.positionBottom]: bottom,
            })}
        />
    );
};

export default Background;
