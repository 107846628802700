import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    barTitle: {
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'justify !important',
        },
    },
    title: {
        fontSize: 42,
        fontWeight: '500',

        textAlign: 'left !important',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center !important',
        }
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
