import React, { Fragment, useContext, useMemo } from 'react';
import moment from 'moment';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { ContractContext } from 'service/provider';
import { PHOENIX_PROMOTION, PHOENIX_MINT_END_DATE } from '../../containers/Accelerator/variables';
import { HelpOutline } from '@material-ui/icons';
import useStyles from './styles';
import clsx from 'classnames';

const UpgradeStake = ({ data }) => {
    const classes = useStyles();
    const {loadingContractInfo, contractInfo} = useContext(ContractContext);

    const shareRate = useMemo(() => {
        if (!loadingContractInfo)
            return parseFloat(contractInfo.staking.shareRate / 1e18).toFixed(4);
        else return 1;
    }, [loadingContractInfo, contractInfo]);

    const phoenixPromotionEligibility = useMemo(() => {
        if (Date.now() / 1000 >= PHOENIX_MINT_END_DATE) return null;
        const idx = PHOENIX_PROMOTION.findIndex(promotion => data.stake.principal >= promotion.upgrade.minAXN && data.stake.principal < promotion.upgrade.maxAXN);
        return idx === 0 ? null : PHOENIX_PROMOTION[idx];
    }, []);

    return (
        <div>
            <Typography>
                Upgrade this stake and receive max shares at the current share rate (
                {shareRate}). Any accumulated interest will be added
                to your principal and re-staked for 5,555 days.
            </Typography>
            <br />

            {phoenixPromotionEligibility && (
                <div className={classes.promotion}>
                    <Typography align="center" variant="body2" style={{ fontSize: "1rem" }}>
                        This upgrade qualifies for a free <span className={
                            clsx(classes.nft, {
                                [classes.colliderOrange]: phoenixPromotionEligibility.discount === 2,
                                [classes.silver]: phoenixPromotionEligibility.discount === 3,
                                [classes.gold]: phoenixPromotionEligibility.discount === 4
                            })}>
                            {phoenixPromotionEligibility.name} Phoenix NFT
                        </span>
                    </Typography>
                    <Typography align="center" variant="body2">
                        It will be automatically minted to your wallet{" "}
                        <Tooltip title={
                            <div>
                                <Typography variant="body2">The Phoenix NFT is a special utility NFT will grant you an accelerator discount.</Typography>
                                <br />
                                <div className={classes.col}>
                                    <Typography variant="caption">{PHOENIX_PROMOTION[1].upgrade.minAXN.shorten()} - {PHOENIX_PROMOTION[1].upgrade.maxAXN.shorten()} AXN = {PHOENIX_PROMOTION[1].name} NFT ({PHOENIX_PROMOTION[1].discount}% discount)</Typography>
                                    <Typography variant="caption">{PHOENIX_PROMOTION[2].upgrade.minAXN.shorten()} - {PHOENIX_PROMOTION[2].upgrade.maxAXN.shorten()} AXN = {PHOENIX_PROMOTION[2].name} NFT ({PHOENIX_PROMOTION[2].discount}% discount)</Typography>
                                    <Typography variant="caption">{PHOENIX_PROMOTION[3].upgrade.minAXN.shorten()}+ AXN = {PHOENIX_PROMOTION[3].name} NFT ({PHOENIX_PROMOTION[3].discount}% discount)</Typography>
                                </div>
                            </div>
                        }>
                            <HelpOutline style={{ fontSize: "0.85rem" }} />
                        </Tooltip>
                    </Typography>
                </div>
            )}

            <Typography variant="h4">Principal</Typography>
            <Typography>
                {data?.stake.principal.numberWithCommas(0)} AXN will become{' '}
                {data?.amount.numberWithCommas(0)} AXN
            </Typography>
            <br />

            <Typography variant="h4">Shares</Typography>
            <Typography>
                {data?.stake.shares.numberWithCommas(0)} shares will become{' '}
                {data?.totalShares.numberWithCommas(0)} shares
            </Typography>
            <br />

            <Typography variant="h4">End Date</Typography>
            <Typography>
                {moment(data?.stake.endSeconds * 1000).format('MMM DD, YYYY')} will change to{' '}
                {moment(data?.endDate).format('MMMM DD, YYYY')}
            </Typography>
        </div>
    );
};

export default UpgradeStake;
