import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const emptyTimer = { d: '00', h: '00', m: '00', s: '00' };
const pad = (n) => (n < 10 ? '0' + n : n);

const Countdown = ({ end, onEnd, unlockSeconds }) => {
    const [components, setComponents] = useState({ ...emptyTimer });

    const checkTime = (e) => {
        let secondsUntilEnd = moment(e).diff(moment(), 'seconds');

        let days = Math.floor(secondsUntilEnd / 24 / 60 / 60);
        let hoursLeft = Math.floor(secondsUntilEnd - days * 86400);
        let hours = Math.floor(hoursLeft / 3600);
        let minutesLeft = Math.floor(hoursLeft - hours * 3600);
        let minutes = Math.floor(minutesLeft / 60);
        let remainingSeconds = secondsUntilEnd % 60;

        if (remainingSeconds <= 0 && minutes <= 0 && hours <= 0 && days <= 0) {
            onEnd?.();
            end += unlockSeconds * 1000;
            return emptyTimer;
        }

        return {
            d: pad(days),
            h: pad(hours),
            m: pad(minutes),
            s: pad(remainingSeconds),
        };
    };

    useEffect(() => {
        setComponents(checkTime(end));

        const interval = setInterval(() => {
            setComponents(checkTime(end));
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [end]);

    return (
        <Typography>
            {+components.d !== 0 && `${components.d}:`}
            {components.h}:{components.m}:{components.s}
        </Typography>
    );
};

export default Countdown;
