import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import useStyles from './styles';
import Button from 'components/Button';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { Card, Grid, Typography } from '@material-ui/core';

const ProposalOverview = ({ viewDetail, proposal }) => {
    const classes = useStyles();
    const history = useHistory();

    const onShowDetails = () => {
        viewDetail();
        history.push(`/vote/${proposal.id}`);
    };

    const getStatus = () => {
        const now = Date.now() / 1000;
        const end = proposal.end;
        const start = proposal.start;

        if (now >= end) return 'Complete';
        else if (now <= start) return 'Starting Soon';
        else return 'In Progress';
    };

    const getMarkdownString = () => {
        let md = proposal.body.replace('**', '').substring(0, 500);
        let length = 225;

        // If there is a URL
        const matches = md.match(/(?:https?|ftp):\/\/[\n\S]+/g);
        if (matches) length += matches[0].length;

        md = md.substring(0, length);
        md = md.replace('\n\n', ' ');

        return md + '...';
    };

    return (
        <Card classes={{ root: classes.card }}>
            <div className={classes.cardHeader}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h2" onClick={onShowDetails} className={classes.link}>
                            {proposal.title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div
                            className={clsx(classes.badge, {
                                [classes.badgeComplete]: getStatus() === 'Complete',
                                [classes.badgeActive]: getStatus() === 'In Progress',
                                [classes.badgePending]: getStatus() === 'Starting Soon',
                            })}
                        >
                            <Typography color="inherit">{getStatus()}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.cardBody}>
                <ReactMarkdown
                    components={{
                        p: ({ node, ...props }) => <Typography className={classes.p} {...props} />,
                        a: ({ node, ...props }) => (
                            <Typography
                                {...props}
                                className={classes.a}
                                onClick={() => window.open(node.properties.href)}
                            />
                        ),
                    }}
                >
                    {getMarkdownString()}
                </ReactMarkdown>
                <br />
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography>
                            <b>Start:</b>&nbsp;
                            {moment.unix(proposal.start).format('MMM DD, YYYY hh:mm a')}
                        </Typography>
                        <Typography>
                            <b>End:</b>&nbsp;
                            {moment.unix(proposal.end).format('MMM DD, YYYY hh:mm a')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            fullWidth
                            override="translucent"
                            onClick={onShowDetails}
                            variant="contained"
                        >
                            <Typography>
                                {getStatus() === 'In Progress'
                                    ? 'Learn Details & Vote'
                                    : 'See Results'}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
};

export default ProposalOverview;
