import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, styles }) => {
    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {styles && <style>{styles}</style>}
        </Helmet>
    );
};

export default Meta;
