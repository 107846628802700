import React from 'react';

// Modules
import clsx from 'classnames';

// styles
import useStyles from './styles';

const CardBody = ({ children, borderBottom, ...props }) => {
    const classes = useStyles(props);

    const rootClasses = clsx(classes.root, {
        [classes.borderBottom]: borderBottom,
    });

    return (
        <div className={rootClasses} {...props}>
            {children}
        </div>
    );
};

export default CardBody;
