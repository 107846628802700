import BN from 'bn.js';
import ABI from '../abi.json';
import { CONTRACT_INFO, _1E18, ETHEREUM_TOKEN, DEFAULT_GAS_PRICE, BSC_CHAIN } from 'utils/variables';

class Contract {
    /** Static initializer functions
     * ******************************************
     * ******************************************
     * ******************************************
     * ******************************************
     */
    static initialize({ contracts, web3, ethWeb3, bscWeb3 }) {
        Contract.web3 = web3;
        Contract.ethWeb3 = ethWeb3;
        Contract.bscWeb3 = bscWeb3;
        Contract.contracts = contracts;
    }

    static setAccount(account) {
        Contract.account = account;
    }

    static async getAllContractDetails(library) {
        if (!Contract.contracts || !Contract.web3)
            throw new Error('Contracts must be initialized');

        const [axion, pledgeEngine, staking, utility, accelerator] = await Promise.all([
            library.Axion.getInfo(),
            library.Pledge.getPledges(),
            library.Staking.getInfo(),
            library.Utility.getInfo(),
            library.Accelerator.getInfo()
        ]);
        
        Contract.axion = axion;
        Contract.staking = staking;
        Contract.utility = utility;
        Contract.accelerator = accelerator;
        Contract.pledgeEngine = pledgeEngine;

        return { staking, accelerator, axion, utility, pledgeEngine };
    }

    /**
     * Get token information from any ERC20 token address.
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
    static async getTokenInfo(address) {
        if (address === '0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF') {
            return { name: 'Ethereum', symbol: 'ETH', decimals: 18 };
        }

        const tokenContract = new Contract.web3.eth.Contract(ABI.ERC20.ABI, address);
        const [name, symbol, decimals] = await Promise.all([
            tokenContract.methods.name().call(),
            tokenContract.methods.symbol().call(),
            tokenContract.methods.decimals().call(),
        ]);

        return { name, symbol, decimals: +decimals };
    }

    /**
     *
     * @param {*} abi
     * @param {*} address
     */
    static getContract(abi, address) {
        return new Contract.web3.eth.Contract(abi, address);
    }

    /** END Static initializer functions
     * ******************************************
     * ******************************************
     * ******************************************
     * ******************************************
     */

    /** Class Inheritance functions
     * ******************************************
     * ******************************************
     * ******************************************
     * ******************************************
     */

    /**
     * Check a specific spender's address to see if it has access to see if
     * Axion is allowed to be spent by that contract.
     *
     * @param {string} amount - The amount of the bid in wei (10^18)
     * @param {string} address - The spender address (Staking contract, Mining contract, etc.)
     * @returns {Promise<boolean>} A flag determining if the user is approved.
     */
    async checkApproval(amount, address) {
        const allowance = new BN(
            await Contract.contracts.AXN.methods.allowance(Contract.account, address).call()
        );
        return !allowance.sub(new BN(amount)).isNeg();
    }

    /**
     *
     * @param {*} amount
     * @param {*} mineAddress
     * @param {*} lpTokenAddress
     */
    async isTokenApproved(amount, approvedFor, tokenAddress) {
        const token = this.getContract(ABI.ERC20.ABI, tokenAddress);
        const allowance = await token.methods.allowance(Contract.account, approvedFor).call();

        const allow = new BN(allowance);
        const allowed = allow.sub(amount);
        return !allowed.isNeg();
    }

    /** Get token information from any ERC20 token address. 
     *
     * @param {string} address - The address of the ERC20 token
     * @param {number} chain - The chain of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
    async getTokenInfo(address, chain) {
        let tokenContract = new Contract.web3.eth.Contract(ABI.ERC20.ABI, address);
        if (chain === BSC_CHAIN) tokenContract = new Contract.bscWeb3.eth.Contract(ABI.ERC20.ABI, address);
        const [name, symbol, decimals] = await Promise.all([
            tokenContract.methods.name().call(),
            tokenContract.methods.symbol().call(),
            tokenContract.methods.decimals().call(),
        ]);

        return { name, symbol, decimals: +decimals };
    }

    /** Get token information from any ERC20 token address on Ethereum. 
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
       async getTokenInfoETH(address) {
        const tokenContract = new Contract.ethWeb3.eth.Contract(ABI.ERC20.ABI, address);
        const [name, symbol, decimals] = await Promise.all([
            tokenContract.methods.name().call(),
            tokenContract.methods.symbol().call(),
            tokenContract.methods.decimals().call(),
        ]);

        return { name, symbol, decimals: +decimals };
    }

    /** Get the balance of a token for the current user
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} The current balance
     */
    getBalanceOf(address) {
        if (address === ETHEREUM_TOKEN.tokenAddress)
            return Contract.web3.eth.getBalance(Contract.account);

        const tokenContract = new Contract.web3.eth.Contract(ABI.ERC20.ABI, address);
        return tokenContract.methods.balanceOf(Contract.account).call();
    }

    /** Get the allowance of a token for the current user
     *
     * @param {string} token - The address of the ERC20 token
     * @param {string} spender - The address of the spender contract
     * @returns {Promise<any>} The current allowance
     */
    getAllowanceOf(token, spender) {
        if (token === ETHEREUM_TOKEN.tokenAddress) return 1e18;

        const tokenContract = new Contract.web3.eth.Contract(ABI.ERC20.ABI, token);
        return tokenContract.methods.allowance(Contract.account, spender).call();
    }

    async getWethToTokenAmountsOut(amount, tokenAddress) {
        if (tokenAddress === CONTRACT_INFO.Tokens.WETH) {
            return [_1E18, _1E18];
        }

        return Contract.contracts.SwapRouter.methods
            .getAmountsOut(amount, [CONTRACT_INFO.Tokens.WETH, tokenAddress])
            .call();
    }

    /**
     *
     * @param {*} abi
     * @param {*} address
     */
    getContract(abi, address) {
        return new Contract.web3.eth.Contract(abi, address);
    }

    async getTokenToUsdcAmountsOutAsync(tokenAddress, amount) {
        const outputAmounts = await Contract.contracts.SwapRouter.methods.getAmountsOut(amount, [tokenAddress, CONTRACT_INFO.Tokens.USDC]).call()
        return outputAmounts[1]
    }

    /**
     * Helper function for that
     * reduces amount passed in by percent (e.g: for slippage)
     *
     * @param {string} amount - The amount to reduce
     * @param {number} percent - The percent to reduce by
     * @returns {string>} The reduced amount
     */
    reduceAmountByPercent(amount, percent) {
        return new BN(amount).muln((100 - percent) / 100).toString();
    }

    /**
     * Helper function that returns the current gas price
     * @returns {Promise<string>} The current gas price
     */
    async getGasPrice() {
        if (Contract.web3) {
            try {
                const gas = await Contract.web3.eth.getGasPrice();
                if (parseInt(gas) < parseInt(DEFAULT_GAS_PRICE)) return DEFAULT_GAS_PRICE;

                // increase gas price by 5%
                return new BN(gas).muln(105).divn(100).toString();
            } catch (err) { return DEFAULT_GAS_PRICE }
        } else return DEFAULT_GAS_PRICE;
    }
}

export default Contract;
