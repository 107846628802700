import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Hook that allows for easy access of a users KYC status.
 */
const useCheckKYC = () => {
    const { user } = useSelector(store => store);
    const [kyc, setKYC] = useState({
        loading: true,
        status: '',
        wallet: {
            address: '',
            chain: '',
        },
    });

    useEffect(() => {
        if (user) setKYC(user.kyc);
    }, [user]);

    return kyc;
};

export default useCheckKYC;
