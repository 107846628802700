import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toggleAnimations: {
        opacity: 0.6,
        float: 'right',
        position: 'relative',
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toggleLabel: {
        fontSize: '0.8rem',
    },
}));

export default useStyles;
