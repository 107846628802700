import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    option: {
        cursor: 'pointer',
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(0.75)}px`,
        },
    },
    active: {
        fontWeight: 500,
        backgroundColor: theme.brand.border,
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,

        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(0.75)}px`,
        },
    },
    options: {
        fontWeight: 300,
        userSelect: 'none',
    },

    basic: {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    advanced: {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
}));

export default useStyles;
