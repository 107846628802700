import React, { useContext, useMemo } from 'react';
import StakeInput from 'components/StakeInput';
import { Typography } from '@material-ui/core';
import { ContractContext } from 'service/provider';

const Restake = ({ data, topUp, setTopUp, restakeDays, setRestakeDays }) => {
    const {loadingContractInfo, contractInfo} = useContext(ContractContext);

    const shareRate = useMemo(() => {
        if (!loadingContractInfo)
            return parseFloat(contractInfo.staking.shareRate / 1e18).toFixed(4);
        else return 1;
    }, [loadingContractInfo, contractInfo]);

    return (
        <div>
            <Typography>
                Put your completed stake earnings (principal + interest) into a new stake in one
                transaction. Enter the length of the restake as well as any additional top up amount,
                then click the "Restake" button. The new stake would have a global share rate of {shareRate}.
            </Typography>
            <br />

            <StakeInput
                showBPD
                restake
                amount={topUp}
                session={data?.stake}
                stakeDays={restakeDays}
                stakeLabel="Restake Days"
                onChangeAmount={setTopUp}
                amountLabel="Top Up (optional)"
                onChangeDays={setRestakeDays}
            />
        </div>
    );
};

export default Restake;
