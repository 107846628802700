const apexDonutStyle = ({ theme, labels, toolTipFormatter, totalFormatter, hoverFormatter }) => ({
    options: {
        colors: [theme.brand.primary, theme.brand.accent],
        labels,
        stroke: {
            show: true,
            colors: ['rgba(0,0,0,0.0)'],
        },
        legend: {
            show: false,
        },
        tooltip: {
            y: {
                show: true,
                formatter: toolTipFormatter,
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        value: {
                            formatter: hoverFormatter,
                            color: theme.brand.SecondaryText,
                        },
                        total: {
                            show: true,
                            // showAlways: true,
                            color: theme.brand.SecondaryText,
                            label: 'Total',
                            formatter: totalFormatter,
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
    },
});

export default apexDonutStyle;
