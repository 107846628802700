import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GOOGLE_ANALYTICS_ID } from 'utils/variables';

const RouteAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('event', 'page_view', {
                page_path: location.pathname,
                send_to: GOOGLE_ANALYTICS_ID,
            });
        }
    }, [location]);

    return <div></div>;
};

export default RouteAnalytics;
