import React, { useContext, useMemo, useState } from 'react';

// Modules
import clsx from 'classnames';
import { ContractContext } from 'service/provider';

// Components
import Stat from 'components/Stat';
import Chart from 'react-apexcharts';
import Skeleton from 'components/Skeleton';
import Dialog from 'components/Modals/BasicModal';
import { InfoOutlined } from '@material-ui/icons';
import { Typography, useTheme, Hidden, IconButton } from '@material-ui/core';

// styles
import useStyles from './styles';
import apexDonutStyle from 'utils/apex-donut-style';
import { APR, MIN_STAKE_AMOUNT } from 'utils/variables';

const PageTitle = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [showInfo, setShowInfo] = useState(false);
    const { contractInfo, loadingContractInfo } = useContext(ContractContext);

    const stats = useMemo(() => {
        let liquid = 0;
        let staked = 0;
        let priceUSD = 0;

        if (!loadingContractInfo && contractInfo?.axion) {
            liquid = contractInfo?.axion?.totalSupply - contractInfo?.axion?.totalBurned;
            staked = contractInfo?.axion?.totalStaked;
            priceUSD = contractInfo?.axion?.usdPerAXN;
        }

        return { liquid, staked, priceUSD };
    }, [contractInfo, loadingContractInfo]);

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Staking{' '}
                    <IconButton onClick={() => setShowInfo(true)}>
                        <InfoOutlined className={classes.icon} />
                    </IconButton>
                </Typography>
                <Typography className={classes.body} variant="h6">
                    Stake your Axion and earn revenue from the daily accelerator as well as a
                    guaranteed {APR}% APR! By creating a stake, your AXN tokens are burned in
                    exchange for Shares, which indicates your share in the distribution of rewards
                    from the daily dividend pool.
                </Typography>
            </div>
            {loadingContractInfo ? (
                <Skeleton type="pageStats" />
            ) : (
                <div className={classes.barStats}>
                    <Hidden smDown>
                        <Chart
                            type="donut"
                            series={[stats.liquid, stats.staked]}
                            height={200}
                            {...apexDonutStyle({
                                theme,
                                labels: ['Liquid', 'Staked'],
                                toolTipFormatter: (x) => {
                                    return `${x.numberWithCommas(2)} AXN`;
                                },
                                totalFormatter: (w) => {
                                    return (stats.liquid + stats.staked).shorten();
                                },
                                hoverFormatter: (val) => {
                                    return parseFloat(val).shorten();
                                },
                            })}
                        />
                    </Hidden>
                    <div className={classes.stats}>
                        <div className={classes.row}>
                            <Stat
                                classes={{ root: clsx(classes.stat, classes.mr) }}
                                legend={{ color: theme.brand.accent }}
                                value={stats.staked.numberWithCommas(0)}
                                usdValue={(stats.staked * stats.priceUSD).numberWithCommas(2)}
                            />
                            <Stat
                                classes={{ root: classes.stat }}
                                title={'Liquid'}
                                legend={{ color: theme.brand.primary }}
                                value={stats.liquid.numberWithCommas(0)}
                                usdValue={(stats.liquid * stats.priceUSD).numberWithCommas(2)}
                            />
                        </div>

                        <Hidden smDown>
                            <Stat
                                classes={{ root: classes.stat }}
                                title={'Total Supply'}
                                value={(stats.liquid + stats.staked).numberWithCommas(0)}
                                usdValue={(
                                    (stats.liquid + stats.staked) *
                                    stats.priceUSD
                                ).numberWithCommas(2)}
                            />
                        </Hidden>
                    </div>
                </div>
            )}
            <Dialog
                open={showInfo}
                onClose={() => setShowInfo(false)}
                cancelButtonText="I understand"
                title="Staking Information"
                message={
                    <React.Fragment>
                        <Typography style={{ fontWeight: 300 }}>
                            Stake your Axion and earn a guaranteed APR of {APR}% for the duration of
                            your stake, as well as "liquid" rewards that can be withdrawn at any
                            time. By creating a stake, the user loses access to their Axion tokens,
                            but receives Shares, indicating the user's share in the distribution of
                            rewards.
                        </Typography>

                        <br />

                        <Typography style={{ fontWeight: 300 }}>
                            <span className={classes.bold}>Note:</span> The minimum amount that can
                            be staked is:{' '}
                            <span className={classes.bold}>
                                {MIN_STAKE_AMOUNT.toLocaleString()} AXN
                            </span>
                            .
                        </Typography>

                        <br />

                        <Typography variant="h4">BigPayDays (BPD)</Typography>
                        <br />

                        <Typography style={{ fontWeight: 300 }}>
                            Every year the amount accumulated in the BigPayDay pool will be
                            distributed among the stakes for{' '}
                            <span className={classes.bold}>350 days or more</span>. The rewards will
                            be added to your stake as interest and{' '}
                            <span className={classes.bold}>
                                can be withdrawn at the end of your stake
                            </span>
                            .
                        </Typography>

                        <br />
                        <Typography style={{ fontWeight: 300 }}>
                            There are <span className={classes.bold}>5 BigPayDays</span>, each being
                            a bit larger than the previous:
                        </Typography>
                        <br />

                        <Typography style={{ fontWeight: 300 }}>
                            First year: <span className={classes.bold}>5,002,049,020 AXN</span> will
                            be rewarded
                        </Typography>
                        <Typography style={{ fontWeight: 300 }}>
                            Second year: <span className={classes.bold}>7,503,073,530 AXN</span>{' '}
                            will be rewarded
                        </Typography>
                        <Typography style={{ fontWeight: 300 }}>
                            Third year: <span className={classes.bold}>10,004,098,040 AXN</span>{' '}
                            will be rewarded
                        </Typography>
                        <Typography style={{ fontWeight: 300 }}>
                            Fourth year: <span className={classes.bold}>12,505,122,550 AXN</span>{' '}
                            will be rewarded
                        </Typography>
                        <Typography style={{ fontWeight: 300 }}>
                            Fifth year: <span className={classes.bold}>15,006,147,060 AXN</span>{' '}
                            will be rewarded
                        </Typography>
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default PageTitle;
