import React, { useEffect, useState, createContext } from 'react';
import Web3 from 'web3';
import { useWallet } from 'use-wallet';
import { useDispatch } from 'react-redux';
import Contract from './contracts/contract';
import { hasVoted } from '../utils/intercom';
import { getKYCStatus } from 'utils/get-kyc';
import { useIntercom } from 'react-use-intercom';
import { setAddress, setKYC } from '../redux/actions/user';
import { loadContracts, loadLibrary } from './connect';
import { BSC_RPC, INFURA_URL, MATIC_CHAIN, POLYGON_RPC } from 'utils/variables';

export const ContractContext = createContext();

const ContractProvider = (props) => {
    const wallet = useWallet();
    const dispatch = useDispatch();
    const { update } = useIntercom();
    const library = React.useRef(null);
    const kycInterval = React.useRef(null);
    const refreshInterval = React.useRef(null);

    const [user, setUser] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [ethWeb3, setEthWeb3] = useState(null);
    const [bscWeb3, setBscWeb3] = useState(null);
    const [contracts, setContracts] = useState(null);
    const [contractInfo, setContractInfo] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [loadingContractInfo, setLoadingContractInfo] = useState(true);

    const _setup = (provider) => {
        if (!provider) provider = new Web3.providers.HttpProvider(POLYGON_RPC);

        // Setup ETH web3 instance
        const ethProvider = new Web3.providers.HttpProvider(INFURA_URL)
        const _ethWeb3 = new Web3(ethProvider);

        // Setup BSC web3 instance
        const bscProvider = new Web3.providers.HttpProvider(BSC_RPC)
        const _bscWeb3 = new Web3(bscProvider);

        const _web3 = new Web3(provider);
        const _contracts = loadContracts(_web3, _ethWeb3, _bscWeb3);
        const _libraries = loadLibrary(_contracts, _web3, _ethWeb3, _bscWeb3);

        // Set Data
        setWeb3(_web3);
        setEthWeb3(_ethWeb3);
        setBscWeb3(_bscWeb3);
        setContracts(_contracts);
        library.current = _libraries;
    }

    useEffect(() => {
        const _checkConnection = () => {
            setTimeout(() => {
                if (window.ethereum) {
                    if (window.ethereum.selectedAddress)
                        wallet.connect();
                }
            }, 1500);
        }

        _setup();
        getContractInfo(true);
        _checkConnection()

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function _checkKYC() {
            const kyc = await getKYCStatus(wallet.account);
            if (kyc) dispatch(setKYC(kyc));
            return kyc;
        }

        async function _loadUserData() {
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }

            if (kycInterval.current) {
                clearInterval(kycInterval.current);
                kycInterval.current = null;
            }

            // Re-setup contracts with selected account
            _setup(wallet.ethereum)
            Contract.setAccount(wallet.account);

            // Get KYC info from sekuritance
            const kyc = await _checkKYC();
            if (kyc && kyc.status === "NOT_VERIFIED") {
                kycInterval.current = setInterval(() => {
                    _checkKYC().then(kyc => {
                        if (kyc.status === "VERIFIED") {
                            clearInterval(kycInterval.current);
                            kycInterval.current = null;
                        }
                    });
                }, 30 * (60 * 1000)); // 30 minutes        
            }  

            getWalletDetails(true);
            refreshInterval.current = setInterval(async () => {
                Promise.all([
                    getContractInfo(), 
                    getWalletDetails()
                ]);
            }, 60 * 1000); // 60 seconds
        }

        if (wallet.account) {
            _loadUserData();
            hasVoted(wallet.account, update);
            dispatch(setAddress(wallet.account));
            update({ customAttributes: { eth_address: wallet.account } });
        } else if (user || refreshInterval || library.current) {
            setUser(null);
            library.current = null;
            refreshInterval.current = null;
            clearInterval(refreshInterval.current);
            update({ customAttributes: { eth_address: null, has_voted: null } });
            dispatch(setKYC({
                status: "",
                wallet: {
                    address: "",
                    chain: "",
                }
            }));
        }

        return () => { clearInterval(refreshInterval.current) };

        // eslint-disable-next-line
    }, [wallet.account]);

    async function getWalletDetails(setLoad = false) {
        setLoad && setUserLoading(true);
        try {
            let _user = { stakes: [] }, ventureDivs = [], allocations = [];

            if (wallet.chainId === MATIC_CHAIN) {
                [_user, ventureDivs] = await Promise.all([
                    library.current.Staker.get(),
                    library.current.Staking.getVentureAuctionDivs()
                ]);

                allocations = await library.current.Pledge.getUserAllocationPerPledge(_user);
            }

            setUser({..._user, ventureDivs, allocations });
        } catch (error) {
            console.log('Error loading user', error);
        }
        setLoad && setUserLoading(false);
    }

    async function getContractInfo(setLoad = false) {
        setLoad && setLoadingContractInfo(true);
        try {
            const contractInfo = await Contract.getAllContractDetails(library.current);
            setContractInfo(contractInfo);
        } catch (error) {
            console.log('Error loading contract info', error);
        } finally {
            setLoadingContractInfo(false);
        }
    }

    const values = {
        /** Wallet queries */
        getWalletDetails,
        userLoading,
        user,

        /** Contract Information queries */
        getContractInfo,
        loadingContractInfo,
        contractInfo,
        contracts,

        /** Class Libraries with web3 etc attached */
        library: library.current,

        /** Use Walet */
        ethWeb3,
        bscWeb3,
        wallet,
        web3,
    };

    return <ContractContext.Provider value={values}>{props.children}</ContractContext.Provider>;
};

export default ContractProvider;
