import React, { useState } from 'react';
import useStyles from './styles';
import { APR } from 'utils/variables';
import Button from 'components/Button';
import { Close } from '@material-ui/icons';
import SlideUp from 'components/Transitions/SlideUp';
import { Dialog, Stepper, Step, StepLabel, StepContent, Typography, Grid } from '@material-ui/core';

const AutostakeApprovalModal = ({ token, open, onClose, stakeInfo, onStake, onApprove, axnPrice }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const stakeToken = async () => {
        setLoading(true);

        try {
            await onStake();
            onClose();
        } finally {
            setLoading(false);
        }
    };

    const approveToken = async () => {
        setLoading(true);

        try {
            await onApprove();
            setActiveStep(1);
        } finally {
            setLoading(false);
        }
    };

    const close = () => {
        setActiveStep(0);
        onClose();
    };

    const getStepContent = (step) => {
        if (step === 0)
            return (
                <>
                    <Typography>
                        Since this is the first time using {token.symbol} in the accelerator, 
                        you will need to complete an approval transaction before the smart contract can use your tokens.
                        <br />
                        <br />
                        Your {token.symbol} will not leave your wallet during this step, and you
                        will be asked to complete an additional transaction afterwards.
                    </Typography>
                    <br />
                    <Button
                        fullWidth
                        loading={loading}
                        disabled={loading}
                        override="translucent"
                        onClick={approveToken}
                    >
                        Approve {token.symbol} Token
                    </Button>
                </>
            );
        else
            return (
                <>
                    <Typography>
                        Congratulations! You are one step closer. You will earn a minimum of {APR}% annual interest, 
                        and liquid wBTC rewards from the daily accelerator, which can be withdrawn at any time. 
                        All that's left to do is complete the "stake" transaction.
                    </Typography>
                    <br />
                    <Typography>
                        You are about to create a stake using{' '}
                        <b>
                            {stakeInfo.amount.toLocaleString()} {token.symbol} for{' '}
                            {stakeInfo.days.toLocaleString()} days
                        </b>
                        . Your {token.symbol} will be converted to approximately{' '}
                        <b>
                            {((stakeInfo.amount * token.price) / axnPrice).toFixed(token.decimalsToDisplay)}{' '}
                            AXN
                        </b>{' '}
                        for the stake. At the end of your stake, you will receive AXN.
                    </Typography>
                    <br />
                    <Typography>
                        If you agree, you can click below to begin your stake.
                    </Typography>
                    <br />
                    <Button
                        fullWidth
                        loading={loading}
                        disabled={loading}
                        onClick={stakeToken}
                        override="translucent"
                    >
                        Create Stake
                    </Button>
                </>
            );
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={close}
            disableScrollLock
            disableBackdropClick
            classes={{ paper: classes.paper }}
            TransitionComponent={SlideUp}
        >
            <Grid container justify="space-between" className={classes.closeIconContainer}>
                <Grid item>
                    <Typography variant="h3" align="right">
                        Stake Your Tokens
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography align="right">
                        <Close onClick={close} fontSize="small" className={classes.icon} />
                    </Typography>
                </Grid>
            </Grid>

            <Stepper activeStep={activeStep} orientation="vertical" className={classes.paper}>
                {['Approval Transaction', 'Stake Transaction'].map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>{getStepContent(index)}</StepContent>
                    </Step>
                ))}
            </Stepper>

            <Typography variant="caption" style={{ padding: '2% 3%' }}>
                Note: Depending on the gas price you use, these transactions may take a few minutes
                each to complete. You are not required to stay on this page, but to keep it simple, we recommend you do!
            </Typography>
        </Dialog>
    );
};

export default AutostakeApprovalModal;
