import { SET_ADDRESS, SET_KYC } from '../reducers/user';

export const setAddress = (address) => {
    return {
        type: SET_ADDRESS,
        payload: address,
    };
};

export const setKYC = (kyc) => {
    return {
        type: SET_KYC,
        payload: kyc,
    };
};
