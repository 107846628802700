import { SET_ALERTBAR, CLOSE_ALERTBAR } from 'redux/reducers/alertbar';

export function setAlertbar(payload) {
    return {
        payload,
        type: SET_ALERTBAR,
    };
}

export function closeAlertbar() {
    return {
        type: CLOSE_ALERTBAR,
    };
}
