import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    icon: {
        color: 'white',
        backgroundColor: theme.brand.BPDBlueShade2,
        margin: theme.spacing(1),
    },
    link: {
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    newsImg: {
        padding: theme.spacing(1),
        width: '90%',
        borderRadius: theme.spacing(1),

        [theme.breakpoints.only('md')]: {
            width: '75%'
        }
    },
    newsTitle: {
        padding: theme.spacing(1),
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    newsItem: {
        cursor: 'pointer',
        padding: theme.spacing(0.5),
        borderBottom: `1px solid ${theme.brand.border}`,
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'translateY(-0.15em)',
            filter: 'brightness(0.70)',
        },
        '&:last-child': {
            borderBottom: `none`,
        },
    },
    newsDate: {
        fontSize: '0.8rem',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    error: {
        fontSize: '2rem',
        color: theme.brand.error,
    },
    hyperlink: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
