import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    connectImage: {
        width: '50%',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

export default useStyles;
