import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.2rem',
        paddingBottom: theme.spacing(2)
    },
    emptyText: {
        color: theme.brand.GrayChateau,
        fontSize: '1rem',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    collection: {
        maxWidth: 1500,
        borderRadius: 10,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: `2px solid ${theme.brand.border}`,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            filter: 'brightness(0.8)',
        }
    },
    event: {
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
    },
    expendedParticles: {
        width: '100%'
    },
    red: {
        color: theme.brand.error
    },
    green: {
        color: theme.brand.JavaGreen
    },
    resultText: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    }
}));

export default useStyles;
