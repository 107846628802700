import React, { useEffect, useState, useContext } from 'react';
import useStyles from './styles';
import Page from 'components/Page';
import PledgeTable from './PledgeTable';
import Meta from 'components/util/Meta';
import PageTitle from './PageTitle/PageTitle';
import { ContractContext } from 'service/provider';
import Background from 'components/Background/Background';

const Pledge = () => {
    const classes = useStyles();
    const [pledgeItems, setPledgeItems] = useState([]);
    const [userPledges, setUserPledges] = useState([]);
    const [userUSDCInfo, setUserUSDCInfo] = useState({ balance: 0, allowance: 0 });
    const { user, library, contractInfo, loadingContractInfo } = useContext(ContractContext);

    useEffect(() => {
        if (!loadingContractInfo) setPledgeItems(contractInfo.pledgeEngine?.pledges ?? []);
    }, [loadingContractInfo, contractInfo]);

    const _getUSDCInfo = async () => {
        try {
            const info = await library.Pledge.getUserUSDCInfo();
            setUserUSDCInfo(info);
        } catch (error) {
            console.error(error);
        }
    };

    const _getUserPledges = async () => {
        try {
            const info = await library.Pledge.getUserPledges();
            setUserPledges(info);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (library && user) {
            _getUSDCInfo();
            _getUserPledges();
        }

        // eslint-disable-next-line
    }, [library, user]);

    return (
        <React.Fragment>
            <Background />
            <Meta title="Axion | Pledge Engine" />
            <Page classes={{ inner: classes.inner }} header={<PageTitle />}>
                <div className={classes.section}>
                    <PledgeTable
                        data={pledgeItems}
                        userUSDC={userUSDCInfo}
                        userPledges={userPledges}
                        refreshBalance={_getUSDCInfo}
                        refreshPledges={_getUserPledges}
                    />
                </div>
            </Page>
        </React.Fragment>
    );
};

export default Pledge;
