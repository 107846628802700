import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        borderRadius: 5,
        cursor: 'pointer',
    },
    light: {
        backgroundColor: theme.brand.Tropaz,
    },
    hyperlink: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
