import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { DEX } from 'utils/variables';
import SharedDays from '../SharedDays';
import SharedSelect from '../SharedSelect';
import SharedSlider from '../SharedSlider';
import SharedAmount from '../SharedAmount';
import SharedActionButton from '../SharedActionButton';
import { Grid, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import SharedSelectIcons from '../SharedSelectIcons/SharedSelectIcons';
import BPDChecker from 'components/BPDChecker/BPDChecker';
import SharedDivCheckbox from '../SharedDivCheckbox/SharedDivCheckbox';

const AdvancedStakeConfig = (props) => {
    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <div>
            <Grid container className={classes.root}>
                <Grid item xs={12} md={5} className={classes.inputs}>
                    {/* <SharedSelectIcons
                        token={props.token}
                        theme={props.theme}
                        tokens={props.tokens}
                        handleChangeToken={props.handleChangeToken}
                    /> */}
                    <SharedSelect
                        token={props.token}
                        theme={props.theme}
                        tokens={props.tokens}
                        setOpen={props.setOpen}
                        handleChangeToken={props.handleChangeToken}
                        open={props.open && props.interfaceType === 'advanced'}
                    />

                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="h6" className={classes.balance}>
                                Balance: {`${props.balance.numberWithCommas(props.balance === 0 ? 0 : props.token.decimalsToDisplay)} ${props.token.symbol}`}
                            </Typography>
                        </Grid>
                        {props.token?.divToken && props.divsInToken > 0 && (
                            <Grid item>
                                <SharedDivCheckbox 
                                    token={props.token} 
                                    checked={props.usingDivs}
                                    divsInToken={props.divsInToken} 
                                    handleAmount={props.handleAmount} 
                                    setChecked={props.handleUsingDivs}
                                />
                            </Grid>
                        )}
                    </Grid>
                
                    <SharedAmount
                        amount={props.amount}
                        balance={props.balance}
                        disabled={props.usingDivs}
                        tokenPrice={props.tokenPrice}
                        handleAmount={props.handleAmount}
                        handleMaxAmount={props.handleMaxAmount}
                    />

                    <div className={classes.textboxSpacer} />

                    <SharedDays
                        days={props.days}
                        handleDays={props.handleDays}
                        handleMaxDays={props.handleMaxDays}
                        minStakeDays={props.minStakeDays}
                    />
                </Grid>

                <Grid item container xs={12} md={7}>
                    <Grid item xs={12} className={clsx(classes.stat, classes.borderBottom)}>
                        <BPDChecker days={props.days.value} />
                    </Grid>
                    {props.stats.map((s, index) => (
                        <Grid
                            item
                            xs={6}
                            key={`stats-${index}`}
                            className={clsx(classes.stat, {
                                [classes.borderRight]: s.right,
                                [classes.borderBottom]: s.bottom,
                            })}
                        >
                            <Tooltip title={s.tooltip ? s.tooltip : ''} arrow={true}>
                                <Typography
                                    variant="h3"
                                    className={{
                                        [classes.bonusText]: props.bonus.active && s.bonus,
                                    }}
                                >
                                    {s.data}
                                    {s.suffix ? s.suffix : ''}
                                </Typography>
                            </Tooltip>
                            <Typography variant="h6">{s.statTitle}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <SharedSlider
                minStakeDays={props.minStakeDays}
                days={props.days}
                changeSlider={props.changeSlider}
            />

            <div className={classes.stakeFooter}>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={8}>
                        <Typography className={classes.summarySub}>
                            {DEX}{isSmall ? '' : ' Price'}{' '}
                            {(+props.amount.value).numberWithCommas(props.token.decimalsToDisplay)}{' '}
                            {props.token.symbol} = ~
                            {props.bonus.axnBeforeBonus?.numberWithCommas(0)} AXN
                        </Typography>
                        <Typography className={classes.summarySub}>
                            <span
                                className={clsx({
                                    [classes.bonusText]: props.bonus.active === true,
                                })}
                            >
                                Accelerator{isSmall ? '' : ' Price'}:{' '}
                                {(+props.amount.value).numberWithCommas(
                                    props.token.decimalsToDisplay
                                )}{' '}
                                {props.token.symbol} = ~{props.bonus.principal?.numberWithCommas(0)}{' '}
                                AXN
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SharedActionButton
                            variant="stake"
                            user={props.user}
                            loading={props.staking}
                            onClick={props.stake}
                            disabled={props.stakeDisabled()}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default AdvancedStakeConfig;
