import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'justify',
        }
    },
    title: {
        fontSize: 42,
        fontWeight: '500',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },

    /** Stats */
    barStats: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            // flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 12,
        },
    },
    graph: {
        height: 200,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    stats: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    },
    stat: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '34%',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },

    /** Margins */
    mr: {
        marginRight: theme.spacing(2),
    },
}));

export default useStyles;
