import { Hidden } from '@material-ui/core';
import { addPolygonToMetamask, requestChainSwitchETH } from 'utils/add-to-metamask';

const errorTranslate = (message, resetWallet) => {
    const _addPolygon = async () => {
        try {
            await addPolygonToMetamask()
            setTimeout(() => resetWallet(), 1000)
        } catch (err) {}
    }

    const _switchToEth = async () => {
        try {
            await requestChainSwitchETH()
            setTimeout(() => resetWallet(), 1000)
        } catch (err) {}
    }

    switch (message) {
        case 'No Ethereum provider was found on window.ethereum.':
            return (
                <span>
                    <Hidden smDown>
                        Please install the Metamask extension from{' '}
                        <a
                            className="translate-link"
                            href="https://metamask.io/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            metamask.io
                        </a>
                    </Hidden>
                    <Hidden mdUp>
                        Using a mobile you must open this website inside the{' '}
                        <a
                            className="translate-link"
                            href="https://metamask.io/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Metamask
                        </a>{' '}
                        app.
                    </Hidden>
                </span>
            );
        case 'Unsupported chain: Unknown. Required chain: Unknown (Chain ID: 137).':
            return (
                <span>
                    Wrong Network! Please switch to Polygon Network.
                    <span style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 10, fontStyle: 'italic' }} onClick={_addPolygon}>
                        Switch Network
                    </span>
                </span>
            )
        case 'Unsupported chain: Unknown. Required chain: Mainnet (Chain ID: 1).':
            return (
                <span>
                    Wrong Network! Please switch to Ethereum Network.
                    <span style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 10, fontStyle: 'italic' }} onClick={_switchToEth}>
                        Switch Network
                    </span>
                </span>
            )
        case "Invariant failed":
            return "Polygon RPC failed to respond. Please set your RPC URL to https://polygon-rpc.com/ in your wallet."
        default:
            return <span>{message}</span>;
    }
};

export default errorTranslate;
