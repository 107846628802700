import React, { useState } from 'react';

// Components
import Page from 'components/Page';
import PageTitle from './PageTitle';
import Button from 'components/Button';
import Meta from '../../components/util/Meta';
import DisclaimerModal from 'components/Modals/DisclaimerModal';

// Modules
import useStyles from './styles';
import { DEX, SWAP_URL } from 'utils/variables';
import { DISCLAIMERS } from 'utils/disclaimers';
import Background from 'components/Background/Background';

const Buy = () => {
    const classes = useStyles();
    const [sawDisclaimer, setSawDisclaimer] = useState(localStorage.getItem(DISCLAIMERS.DEX.key));

    return (
        <React.Fragment>
            <Background />
            <Meta title={`Axion | Buy on ${DEX}`} />

            <DisclaimerModal
                open={!sawDisclaimer}
                onClose={() => setSawDisclaimer(true)}
                data={DISCLAIMERS.DEX}
            />
            <Page classes={{ inner: classes.inner }} header={<PageTitle />}>
                <iframe className={classes.frame} src={SWAP_URL} title="Buy Axion" />
                <Button classes={{ root: classes.button }} href={SWAP_URL} target="_blank">
                    Click here to buy from {DEX} directly
                </Button>
            </Page>
        </React.Fragment>
    );
};

export default Buy;
