import { BiRocket } from 'react-icons/bi';
import { CgToolbox } from 'react-icons/cg';
import { RiToolsFill } from 'react-icons/ri';
import { IoMdWallet } from 'react-icons/io';
import { MdDashboard, MdFilterFrames } from 'react-icons/md';
import AnimatedAtom from 'components/AnimatedAtom/AnimatedAtom';
import { AiOutlineBank, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { GiArchBridge, GiAtomicSlashes, GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import HowToVoteIcon from '@material-ui/icons/HowToVote';

const menuItems = [
    { name: 'Dashboard', pathname: '/', Icon: MdDashboard },
    { name: 'Accelerator', pathname: '/accelerator', Icon: AnimatedAtom },
    { name: 'Collider', pathname: '/collider', Icon: GiAtomicSlashes },
    { name: 'Stake', pathname: '/stake', Icon: AiOutlineBank },
    { name: 'NFTs', Icon: MdFilterFrames, pathname: '/nft' },
    { name: 'Voting', pathname: '/voting', Icon: HowToVoteIcon },
    { 
        name: 'Launch', 
        Icon: BiRocket,
        subItems: [    
            { name: 'Launches', pathname: '/launch', Icon: AiOutlineFundProjectionScreen },       
            { name: 'Pledge', pathname: '/pledge', Icon: GiPayMoney },
            { name: 'Vesting', pathname: '/vesting', Icon: GiReceiveMoney },
        ] 
    },
    { 
        name: 'Utilities', 
        Icon: CgToolbox,
        subItems: [           
            // { name: 'Bridge', pathname: '/bridge', Icon: GiArchBridge },
            { name: 'Tools', pathname: '/tools', Icon: RiToolsFill },
            { name: 'Add AXN Token', Icon: IoMdWallet, isMetamask: true, },
        ] 
    }
];

export default menuItems;
