import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tokenIcons: {
        display: 'flex',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: 'space-evenly',
    },
    img: {
        width: '40px',
        cursor: 'pointer',
        opacity: 0.3,
        transition: 'opacity 0.5s ease',
        filter: theme.mode === 'supernova' ? 'sepia(20%) saturate(3000%) brightness(95%)' : undefined,

        [theme.breakpoints.down('lg')]: {
            width: '100%',
            maxWidth: '30px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '40px',
        },
    },
    tokenIcon: {
        margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    },
    active: {
        opacity: 1,
    },
}));

export default useStyles;
