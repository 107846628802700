// React
import React, { useContext, useEffect, useRef, useState } from 'react';

// Modules
import moment from 'moment';
import { ContractContext } from 'service/provider';
import { DISCLAIMERS } from 'utils/disclaimers';

// Components
import Page from 'components/Page';
import Card from 'components/Card';
import PageTitle from './PageTitle';
import PortfolioWidget from './Portfolio';
import VentureTable from './VentureTable';
import Skeleton from 'components/Skeleton';
import Meta from '../../components/util/Meta';
import CardBody from 'components/Card/CardBody';
import CardTitle from 'components/Card/CardTitle';
import StakeTables from './StakeTables/StakeTables';
import { Grid, Typography } from '@material-ui/core';
import SharesBarChart from 'components/SharesBarChart';
import Background from 'components/Background/Background';
import { BPD_AMOUNTS, BPD_TIMES } from 'utils/variables';
import DisclaimerModal from 'components/Modals/DisclaimerModal';

const Staking = (props) => {
    const stakeTableRef = useRef(null);
    const { user, userLoading } = useContext(ContractContext);
    const [sawDisclaimer, setSawDisclaimer] = useState(localStorage.getItem(DISCLAIMERS.STAKING.key));

    // For handling scrolling to the stake table
    // from the Accelerator's StakeConfirmation component.
    useEffect(() => {
        if (props.location.state?.scroll) {
            if (stakeTableRef.current) {
                setTimeout(() => {
                    stakeTableRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest"
                    });
                }, 1000)
            }
        }
    }, [props.location.state])

    return (
        <React.Fragment>
            <Background/>
            <Meta title="Axion | Staking Platform" />
            <DisclaimerModal open={!sawDisclaimer} onClose={() => setSawDisclaimer(true)} data={DISCLAIMERS.STAKING}/>

            <Page header={<PageTitle />}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <PortfolioWidget />
                    </Grid>
                    {user && (
                        <Grid item xs={12}>
                            <VentureTable />
                        </Grid>
                    )}
                    {userLoading && (
                        <Grid item xs={12}>
                            <Skeleton type="cardTable" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Card>
                            <CardTitle>
                                <Typography variant="h3">BigPayDay Yearly Amounts</Typography>
                            </CardTitle>
                            <CardBody>
                                <SharesBarChart values={BPD_AMOUNTS.map((value, index) => ({
                                    axn: value,
                                    date: moment(BPD_TIMES[index] * 1000).format('L'),
                                    paid: Date.now() / 1000 > BPD_TIMES[index]
                                }))}/>
                            </CardBody>
                        </Card>
                    </Grid>

                    {user && user?.stakes?.length > 0 && (
                        <Grid item xs={12} md={12}>
                            <div ref={stakeTableRef}>
                                <StakeTables />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Page>
        </React.Fragment>
    );
};

export default Staking;
