import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme._shadows.bar,
        backgroundColor: theme.brand.CardBG,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',

    },
    media: {
        height: 160,
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.brand.CardBG}`
    },
    actionArea: {
        flexGrow: 1,
    },
    content: {
        textAlign: 'left',
    },
}));

export default useStyles;
