export const SET_SETTINGS = 'SET_SETTINGS';
export const INIT = {};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_SETTINGS:
            return {...state, ...payload};
        default:
            return state;
    }
}

export default reducer;
