import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    confirmIcon: {
        fontSize: 20,
        color: theme.brand.teal,
    },
    root: {
        zIndex: '5 !important',
        [theme.breakpoints.down('md')]: {
            marginTop: '70px',
        },
    },
    paper: {
        opacity: 0.9,
        backgroundColor: `${theme.brand.CardBG} !important`, // !important? For some reason, sometimes comes up grey: http://prntscr.com/1sktwga
    },
    dialogTitle: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    icon: {
        color: theme.brand.textPrimary,
        marginRight: theme.spacing(1),
    },
    actions: {
        padding: '2% 5%',
        display: 'flex',
        justifyContent: 'space-between !important'
    },
    explainItem: {
        marginTop: 20,
    },
}));

export default useStyles;
