import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    frame: {
        width: '100%',
        height: 800,
        border: 0,
    }
}));

export default useStyles;
