import { MATIC_CHAIN, BSC_CHAIN, ETH_CHAIN } from 'utils/variables';

const _open = (type, chain) => {
    let url = 'polygonscan.com';
    if (chain === ETH_CHAIN) url = 'etherscan.io';
    else if (chain === BSC_CHAIN) url = 'bscscan.com';

    window.open(`https://${url}/${type}`, '_blank')
};

export const openTx = (txId, chain = MATIC_CHAIN) => _open(`tx/${txId}`, chain);
export const openToken = (address, chain = MATIC_CHAIN) => _open(`token/${address}`, chain);
export const openAddress = (address, chain = MATIC_CHAIN) => _open(`address/${address}`, chain);
export const openBlock = (blockNumber, chain = MATIC_CHAIN) => _open(`block/${blockNumber}`, chain);
