import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { estimateDivs } from 'utils/calculator';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { ContractContext } from 'service/provider';
import { PHOENIX_PROMOTION, PHOENIX_MINT_END_DATE } from "../variables";
import { DEX } from 'utils/variables';

const AcceleratorBonus = ({ currentPrice, stakeInfo, calculateBonus }) => {
    const classes = useStyles();
    const { contractInfo } = useContext(ContractContext);

    const [future, setFuture] = useState(0);
    const [BPD, setBPD] = useState({ amount: 0, bpds: 0 });
    const [bonus, setBonus] = useState({ bonusAXN: 0, principal: 0, axnBeforeBonus: 0 });

    useEffect(() => {
        const bonus = calculateBonus({
            axnAmount: +stakeInfo.amount || 0,
            days: +stakeInfo.days || 1,
        });

        setBonus(bonus);
        setFuture(bonus.principal + estimateDivs.axn(bonus.principal, +stakeInfo.days));

        const bpdInfo = estimateDivs.bpd(
            stakeInfo.startDate.getTime() / 1000,
            stakeInfo.endDate.getTime() / 1000,
            bonus.totalShares,
            contractInfo?.axion?.bpdShares
        );

        setBPD(bpdInfo);

    // eslint-disable-next-line
    }, [stakeInfo, contractInfo]);

    const phoenixPromotion = useMemo(() => {
        let promotion = PHOENIX_PROMOTION[0];
        // if (Date.now() / 1000 >= PHOENIX_MINT_END_DATE) return promotion;
        for (const p of PHOENIX_PROMOTION)
            if (
                bonus.axnBeforeBonus * currentPrice >= p.minUSD &&
                bonus.axnBeforeBonus * currentPrice < p.maxUSD
            ) {
                // TODO: Do this properly/more reliably
                // Go to lower promotion if max mint is reached
                if (contractInfo?.accelerator?.phoenixAmounts && contractInfo?.accelerator?.phoenixAmounts[p.name.toLowerCase()] < p.maxMint)
                    promotion = p;
                else {
                    if (p.name === "Standard") promotion = PHOENIX_PROMOTION[0];
                    else if (p.name === "Silver" && contractInfo?.accelerator?.phoenixAmounts.standard < PHOENIX_PROMOTION.find(p => p.name === "Standard").maxMint)
                        promotion = PHOENIX_PROMOTION.find(p => p.name === "Standard");
                    else if (p.name === "Gold" && contractInfo?.accelerator?.phoenixAmounts.silver < PHOENIX_PROMOTION.find(p => p.name === "Silver").maxMint)
                        promotion = PHOENIX_PROMOTION.find(p => p.name === "Silver");
                }
            }

        return promotion;
    }, [bonus, currentPrice, contractInfo]);

    const items = [
        { 
            title: "Principal:",
            subtitle: "",
            totalAXN: bonus.axnBeforeBonus,
            totalUSD: bonus.axnBeforeBonus * currentPrice,

            colorActive: true,
            color: classes.white,
        },
        { 
            title: <>Bonus <span className={classes.bonus}>({bonus.percent?.toFixed(1) || 0}%)</span>:</>,
            subtitle: "",
            totalAXN: bonus.bonusAXN,
            totalUSD: bonus.bonusAXN * currentPrice,

            dividerAfter: true,
            colorActive: true,
            color: classes.white,
        },
        { 

            prefix: "~",
            title: `Total Principal:`,
            subtitle: "Total AXN to be staked",
            totalAXN: bonus.principal,
            totalUSD: bonus.principal * currentPrice,
            tooltip: `The amounts you see here are estimates. The actual amounts depend on the final values the contract gets from ${DEX}, which can vary by a few percent at times.`,

            dividerAfter: true,
            color: classes.green,
            colorActive: bonus.bonusAXN > 0,
        },
        { 
            title: `BPD Bonus${BPD.bpds > 1 ? 'es' : ''}:`,
            subtitle: "Using today's ratio",
            totalAXN: BPD.amount,
            totalUSD: BPD.amount * currentPrice,
            tooltip: "A total of 50 billion AXN is to be distributed over 5 years based on your Staker Share Percentage (SSP). Only stakes that are 350 days or longer are eligible, and the amount can only be withdrawn at the end of your stake.",
            
            colorActive: BPD.bpds > 0,
            color: classes.green,
        },
        {             
            title: `Maturity Value:`,
            subtitle: "Using today's price",
            totalAXN: future + BPD.amount,
            totalUSD: (future + BPD.amount) * currentPrice,

            colorActive: true,
            color: classes.green,
        },
        { 
            title: `Potential Bonus:`,
            subtitle: "If Collider NFT succeeds",
            totalAXN: Math.min(50000000, bonus.axnBeforeBonus * 5),
            totalUSD: Math.min(50000000, bonus.axnBeforeBonus * 5) * currentPrice,
            tooltip: "Potential is based on a 5x multiplier. You will receive an NFT with an amount of particles based on your principal, which can be put into the Collider for a chance to mint a bonus stake.",
        
            colorActive: true,
            dividerAfter: Boolean(phoenixPromotion),
            color: classes.colliderOrange,
        },
    ]

    const getColor = useCallback(discount => {
        if (discount === 2) return classes.colliderOrange;
        if (discount === 3) return classes.silver;
        if (discount === 4) return classes.gold;
        return classes.white;
    }, [classes]);

    if (phoenixPromotion) {
        items.push({
            title: `Phoenix NFT:`,
            subtitle: "Free bonus utility NFT",
            totalAXN: phoenixPromotion.discount,
            overrideSuffix: "% BONUS",
            totalUSD: phoenixPromotion.name,
            tooltip: (
                <div>
                    <Typography variant="body2">The Phoenix NFT is a special utility NFT will grant you an accelerator discount. In addition, for 6 months after the promotion is over, the Diamond Stake NFT bonus will stack with this one.</Typography>
                    <br />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="caption">{PHOENIX_PROMOTION[1].minUSD.shorten()} - {PHOENIX_PROMOTION[1].maxUSD.shorten()} USD = {PHOENIX_PROMOTION[1].name} NFT ({PHOENIX_PROMOTION[1].discount}% discount)</Typography>
                        <Typography variant="caption">{PHOENIX_PROMOTION[2].minUSD.shorten()} - {PHOENIX_PROMOTION[2].maxUSD.shorten()} USD = {PHOENIX_PROMOTION[2].name} NFT ({PHOENIX_PROMOTION[2].discount}% discount)</Typography>
                        <Typography variant="caption">{PHOENIX_PROMOTION[3].minUSD.shorten()}+ USD = {PHOENIX_PROMOTION[3].name} NFT ({PHOENIX_PROMOTION[3].discount}% discount)</Typography>
                    </div>
                    <br />
                    <Typography variant="body2">The USD value you see above is based on the "Principal" AXN amount.</Typography>
                </div>
            ),
            colorActive: true,
            color: getColor(phoenixPromotion.discount),
        });
    }

    return (
        <div className={classes.body}>
            <Grid container direction="column" spacing={1}>
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <Grid item container justify="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <Typography className={classes.title}>{item.title}</Typography>
                                <Typography className={classes.term}>
                                    {item.subtitle}
                                    {item.tooltip && (
                                        <React.Fragment>
                                            {' '}
                                            <Tooltip enterDelay={0} arrow title={item.tooltip}>
                                                <HelpOutline className={clsx(classes.term, classes.pointer)} />
                                            </Tooltip>
                                        </React.Fragment>
                                    )}
                                
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={clsx(classes.value, { [item.color]: item.colorActive })}>
                                    {item.prefix && item.prefix}{(item.totalUSD || 0).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={clsx(classes.amount, { [item.color]: item.colorActive })}>
                                    {item.prefix && item.prefix}{(item.totalAXN || 0).numberWithCommas(0)}{item.overrideSuffix ? item.overrideSuffix : " AXN"}
                                </Typography>
                            </Grid>
                        </Grid>
                        {item.dividerAfter && <Grid item className={classes.divider} />}
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    );
};

export default AcceleratorBonus;
