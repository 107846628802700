import React from 'react';
import useStyles from './styles';
import { Typography, Grid } from '@material-ui/core';
import { OpenInNewOutlined } from '@material-ui/icons';
import { openAddress } from 'utils/open-etherscan';

const VoterInformation = ({ results, address, shares }) => {
    const classes = useStyles();
    const information = [
        {
            key: 'Wallet',
            value: `${address.substring(0, 6)}...${address.slice(-4)}`,
            extra: (
                <OpenInNewOutlined
                    className={classes.open}
                    onClick={() => openAddress(address)}
                />
            ),
        },
        {
            key: 'Vote',
            value: results?.voters[address]?.vote,
        },
    ];

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.infoGrid}>
            {information.map((i) => (
                <>
                    <Grid item xs={2}>
                        <Typography variant="h4">{i.key}:</Typography>
                    </Grid>
                    <Grid item container xs={10} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Typography align="right" className={classes.value}>
                                {i.value.toLocaleString()}
                            </Typography>
                        </Grid>
                        {i?.extra && (
                            <Grid item>
                                <Typography>&nbsp;{i.extra}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

export default VoterInformation;
