import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    colliderContainer: {
        display: 'flex',
        flexFlow: 'column',
    }
}));

export default useStyles;
