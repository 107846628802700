import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    infoGrid: {
        marginBottom: 5,
    },
    value: {
        fontSize: '0.9rem',
    },
    open: {
        cursor: 'pointer',
        fontSize: '0.9em',
    },
}));

export default useStyles;
