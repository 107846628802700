import React from 'react';
import useStyles from './styles';
import Button from 'components/Button';
import { Typography, useTheme } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';

const ButtonComponent = ({ onClick, theme }) => {
    const classes = useStyles();

    return (
        <Button
            color="primary"
            variant="contained"
            onClick={onClick}
            override={theme.mode === 'dark' ? "blue" : 'supernova'}
            classes={{ root: classes.buttonRoot }}
        >
            I Understand
        </Button>
    );
};
const CookiConsent = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <CookieConsent
            ButtonComponent={props => <ButtonComponent theme={theme} {...props}/>}
            containerClasses={classes.cookieRoot}
        >
            <Typography className={classes.cookieText}>
                This website uses cookies to enhance the user experience!
            </Typography>
        </CookieConsent>
    );
};

export default CookiConsent;
