import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: "pointer",
        textDecoration: "underline",
    }
}));

export default useStyles;
