import { useEffect, useContext, useState } from 'react';
import { ContractContext } from 'service/provider';
import { useSelector, useDispatch } from 'react-redux';
import { completedTx, clearTxs, deleteTx, failedTx } from 'redux/actions/transactions';

/**
 * Hook that helps with tracking the status of a user's transactions.
 * Will track it from pending to mined.
 *
 * @param {boolean} checkPending Initiate the pending transaction checker
 * @returns All of the user's transactions, a method to clear all transactions
 * and a method to remove a single transaction by ID
 */
const useTransactions = (checkPending = false) => {
    const dispatch = useDispatch();
    const [checker, setChecker] = useState(null);
    const { transactions, user } = useSelector(store => store);
    const { loadingContractInfo, web3 } = useContext(ContractContext);

    useEffect(() => {
        stopTransactionChecker();
        if (
            checkPending &&
            !loadingContractInfo &&
            transactions.filter(_filterByUserPending).length > 0
        ) {
            checkPendingTx();
            startTransactionChecker();
        }

        return stopTransactionChecker;

        // eslint-disable-next-line
    }, [loadingContractInfo, user]);

    const startTransactionChecker = () => {
        setChecker(
            setInterval(() => {
                checkPendingTx();
            }, 1000 * 5)
        );
    };

    const stopTransactionChecker = () => {
        if (checker) {
            clearInterval(checker);
            setChecker(null);
        }
    };

    const checkPendingTx = async () => {
        const pending = transactions.filter(_filterByUserPending);
        if (pending.length > 0) {
            try {
                const promises = pending.map((pTx) => web3.eth.getTransactionReceipt(pTx.id));
                for (const tx of await Promise.all(promises)) {
                    if (tx) {
                        if (!tx.status) dispatch(failedTx(tx.transactionHash));
                        else if (tx.blockHash) dispatch(completedTx(tx.transactionHash));
                    }
                }
            } catch (err) {
                console.error('Transaction Checker:', err);
            }
        } else stopTransactionChecker();
    };

    const _filterByUser = (tx) => tx.address === user.address;
    const _filterByUserPending = (tx) => tx.address === user.address && tx.pending;

    return {
        transactions: transactions.filter(_filterByUser).reverse(),
        pendingTransactions: transactions.filter(_filterByUserPending).reverse(),

        clearTransactions: () => {
            dispatch(clearTxs());
        },
        removeTransaction: (id) => {
            dispatch(deleteTx(id));
        },
    };
};

export default useTransactions;
