import { NETWORK } from 'utils/variables';

const TYPES = {
    stake: 'Create Stake',
    rename: 'Rename Stake',
    autostake: 'Create Non-Axion Stake',
    buy_stake: 'Axion Accelerator',
    buy_stake_approval: 'Axion Accelerator Approval',
    auction_stake: 'Stake Winnings',
    vca_bid: 'Venture Bid',
    regular_bid: 'Regular Bid',
    restake: 'ReStake',
    withdraw: 'Withdraw Stake',
    max_share: 'Max Share Upgrade',
};

export const trackAcceleratorEvent = (event_category, days, symbol, depositAmountUSD) => {
    // Google Analytics Tracking
    if (window.gtag && event_category) window.gtag('event', 'Accelerator', { event_category });

    // Semetrix Tracking
    if (days && symbol && depositAmountUSD) {
        let ec = symbol;
        if (ec === 'WETH') ec = 'Ethereum';
        if (+days === 5555) ec += 'MAX';

        window.dotq = window.dotq || [];
        window.dotq.push({
            projectId: '10000',
            properties: {
                pixelId: '10153801',
                userEmail: '<email_address>',
                qstrings: {
                    ec,
                    et: 'custom',
                    gv: depositAmountUSD,
                },
            },
        });
    }
};

export const trackStakeEvent = ({ amount, days = 5555, type }) => {
    if (NETWORK === 'mainnet') {
        if (window.gtag) {
            window.gtag('event', 'Staking', {
                action: TYPES[type],
                stake_days: days,
                stake_amount: amount,
            });
        }

        if (type !== 'withdraw' || type !== 'convertToNFT') {
            let ec = days === 5555 ? 'Staking_Confetti' : 'Staking';
            window.dotq = window.dotq || [];
            window.dotq.push({
                projectId: '10000',
                properties: {
                    pixelId: '10153801',
                    userEmail: '<email_address>',
                    qstrings: { et: 'custom', ec },
                },
            });

            if (window.twttr) {
                window.twttr.conversion.trackPid('o67d7', {
                    tw_sale_amount: 0,
                    tw_order_quantity: 0,
                });
            }
        }

        window.coinzilla_performance = window.coinzilla_performance || [];
        window.coinzilla_performance.push({ event: 'register' }); // Using "register" for stake
    }
};

export const trackError = ({ type, description, fatal = false }) => {
    if (NETWORK === 'mainnet' && window.gtag) {
        window.gtag('event', 'exception', {
            description: `${TYPES[type]} Error: ${description}`,
            fatal,
        });
    }
};
