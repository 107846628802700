import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        margin: '10px 100px',
    },
    cookieText: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    root: {
        borderTop: '1px solid black',
    },

    cookieRoot: {
        justifyContent: "center !important",
        alignItems: "center !important",
        minHeight: "59px",
        zIndex: "3 !important",
        right: "0 !important",
        left: "unset !important" ,
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        backgroundColor: `${theme.brand.NavigationBackground} !important`,

        [theme.breakpoints.up('lg')]: {
            width: 'calc(100vw - 200px) !important',
        }
    }
}));

export default useStyles;
