import React from 'react';
import useStyles from './styles';
import { APR } from 'utils/variables';
import { Typography } from '@material-ui/core';

const PageTitle = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    NFT Collection
                </Typography>
                <Typography className={classes.body} variant="h6">
                    The Axion NFT ecosystem is here. Now the world's best staking token is bringing a whole new suite of 
                    Utility, Visual, and Community NFTs designed to augment your staking experience. 
                    Axion's ecosystem features {APR}% fixed-rate APR, daily bitcoin rewards, exclusive token launchpad, NFT stakes, and more! 
                    This is the future of finance. And it features some seriously rad NFTs.
                    Listed below are all the Axion NFTs that you own in the wallet address currently connected.
                </Typography>
            </div>
        </div>
    );
};

export default PageTitle;
