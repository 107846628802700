import React from 'react';
// Modules
import clsx from 'classnames';
import { Button, CircularProgress, withStyles, withTheme } from '@material-ui/core';
import styles from './styles';

class LoadingButton extends React.Component {
    render() {
        const { loading, children, classes, override, ...rest } = this.props;
        return (
            <Button
                {...rest}
                className={clsx(classes.root, {
                    [classes[override]]: !!override,
                })}
            >
                {loading ? (
                    <CircularProgress
                        style={{ height: 20, width: 20 }}
                        classes={{
                            root: clsx(classes.loader, {
                                [classes.white]: rest.variant === 'contained',
                                [classes.blue]:
                                    this.props.theme.mode === 'dark' &&
                                    rest.variant === 'contained',
                            }),
                        }}
                    />
                ) : (
                    children
                )}
            </Button>
        );
    }
}

export default withTheme(withStyles(styles)(LoadingButton));
