export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const INIT = [];

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_NOTIFICATIONS:
            return payload;
        default:
            return state;
    }
}

export default reducer;
