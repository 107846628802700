import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';
import Button from 'components/Button';
import BPDChecker from 'components/BPDChecker';
import getStakeInfo from 'utils/get-stake-info';
import { MIN_STAKE_AMOUNT } from 'utils/variables';
import { ContractContext } from 'service/provider';
import { Grid, Typography } from '@material-ui/core';
import NumberFormatField from 'components/NumberFormatField';
import { TextField, InputAdornment, Tooltip, Slider } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { PHOENIX_PROMOTION } from 'containers/Accelerator/variables';

const StakingInput = ({
    amount,
    onChangeAmount,
    stakeDays,
    onChangeDays,
    restake,
    session,
    showBPD,
    disabled,
    hideStats,
    phoenixPromotion,
    minStakeDays = 1,
    amountLabel = 'Amount to stake',
    stakeLabel = 'Stake days',
    stakeDaysHelperText = 'Max stake: 5,555 Days',
}) => {
    const classes = useStyles();
    const [_days, _setDays] = useState(stakeDays.value);
    const { user, contractInfo, loadingContractInfo } = useContext(ContractContext);

    // Ensure id's don't change on every rerender
    const id1 = React.useRef(uuidv4()).current;
    const id2 = React.useRef(uuidv4()).current;

    let value = parseFloat(amount?.value || 0);
    if (restake) {
        value += parseFloat(session?.payout);
    }

    const { shares, lpb, totalShares, startDate, endDate } = useMemo(() => {
        let shareRate = 1;
        if (!loadingContractInfo) shareRate = contractInfo.staking.shareRate / 1e18;
        return getStakeInfo(value, stakeDays.value, shareRate);
    }, [contractInfo, value, stakeDays, loadingContractInfo]) 

    const changeAmount = (obj) => {
        onChangeAmount(obj);
    };

    const changeDays = (obj) => {
        onChangeDays(obj);
        _setDays(obj.value);
    };

    return (
        <React.Fragment>
            <div className={clsx(classes.borderBottom, { [classes.noBorder]: hideStats })}>
                <Grid container justify="center" alignContent="center">
                    {showBPD && (
                        <Grid item xs={12} md={12}>
                            <div className={classes.bpd}>
                                <BPDChecker days={_days ?? stakeDays.value} />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
            <div className={clsx(classes.root, { [classes.noBorder]: hideStats })}>
                <div className={clsx(classes.inputs, { [classes.noBorder]: hideStats, [classes.noInputPadding]: hideStats })} style={{ width: hideStats ? '100%' : undefined }}>
                    {amount && 
                        <div>
                            <Tooltip
                                placement="top"
                                title={`$${(
                                    parseFloat(amount.value || 0) * contractInfo?.axion?.usdPerAXN
                                ).numberWithCommas(2)}`}
                                arrow
                            >
                                <TextField
                                    label={amountLabel}
                                    variant="outlined"
                                    fullWidth
                                    disabled={!onChangeAmount}
                                    InputLabelProps={{ shrink: !amount.value ? false : true }}
                                    name={id1}
                                    id={id1}
                                    error={!amount.valid}
                                    onChange={(e) => {
                                        if (e.target.value < MIN_STAKE_AMOUNT) {
                                            return changeAmount?.({
                                                value: e.target.value,
                                                valid: false,
                                            });
                                        }
                                        return changeAmount?.({
                                            value: e.target.value,
                                            valid: true,
                                        });
                                    }}
                                    value={amount.value}
                                    InputProps={{
                                        className: classes.textfield,
                                        inputComponent: NumberFormatField,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    disabled={!onChangeAmount}
                                                    onClick={() =>
                                                        onChangeAmount &&
                                                        changeAmount({
                                                            value: user?.balance ?? '0',
                                                            valid: true,
                                                        })
                                                    }
                                                >
                                                    Send Max
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Tooltip>
                            <Typography variant="h6" className={classes.balance}>
                                Balance: {parseFloat(user?.balance ?? 0).numberWithCommas(2)}
                            </Typography>
                        </div>
                    }
                    <TextField
                        label={stakeLabel}
                        title="Staking Days"
                        variant="outlined"
                        name={id2}
                        id={id2}
                        fullWidth
                        disabled={disabled}
                        error={!stakeDays.valid}
                        InputLabelProps={{ shrink: !stakeDays.value ? false : true }}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                                return changeDays({ valid: true, value: '' });
                            } else if (value <= 0 || value > 5555) {
                                if (value > 999999) return;
                                return changeDays({ valid: false, value });
                            }
                            changeDays({ valid: true, value });
                        }}
                        value={stakeDays.value}
                        InputProps={{
                            className: classes.textfield,
                            inputComponent: NumberFormatField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        disabled={disabled}
                                        onClick={() =>
                                            changeDays({
                                                value: '5555',
                                                valid: true,
                                            })
                                        }
                                    >
                                        Max Days
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="h6" className={classes.maxLength}>
                        {stakeDaysHelperText}
                    </Typography>
                </div>

                {!hideStats && (
                    <React.Fragment>
                        {restake ? (
                            <div className={classes.body}>
                                <div className={clsx(classes.stat, classes.borderRight, classes.borderBottom)}>
                                    <Typography variant="h3">
                                        {moment(endDate).format('MMM DD, YYYY')}
                                    </Typography>
                                    <Typography variant="h6">End Day</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderBottom)}>
                                    <Typography variant="h3">{shares.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Basic Shares</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderRight, classes.borderBottom)}>
                                    <Typography variant="h3">
                                        {session?.payout?.numberWithCommas(2)}
                                    </Typography>
                                    {amount.value && (
                                        <Typography variant="h3">
                                            + {amount.value.numberWithCommas(2)}
                                        </Typography>
                                    )}
                                    <Typography variant="h6">Amount</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderBottom)}>
                                    <Typography variant="h3">{lpb.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Longer Pays Better</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderRight)}>
                                    <Typography variant="h3">
                                        {session?.penalty?.numberWithCommas?.(2)}
                                    </Typography>
                                    <Typography variant="h6">Late Penalty</Typography>
                                </div>
                                <div className={clsx(classes.stat)}>
                                    <Typography variant="h3">{totalShares.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Total Shares</Typography>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.body}>
                                <div className={clsx(classes.stat, classes.borderRight, classes.borderBottom)}>
                                    <Typography variant="h3">
                                        {moment(startDate).format('MMM DD, YYYY')}
                                    </Typography>
                                    <Typography variant="h6">Start Day</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderBottom)}>
                                    <Typography variant="h3">{shares.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Basic Shares</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderRight, classes.borderBottom)}>
                                    <Typography variant="h3">
                                        {moment(endDate).format('MMM DD, YYYY')}
                                    </Typography>
                                    <Typography variant="h6">End Day</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderBottom)}>
                                    <Typography variant="h3">{lpb.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Longer Pays Better</Typography>
                                </div>
                                <div className={clsx(classes.stat, classes.borderRight)}>
                                    <Tooltip title={contractInfo?.staking?.shareRate / 1e18 || 1} arrow={true}>
                                        <Typography variant="h3">
                                            {(contractInfo?.staking?.shareRate / 1e18 || 1)?.toFixed(4) || 1}
                                        </Typography>
                                    </Tooltip>
                                    <Typography variant="h6">Global Share Rate</Typography>
                                </div>
                                <div className={clsx(classes.stat)}>
                                    <Typography variant="h3">{totalShares.numberWithCommas(0)}</Typography>
                                    <Typography variant="h6">Total Shares</Typography>
                                </div>

                                    {phoenixPromotion && (
                                        <div className={clsx(classes.stat, classes.borderTop, classes.phoenix)}>
                                            <Typography variant="h3" className={clsx(classes.na, {
                                                [classes.colliderOrange]: phoenixPromotion.discount === 2,
                                                [classes.silver]: phoenixPromotion.discount === 3,
                                                [classes.gold]: phoenixPromotion.discount === 4,
                                            })}>{phoenixPromotion.name}
                                            </Typography>

                                            <Typography variant="h6">
                                                Phoenix NFT Eligibility&nbsp;
                                                <Tooltip enterTouchDelay={0} title={
                                                    <div>
                                                        <Typography variant="body2">The Phoenix NFT is a special utility NFT will grant you an accelerator discount. New stakes must be over {PHOENIX_PROMOTION[1].stake.minDays} days to be eligible.</Typography>
                                                        <br />
                                                        <div className={classes.flex}>
                                                            <Typography variant="caption">{PHOENIX_PROMOTION[1].stake.minAXN.shorten()} - {PHOENIX_PROMOTION[1].stake.maxAXN.shorten()} AXN = {PHOENIX_PROMOTION[1].name} NFT ({PHOENIX_PROMOTION[1].discount}% discount)</Typography>
                                                            <Typography variant="caption">{PHOENIX_PROMOTION[2].stake.minAXN.shorten()} - {PHOENIX_PROMOTION[2].stake.maxAXN.shorten()} AXN = {PHOENIX_PROMOTION[2].name} NFT ({PHOENIX_PROMOTION[2].discount}% discount)</Typography>
                                                            <Typography variant="caption">{PHOENIX_PROMOTION[3].stake.minAXN.shorten()}+ AXN = {PHOENIX_PROMOTION[3].name} NFT ({PHOENIX_PROMOTION[3].discount}% discount)</Typography>
                                                        </div>
                                                    </div>
                                                }>
                                                    <HelpOutline style={{ fontSize: "0.75rem" }} />
                                                </Tooltip>
                                            </Typography>
                                        </div>
                                    )}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>

            <div className={clsx(classes.sliderContainer, classes.borderBottom)}>
                <Slider
                    min={minStakeDays}
                    step={1}
                    max={5555}
                    disabled={disabled}
                    valueLabelDisplay="auto"
                    className={classes.slider}
                    value={`${stakeDays.value}` || '1'}
                    onChange={(_, value) => changeDays({ value: `${value}`, valid: true })}
                    marks={[
                        { value: 350, label: <Typography variant="h6">1 Year</Typography> },
                        { value: 1750, label: <Typography variant="h6">5 Years</Typography> },
                        { value: 3500, label: <Typography variant="h6">10 Years</Typography> },
                        { value: 5555, label: <Typography variant="h6">Max</Typography> },
                    ]}
                />
            </div>
        </React.Fragment>
    );
};

export default StakingInput;
