import axios from 'axios';
import { setSnackbar } from './snackbar';
import { SET_LAUNCHES } from 'redux/reducers/launches';
import { LAUNCH_API, UPDATES_API } from 'utils/variables';
import launchCache from 'assets/data/axion-launch.json';

export const get = () => async (dispatch) => {
    let launches = [];
    let alerts = [];

    try {
        const [l, a] = await Promise.all([axios.get(LAUNCH_API), axios.get(UPDATES_API)]);
        launches = l.data?.items || [];
        alerts = a.data?.items || [];

        launches.forEach((launch) => {
            let date = new Date(launch["al-launchdate"]);
            launch["launchMS"] = date.getTime() - date.getTimezoneOffset() * 60000;
        });
    } catch (err) {
        launches = launchCache.items;
        console.warn(`Error fetching launch data.`);
    }

    // Show an alert from the feed, if any.
    const alert = alerts[0];
    if (alert) {
        let type = 'error';
        let duration = 20000; // 20 seconds.

        if (alert['staking-warning-type']) type = alert['staking-warning-type'].toLowerCase();
        if (alert['staking-warning-duration']) duration = alert['staking-warning-duration'] * 1000;

        dispatch(
            setSnackbar({
                type,
                duration,
                message: alert['staking-warning'],
                link: alert['staking-warning-link'],
            })
        );
    }

    dispatch({
        type: SET_LAUNCHES,
        payload: { launches, alerts },
    });

    return { launches, alerts };
};
