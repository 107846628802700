import {
    ADD_TRANSACTION,
    UPDATE_TRANSACTION,
    DELETE_TRANSACTION,
    CLEAR_TRANSACTIONS,
} from 'redux/reducers/transactions';
import { store } from 'redux/config';

export function addTx(payload) {
    const { user } = store.getState();

    payload.pending = true;
    payload.address = user.address;
    payload.timeSubmitted = Date.now();

    return {
        type: ADD_TRANSACTION,
        payload,
    };
}

export function failedTx(id) {
    return {
        type: UPDATE_TRANSACTION,
        payload: { id, failed: true },
    };
}

export function completedTx(id) {
    return {
        type: UPDATE_TRANSACTION,
        payload: { id, failed: false },
    };
}

export function deleteTx(txID) {
    return {
        type: DELETE_TRANSACTION,
        payload: txID,
    };
}

export function clearTxs() {
    const { user } = store.getState();

    return {
        type: CLEAR_TRANSACTIONS,
        payload: user.address,
    };
}
