export const SET_SNACKBAR = 'SET_SNACKBAR';
export const INIT = { message: '' };

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_SNACKBAR:
            return payload;
        default:
            return state;
    }
}

export default reducer;
