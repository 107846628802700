export const DISCOUNT = 1.05; // 5%
export const MAX_BONUS = 1.15; // 15%
export const ANIMATION_KEY = 'accelerator_animations_disabled';
export const PHOENIX_MINT_END_DATE = 1662422399; // This represents the promotion of the Phoenix NFTs. When over, no more NFTs will be created.
export const PHOENIX_BONUS_START_DATE = 1661177067; // This representswhen the bonus will take effect.
export const PHOENIX_STACK_END_DATE = PHOENIX_BONUS_START_DATE + 15770000; // 15770000 = 6 months. This represents the date when the Phoenix + DiamondStake bonuses will stop stacking.

export const PHOENIX_PROMOTION = [
    { name: "N/A", discount: 0, minUSD: 0, maxUSD: 150, upgrade: { minAXN: 0, maxAXN: 5000000 }, stake: { minAXN: 0, maxAXN: 10000000, minDays: 0 }, maxMint: 0 },
    { name: "Standard", discount: 2, minUSD: 150, maxUSD: 300, upgrade: { minAXN: 5000000, maxAXN: 10000000 }, stake: { minAXN: 10000000, maxAXN: 20000000, minDays: 365 }, maxMint: 500 },
    { name: "Silver", discount: 3, minUSD: 300, maxUSD: 500, upgrade: { minAXN: 10000000, maxAXN: 20000000 }, stake: { minAXN: 20000000, maxAXN: 35000000, minDays: 365 }, maxMint: 350 },
    { name: "Gold", discount: 4, minUSD: 500, maxUSD: Infinity, upgrade: { minAXN: 20000000, maxAXN: Infinity }, stake: { minAXN: 35000000, maxAXN: Infinity, minDays: 365 }, maxMint: 200 }
]

export const PROMOTION_DISCOUNTS = {
    DIAMONDSTAKE: 0.05,
    PHOENIX_GOLD: 0.04,
    PHOENIX_SILVER: 0.03,
    PHOENIX_STANDARD: 0.02,
}

export const PARTICLE_SETTINGS = (theme, mode) => {
    let config = {
        particles: {
            number: {
                value: 75,
                density: {
                    enable: true,
                    value_area: 800,
                },
            },
            color: {
                value: theme.brand.accent,
            },
            shape: {
                type: 'circle',
                stroke: {
                    width: 0,
                    color: '#000000',
                },
            },
            opacity: {
                value: 0.75,
                random: true,
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                },
            },
            size: {
                value: 2,
                random: true,
                anim: {
                    enable: true,
                    speed: 10,
                    size_min: 20,
                    sync: true,
                },
            },
            line_linked: {
                enable: true,
                distance: 50,
                color: theme.brand.primary,
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 1,
                direction: 'top',
                random: true,
                straight: true,
                out_mode: 'out',
                bounce: false,
                attract: {
                    enable: true,
                    rotateX: 600,
                    rotateY: 1200,
                },
            },
        },
        retina_detect: true,
    };

    if (mode === 'snow') {
        config.particles.number.value = 50;
        config.particles.color.value = '#fffafa';
        config.particles.opacity.anim.enable = false;
        config.particles.line_linked.enable = false;
        config.particles.move.direction = 'bottom';
        config.particles.move.straight = false;
    }

    return config;
}