const copyPromise = (text) => {
    return new Promise(async (resolve, reject) => {
        try {
            await navigator.clipboard.writeText(text);
            resolve();
        } catch (err) {
            let input = document.createElement('input');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            try {
                document.execCommand('copy');
                document.body.removeChild(input);
                resolve();
            } catch (err) { reject() }
        }
    });
};

export default copyPromise;
