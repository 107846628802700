import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    select: {
        marginTop: theme.spacing(1),
    },
    selectMenu: {
        borderRadius: 5,
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, .15)',
    },
    tokenIcon: {
        width: '30px',
        filter: theme.mode === 'supernova' ? 'sepia(20%) saturate(3000%) brightness(95%)' : undefined,
        
        [theme.breakpoints.down('lg')]: {
            width: '30px',
        },
    },
}));

export default useStyles;
