import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const SkeletonListItem = ({ classes, ...props }) => {
    return (
        <Typography className={classes.root} variant={props.variant}>
            <Skeleton variant="text" className={classes.skeleton} style={{ width: props.width }} />
        </Typography>
    );
};

export default withStyles(styles)(SkeletonListItem);
