import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: '3%',
    },
    launchTitle: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        borderBottom: `2px solid ${theme.brand.border}`,
    },
    launchContainer: {
        padding: theme.spacing(1),
        maxWidth: '100%',
    },
    normalText: {
        textTransform: 'none',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    launchImage: {
        width: '100%',
        borderRadius: 10,
        marginBottom: theme.spacing(1),
    },
    emptyContainer: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingBottom: theme.spacing(7),
    },
    launchDescription: {
        paddingLeft: theme.spacing(1),
    },
    connect: {
        textAlign: 'center',
        paddingTop: theme.spacing(5),
    },
    allocation: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
    },
    currentUpcomingContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    upcomingContainer: {
        width: '100%',
    }
}));

export default useStyles;
