import React from 'react';
// Modules
import clsx from 'classnames';
import Card from 'components/Card';
// Styles
import useStyles from './styles';
// Assets

const StatCard = ({ title, body, notes, img, Icon, gradient, variant, ...props }) => {
    const classes = useStyles(props);

    return (
        <Card
            classes={{
                root: clsx(classes.root, {
                    [classes.primary]: true,
                    [classes.secondary]: variant === 'secondary',
                    [classes.bgUniswap]: gradient === 'uniswap',
                    [classes.bgBlueteal]: gradient === 'blue-teal',
                    [classes.bgDark]: gradient === 'dark',
                }),
            }}
        >
            <div className={classes.row}>
                <div className={classes.statIconView}>
                    {img && (
                        <img
                            src={img}
                            alt={title}
                            className={classes.statIconImage}
                            color="primary"
                        />
                    )}
                    {Icon && <Icon src={img} className={classes.statIcon} color="primary" />}
                </div>
                <span className={classes.statCardTitle}>{title}</span>
            </div>
            <div className={classes.column}>
                <span className={classes.statCardValue}>{body}</span>
                <span className={classes.statCardNote}>{notes}</span>
            </div>
        </Card>
    );
};

export default StatCard;
