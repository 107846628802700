import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
}));

export default useStyles;
