import React, { useEffect, useState, useContext } from 'react';
import useStyles from './styles';
import Page from 'components/Page';
import PageTitle from './PageTitle';
import Meta from 'components/util/Meta';
import { useSelector } from 'react-redux';
import { ContractContext } from 'service/provider';
import ClaimVestedTokens from './ClaimVestedTokens';
import Background from 'components/Background/Background';

const Vesting = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState([]);
    const { chain } = useSelector(store => store);
    const [vestItems, setVestItems] = useState([]);
    const { library, wallet } = useContext(ContractContext);

    const getVestedItems = async () => {
        try {
            setLoading(true);
            const vestings = await library.Vesting.getUserVestings();
            setVestItems(vestings.filter(v => v.name !== "Direwolf Fundraiser"));
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (!wallet.account) setVestItems([])
    }, [wallet.account])

    useEffect(() => {
        if (library) getVestedItems();

        // eslint-disable-next-line
    }, [library, chain]);

    return (
        <React.Fragment>
            <Background />
            <Meta title="Axion | Vesting Engine" />
            <Page classes={{ inner: classes.inner }} header={<PageTitle />}>
                <div className={classes.section}>
                    <ClaimVestedTokens loadingPledges={loading} data={vestItems} refreshData={() => getVestedItems()} />
                </div>
            </Page>
        </React.Fragment>
    );
};

export default Vesting;
