import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    profile: {
        color: 'rgba(255,255,255,0.7)',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: '8px 16px',
        borderRadius: 30,
        fontSize: 14,
        backgroundColor: theme.brand.axion,
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
    },
    pendingTxContainer: {
        padding: 0,
        margin: 0,
        maxWidth: '75%',
    },
    whiteText: {
        color: 'rgba(255,255,255,0.7)',
    },
    disconnect: {
        cursor: 'pointer',
        marginLeft: '7px',
        fontSize: '1.2em',

        '&:hover': {
            transform: 'rotate(90deg)',
            transition: 'transform 0.3s ease',
        },
    },
}));

export default useStyles;
