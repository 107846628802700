import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Card from './Card';
import useStyles from './styles';
import Reward from 'react-rewards';
import Page from 'components/Page/Page';
import EstimateCard from './EstimateCard';
import { useSelector } from 'react-redux';
import Meta from '../../components/util/Meta';
import PageTitle from './PageTitle/PageTitle';
import StakeCard from './StakeCard/StakeCard';
import getStakeInfo from 'utils/get-stake-info';
import { DISCLAIMERS } from 'utils/disclaimers';
import TypeSwitch from './TypeSwitch/TypeSwitch';
import PromoBanner from 'components/PromoBanner';
import { HelpOutline, InfoOutlined } from '@material-ui/icons';
import AcceleratorBonus from './AcceleratorBonus';
import { ContractContext } from 'service/provider';
import { getPrice } from 'utils/get-market-prices';
import approvedTokens, { getToken } from './tokens';
import { CONFETTI_SETTINGS, DEX } from 'utils/variables';
import AcceleratorStatistics from './AcceleratorStatistics';
import DisclaimerModal from 'components/Modals/DisclaimerModal';
import ToggleAnimationCheckbox from 'components/ToggleAnimationCheckbox';
import { Grid, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { PARTICLE_SETTINGS, ANIMATION_KEY, PROMOTION_DISCOUNTS, PHOENIX_STACK_END_DATE, PHOENIX_BONUS_START_DATE } from './variables';
import classNames from 'classnames';

const Accelerator = ({ match: { params }, location: { state } }) => {
    const rewardRef = useRef();
    const classes = useStyles();

    const isSquished = useMediaQuery('(max-width:1400px)');
    const { accelerator, theme } = useSelector((store) => store);
    const { user, library, loadingContractInfo, contractInfo } = useContext(ContractContext);

    const [_token, _setToken] = useState(null);
    const [_mode, _setMode] = useState('advanced');
    const [_loading, _setLoading] = useState(true);
    const [_axnAmount, _setAxnAmount] = useState(0);
    const [_tokenPrice, _setTokenPrice] = useState(0);
    const [_usingDivs, _setUsingDivs] = useState(false);
    const [_minStakeDays, _setMinStakeDays] = useState(120);
    const [_walletBalance, _setWalletBalance] = useState(0);
    const [_tokenApproved, _setTokenApproved] = useState(false);
    const [_days, _setDays] = useState({ value: '5555', valid: true });
    const [_particlesDisabled, _setParticlesDisabled] = useState(false);
    const [_tokenAmount, _setTokenAmount] = useState({ value: '', valid: true });
    const [_bonus, _setBonus] = useState({ end: 0, max: 0, remaining: 0, active: false });
    const [sawDisclaimer, setSawDisclaimer] = useState(
        localStorage.getItem(DISCLAIMERS.ACCELERATOR.key)
    );

    // Particles.js script
    useEffect(() => {
        if (window.particlesJS) {
            const disabled = localStorage.getItem(ANIMATION_KEY) === '1';
            if (!disabled) window.particlesJS('particles-js', PARTICLE_SETTINGS(theme));
            else _setParticlesDisabled(true);
        }

        return () => {
            if (window['pJSDom']?.length > 0) {
                for (let i = 0; i < window['pJSDom']?.length; i++)
                    window['pJSDom'][i].pJS.fn.vendors.destroypJS();
                window['pJSDom'] = [];
            }
        };
    }, [theme]);

    useEffect(() => {
        _setToken(getToken(params));
        if (state?.usingDivs) {
            setTimeout(() => {
                _setUsingDivs(true);
            }, 1000);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user && library && _token) _getSetBalance();

        // eslint-disable-next-line
    }, [user, library, _token]);

    useEffect(() => {
        if (_token && !loadingContractInfo) {
            _setTokenAmount({ value: `${_token.prefillAmount}`, valid: true });
            window.history.pushState(null, '', `/accelerator/${_token.symbol}`);

            _setLoading(true);
            _getSetPrice().finally(() => _setLoading(false));
        }

        // eslint-disable-next-line
    }, [_token, loadingContractInfo]);

    useEffect(() => {
        let data = {
            minStakeDays: 120,
            bonus: { end: 0, max: 0, remaining: 0, active: false },
        };

        if (!loadingContractInfo && contractInfo.accelerator) {
            const accelerator = contractInfo.accelerator;
            if (accelerator) {
                data.minStakeDays = accelerator.minStakeDays;
                data.bonus = {
                    active: accelerator.bonusActive,
                    max: accelerator.maxBoughtPerDay,
                    remaining: accelerator.bonusRemaining,
                    endSeconds:
                        accelerator.start + (accelerator.currentDay + 1) * accelerator.seconds,
                };
            }
        }

        _setBonus(data.bonus);
        _setMinStakeDays(data.minStakeDays);
    }, [loadingContractInfo, accelerator, user, contractInfo]);

    const _getSetPrice = async () => {
        let price = 0;

        if (!accelerator.loading) price = accelerator.tokenPrices[_token.symbol];
        else price = await getPrice(_token);

        _setTokenPrice(price);
        _setAxnAmount((price * _token.prefillAmount) / contractInfo?.axion?.usdPerAXN);
        return price;
    };

    const _getSetBalance = async () => {
        const [balance, allowance] = await Promise.all([
            library.Staker.getBalance(_token.address, _token.decimals),
            library.Staker.getAllowance(_token.address),
        ]);

        _setWalletBalance(balance);
        _setTokenApproved(allowance > 0);
        _setDays({ value: _days.value, valid: true });
        return balance;
    };

    const onAmountChange = (v) => {
        _setTokenAmount(v);
        _setAxnAmount((v.value * _tokenPrice) / contractInfo?.axion?.usdPerAXN);
    };

    const stakeInfo = useMemo(() => {
        let shareRate = 1;

        if (!loadingContractInfo) shareRate = contractInfo.staking.shareRate / 1e18;

        return getStakeInfo(_axnAmount, _days.value, shareRate);
    }, [_axnAmount, _days.value, loadingContractInfo]);

    const handleToggle = (mode) => {
        _setMode(mode);
    };

    const doConfetti = () => {
        rewardRef?.current?.rewardMe();
    };

    const calculateBonus = ({ tokenAmount, axnAmount, days }) => {
        const currentPriceAXN = loadingContractInfo ? 0.000004 : contractInfo?.axion?.usdPerAXN;

        const bonusPercent = Math.floor(days / 350); // 1-15
        const percent = 1 + bonusPercent / 100; // e.g 1.09
        const bonusActive = _bonus.active && bonusPercent > 0;

        const hasDiamondStake = user?.hasNFT('DIAMONDSTAKE');
        const hasGoldPhoenix = user?.hasNFT('PHOENIX_GOLD');
        const hasSilverPhoenix = user?.hasNFT('PHOENIX_SILVER');
        const hasStandardPhoenix = user?.hasNFT('PHOENIX_STANDARD');
        const discountActive = hasDiamondStake || hasGoldPhoenix || hasSilverPhoenix || hasStandardPhoenix;

        // We can pass in either tokenAmount or axnAmount - this handles that.
        let axnAmt = axnAmount ?? (tokenAmount * _tokenPrice) / currentPriceAXN;

        // Reduce axnAmy by 1% to account for slippage on tx
        // axnAmt = axnAmt * 0.99;

        // Apply Discount (amount + discount%)
        let discount = 1;
        if (Date.now() / 1000 < PHOENIX_STACK_END_DATE) {
            if (hasDiamondStake) discount += PROMOTION_DISCOUNTS.DIAMONDSTAKE;

            if (Date.now() / 1000 >= PHOENIX_BONUS_START_DATE) {
                if (hasGoldPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_GOLD;
                else if (hasSilverPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_SILVER;
                else if (hasStandardPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_STANDARD;
            }
        } else {
            if (hasDiamondStake) discount += PROMOTION_DISCOUNTS.DIAMONDSTAKE;
            else if (hasGoldPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_GOLD;
            else if (hasSilverPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_SILVER;
            else if (hasStandardPhoenix) discount += PROMOTION_DISCOUNTS.PHOENIX_STANDARD;
        }

        let axn = discountActive ? axnAmt * discount : axnAmt;

        // Apply Bonus up until remaining amount (amount + discount% + bonus%)
        if (bonusActive && axn > _bonus.remaining) {
            axn -= _bonus.remaining;
            axn = _bonus.remaining + (_bonus.remaining * bonusPercent) / 100 + axn;
        } else axn = bonusActive ? axn * percent : axn;

        let shareRate = 1;

        if (!loadingContractInfo) shareRate = contractInfo.staking.shareRate / 1e18;

        const { lpb, shares, totalShares } = getStakeInfo(axn, days, shareRate);
        const totalPercent = (axn / axnAmt) * 100 - 100;

        return {
            lpb,
            shares,
            principal: axn,
            axnBeforeBonus: axnAmt,
            bonusAXN: axn - axnAmt,
            totalShares: totalShares,
            active: bonusActive || discountActive,
            percent: isNaN(totalPercent) ? 0 : totalPercent,
        };
    };

    const items = useMemo(() => {
        if (!contractInfo) return [];
        if (!contractInfo.accelerator) return [];

        return [
            {
                title: "Current Mint Amount:",
                subtitle: "Amount of AXN minted",
                tooltip: "since the addition of the 'max mint' feature",


                value: contractInfo.accelerator.mintedAmount,
                subValue: contractInfo.accelerator.maxMint,

                color: classes.gold,
            }
        ]
    }, [contractInfo]);

    return (
        <>
            <ToggleAnimationCheckbox />
            <Meta title={`Axion Accelerator | Accelerate Your Crypto`} />
            <div id="particles-js" className={classes.particles} />
            <DisclaimerModal
                open={!sawDisclaimer}
                data={DISCLAIMERS.ACCELERATOR}
                onClose={() => setSawDisclaimer(true)}
            />

            {_token && (
                <Page header={<PageTitle loading={!user} bonusInfo={_bonus} />}>
                    <div className={classes.confetti}>
                        <Reward ref={rewardRef} type="confetti" config={{ ...CONFETTI_SETTINGS }}>
                            <></>
                        </Reward>
                    </div>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={isSquished ? 12 : 7} lg={isSquished ? 12 : 7}>
                            <Card
                                title={
                                    <span>
                                        Buy &amp; Stake <span className={classes.bold}>Axion</span>
                                    </span>
                                }
                                titleAction={
                                    <TypeSwitch onClick={(m) => handleToggle(m)} active={_mode} />
                                }
                            >
                                <StakeCard
                                    days={_days}
                                    token={_token}
                                    loading={_loading}
                                    setToken={_setToken}
                                    confetti={doConfetti}
                                    interfaceType={_mode}
                                    amount={_tokenAmount}
                                    usingDivs={_usingDivs}
                                    onChangeDays={_setDays}
                                    tokens={approvedTokens}
                                    tokenPrice={_tokenPrice}
                                    balance={_walletBalance}
                                    isApproved={_tokenApproved}
                                    setUsingDivs={_setUsingDivs}
                                    animOff={_particlesDisabled}
                                    minStakeDays={_minStakeDays}
                                    stakeInfo={stakeInfo}
                                    onChangeAmount={onAmountChange}
                                    calculateBonus={calculateBonus}
                                    setInterfaceType={(t) => handleToggle(t)}
                                    shareRate={contractInfo?.staking?.shareRate / 1e18 ?? 1}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={isSquished ? 12 : 5} lg={isSquished ? 12 : 5}>
                            <Grid item>
                                <Card
                                    subtitle="Your estimated bonus amount if you stake now"
                                    title={
                                        <span>
                                            Accelerator{' '}
                                            <span className={classes.bold}>
                                                Bonus &amp; Earnings
                                            </span>{' '}
                                            Estimate
                                            <Tooltip
                                                enterDelay={0}
                                                title={`Maximum possible amounts for bonus. Exact amounts are based on the outputs from ${DEX}.`}
                                                arrow
                                                placement="right"
                                            >
                                                <InfoOutlined className={classes.info} />
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    <AcceleratorBonus
                                        stakeInfo={stakeInfo}
                                        calculateBonus={calculateBonus}
                                        currentPrice={
                                            loadingContractInfo
                                                ? 0.000004
                                                : contractInfo?.axion?.usdPerAXN
                                        }
                                    />
                                    {/* <div className={classes.estimateCard}>
                                        <div className={classes.titleContainer}>
                                            <Typography className={classes.titleText}>
                                                <span>
                                                    <span className={classes.bold}>
                                                        Passive Income Generation
                                                    </span>{' '}
                                                    Estimate{' '}
                                                    <Tooltip
                                                        enterDelay={0}
                                                        title="This is an estimate based on current pool size. It is impossible to accurately predict the amount of rewards you will receive over the term of your stake, as it is based on many variables. We do not hold any responsibility for miscalculations, and reserve the right to update the formula used to produce these estimates at any time. Do not use this as investment advice."
                                                        arrow
                                                        placement="right"
                                                    >
                                                        <InfoOutlined className={classes.info} />
                                                    </Tooltip>
                                                </span>
                                            </Typography>
                                            <Typography className={classes.subtitle}>
                                                The estimated amount of Bitcoin you could earn.
                                            </Typography>
                                        </div>
                                        <EstimateCard
                                            calculateBonus={calculateBonus}
                                            stakeInfo={stakeInfo}
                                        />
                                    </div> */}
                                </Card>

                                <div style={{ marginTop: "15px" }} />

                                {items.length > 0 && (
                                    <Card
                                        subtitle="Overview of the global accelerator max mint amount"
                                        title={
                                            <span>
                                                Accelerator Mint Metrics
                                                <Tooltip
                                                    enterDelay={0}
                                                    title={`Tracks the current mint amount for the Accelerator. Once the max mint amount is reached, the Accelerator will be discontinued.`}
                                                    arrow
                                                    placement="right"
                                                >
                                                    <InfoOutlined className={classes.info} />
                                                </Tooltip>
                                            </span>
                                        }
                                    >
                                        <Grid container direction="column" spacing={1} style={{ padding: "1% 3%" }}>
                                            {items.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item container justify="space-between" alignItems="center">
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.title}>{item.title}</Typography>
                                                            <Typography className={classes.term}>
                                                                {item.subtitle}
                                                                {item.tooltip && (
                                                                    <React.Fragment>
                                                                        {' '}
                                                                        <Tooltip enterDelay={0} arrow title={item.tooltip}>
                                                                            <HelpOutline className={classNames(classes.term, classes.pointer)} />
                                                                        </Tooltip>
                                                                    </React.Fragment>
                                                                )}

                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography className={classNames(classes.value, { [item.color]: item.colorActive })}>
                                                                {(item.value || 0).numberWithCommas(0)} <span className={classes.amount}>/ {(item.subValue || 0).numberWithCommas(0)}{item.overrideSuffix ? item.overrideSuffix : " AXN"}</span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {item.dividerAfter && <Grid item className={classes.divider} />}
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PromoBanner />
                        </Grid>
                        <Grid item xs={12}>
                            <AcceleratorStatistics />
                        </Grid>
                    </Grid>
                </Page>
            )}
        </>
    );
};

export default Accelerator;
