import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        textAlign: 'center',
    },
    slider: {
        width: '90% !important',
        marginTop: theme.spacing(3.5),
        [theme.breakpoints.down('sm')]: {
            width: '80% !important',
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
}));

export default useStyles;
