import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    timerText: {
        display: 'inline',
        fontSize: '1.2rem',
    }
}));

export default useStyles;
