import React, { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import useStyles from './styles';
import Card from 'components/Card';
import { BiError } from 'react-icons/bi';
import { NEWS_API } from 'utils/variables';
import NewsItems from 'assets/data/axion-articles.json';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const News = () => {
    const classes = useStyles();
    const [newsError, setNewsError] = useState(false);
    const [newsLoading, setNewsLoading] = useState(true);
    const [newsItems, setNewsItems] = useState([...NewsItems.items]);

    useEffect(() => {
        const _getNewsItems = async (amount = 3) => {
            try {
                setNewsLoading(true);
                const news = await axios(NEWS_API, { method: 'GET', timeout: 5000 });
                setNewsItems(news.data.items.slice(0, amount));
            } catch (e) {
                setNewsError(true);
            } finally {
                setNewsLoading(false);
            }
        };

        _getNewsItems();
    }, []);

    return (
        <Card glossy>
            <div className={classes.mainTitle}>
                <Typography variant="h3">Latest News</Typography>
                <Typography variant="subtitle1">
                    News from the&nbsp;
                    <span
                        className={classes.hyperlink}
                        onClick={() => window.open('https://axion.network/news')}
                    >
                        Axion Foundation
                    </span>
                </Typography>
            </div>

            <div>
                {(newsLoading || newsError) && (
                    <div className={classes.loading}>
                        {newsError ? (
                            <BiError className={classes.error} />
                        ) : (
                            <CircularProgress color="primary" />
                        )}
                        <Typography variant="h4" style={{ marginTop: '2%' }}>
                            {newsError ? 'Error Loading News' : 'Loading News...'}
                        </Typography>
                        {newsError && (
                            <Typography
                                className={classes.hyperlink}
                                onClick={() => window.open('https://axion.network/news')}
                            >
                                Click here to visit the news page directly
                            </Typography>
                        )}
                    </div>
                )}
                {!newsLoading &&
                    !newsError &&
                    newsItems.map((n, idx) => (
                        <Grid
                            key={idx}
                            container
                            className={classes.newsItem}
                            onClick={() => window.open(n.link)}
                        >
                            {/* <Grid item xs={4} style={{ 
                                height: 100,
                                borderRadius: 5,
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center', 
                                backgroundImage: `url(${n.enclosure.url})`, 
                            }}/> */}
                            <Grid item xs={4}>
                                <img alt="news" className={classes.newsImg} src={n.enclosure.url} />
                            </Grid>
                            <Grid container direction="column" item xs={8} justify="space-between">
                                <Grid item>
                                    <Typography className={classes.newsTitle} variant="h4">
                                        {n.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.newsDate} variant="subtitle1">
                                        Published {moment.utc(n.pubDate).fromNow()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
            </div>
        </Card>
    );
};

export default News;
