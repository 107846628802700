import React, { useContext, useMemo, useState } from 'react';
import { BN } from 'bn.js';
import Restake from './Restake';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import WithdrawStake from './WithdrawStake';
import Button from 'components/Button/Button';
import { ContractContext } from 'service/provider';
import UpgradeStake from 'components/UpgradeStake';
import SwipeableViews from 'react-swipeable-views';
import { MIN_STAKE_AMOUNT } from 'utils/variables';
import SlideUp from 'components/Transitions/SlideUp';
import { setSnackbar } from 'redux/actions/snackbar';
import { CheckCircleOutline } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, Tab, Tabs, Typography, DialogActions, useTheme } from '@material-ui/core';

const ActionsModal = ({ open, data, onClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [topUp, setTopUp] = useState({ value: '', valid: true });
    const [restakeDays, setRestakeDays] = useState({ value: '', valid: true });

    const { 
        web3,
        user,
        library, 
        contractInfo, 
        getWalletDetails,
        loadingContractInfo, 
    } = useContext(ContractContext)

    const shareRate = useMemo(() => {
        if (!loadingContractInfo)
            return parseFloat(contractInfo.staking.shareRate / 1e18).toFixed(4);
        else return 1;
    }, [loadingContractInfo, contractInfo]);

    const restakeDisabled = useMemo(() => {
        if (!restakeDays.value || restakeDays.value === 0) return true;  
        if (user && topUp.value) {
            return new BN(web3.utils.toWei(`${user?.balance}`)).lt(
                new BN(web3.utils.toWei(`${topUp.value}`))
            );
        }
        return false;
        
        // eslint-disable-next-line
    }, [topUp, user, restakeDays])

    const tabData = useMemo(() => {
        const _handleAction = async (action) => {
            setLoading(true);

            try {
                if (action === 'restake') {
                    const days = restakeDays.value;
                    const amount = web3.utils.toWei(topUp.value || '0')
                    await library.Staking[action](data.stake, days, amount);
                }
                else await library.Staking[action](data.stake.id);

                handleClose();
                getWalletDetails();
            } catch (error) {
                dispatch(setSnackbar({ message: error.message }));
            } finally { setLoading(false) }
        }

        switch(tab) {
            case 0:
                return {
                    action: "Withdraw",
                    disabled: false,
                    handle: () => _handleAction('withdraw'),
                };
            case 1:
                return {
                    action: "Restake",
                    disabled: restakeDisabled,
                    handle: () => _handleAction('restake'),
                };
            case 2:
                return {
                    action: "Upgrade",
                    disabled: false,
                    handle: () => _handleAction('upgrade'),
                };
            default: 
                return {
                    action: "Withdraw",
                    disabled: false,
                    handle: () => _handleAction('withdraw'),
                };
        }

        // eslint-disable-next-line
    }, [tab, restakeDays, data, restakeDisabled])

    const handleTabChange = (_, value) => {
        setTab(value);

        setTopUp({ value: '', valid: true})
        setRestakeDays({ value: '', valid: true})
    }

    const handleClose = () => {
        setTab(0)
        setLoading(false);
        setTopUp({ value: '', valid: true})
        setRestakeDays({ value: '', valid: true})

        onClose();
    }

    return (
        <Dialog
            scroll={'body'}
            maxWidth={'md'}
            open={open === true}
            onClose={handleClose}
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                <Typography variant="h3" className={classes.title}>
                    <CheckCircleOutline className={classes.titleIcon} />
                    Stake Actions
                </Typography>
            </DialogTitle>

            <Tabs
                value={tab}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                onChange={handleTabChange}
            >
                <Tab label={<Typography>Withdraw</Typography>} />
                <Tab 
                    classes={{ disabled: classes.disabled }}
                    label={<Typography>Restake</Typography>} 
                    disabled={
                        data?.stake?.principal + data?.stake?.interest < MIN_STAKE_AMOUNT
                    } 
                />
                <Tab label={<Typography>Upgrade</Typography>} />
            </Tabs>

            <br />

            <DialogContent>
                <SwipeableViews
                    disabled
                    index={tab}
                    ignoreNativeScroll
                    animateHeight={true}
                    containerStyle={{ transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s' }}
                >
                    {/* keep DIVs, fixes bug */}
                    <div><WithdrawStake data={data} /></div>
                    <div><Restake data={data} topUp={topUp} setTopUp={setTopUp} restakeDays={restakeDays} setRestakeDays={setRestakeDays} /></div>
                    <div><UpgradeStake data={data} shareRate={shareRate} /></div>
                </SwipeableViews>
            </DialogContent>

            <DialogActions>
                <div className={classes.buttons}>
                    <Button fullWidth color="primary" variant="outlined"  onClick={handleClose}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button 
                        fullWidth
                        color="primary" 
                        loading={loading}
                        variant="contained" 
                        onClick={tabData.handle}
                        disabled={tabData.disabled || loading} 
                        override={theme.mode === 'dark' ? "blue" : 'supernova'}
                    >
                        {tabData.action}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ActionsModal;
