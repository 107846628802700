import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import ColliderLogo from 'assets/images/AxionColliderLogo.svg';

const PageTitle = () => {
    const classes = useStyles();
    const openColliderInfo = () => window.open('https://axion.network/collider', '_blank');

    return (
        <div className={classes.root}>
            <img src={ColliderLogo} className={classes.logo} alt="Axion Collider" />
            <Typography className={classes.body} variant="h6">
                Equip your Axion Particle NFTs in the Axion Collider for a chance to fuse into new AXN tokens! If your particles fuse into AXN, it will be staked for the duration that you choose.{' '}
                {/* <br className="break-on-large" /> */}
                Your AXN stake will behave just like a normal stake, including earning BTC rewards or enabling you to participate in Axion Launch (1 year stakes and up)!{' '}
                <br className="break-on-large" />
                <br className="break-on-large" />
                Not sure what the collider is? <span onClick={openColliderInfo} className={classes.link}>Click here to learn more</span>!
            </Typography>
        </div>
    );
};

export default PageTitle;
