import React from 'react';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// styles
import styles from './styles';

const SkeletonListItem = ({ classes, ...props }) => {
    return (
        <ListItem className={classes.listItem}>
            <ListItemText
                primary={<Skeleton variant="rect" />}
                secondary={<Skeleton variant="rect" />}
            />
        </ListItem>
    );
};

export default withStyles(styles)(SkeletonListItem);
