import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '0 10px 10px 10px',
        backgroundColor: theme.brand.CardBG,
    },
    success: {
        fontSize: '1.3rem',
        color: theme.brand.JavaGreen,
    },
    failed: {
        fontSize: '1.3rem',
        color: theme.brand.error,
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    contentContainer: {
        margin: theme.spacing(1),
    },
    txInfo: {
        cursor: 'pointer',
    },
    openArrow: {
        marginLeft: theme.spacing(1),
        fontSize: '0.9rem !important',
    },
    clear: {
        cursor: 'pointer',
    },
    emptyTransactions: {
        borderRadius: 5,
        padding: '3% 0',
        fontStyle: 'italic',
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.brand.border}`,
    },
    undoTransform: {
        textTransform: 'none',
    },
    addressBox: {
        margin: '0 2%',
        borderRadius: 5,
        padding: '2%',
        textAlign: 'center',
        border: `1px solid ${theme.brand.border}`,
    },
    wallet: {
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
    },
    walletAddress: {
        cursor: 'pointer',
    },
    buttonIcon: {
        fontSize: '1rem',
        marginRight: theme.spacing(1),
    },
    transactions: {
        padding: '0 2%',
    },
    transaction: {
        padding: '2% 0',
        cursor: 'pointer',
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
