import React from 'react';
import useStyles from './styles';
import Button from 'components/Button';
import NumberFormatField from 'components/NumberFormatField';
import { InputAdornment, TextField, Typography } from '@material-ui/core';

const SharedDays = ({ days, handleDays, handleMaxDays, minStakeDays }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <TextField
                name="Staking Days"
                label="Stake Days"
                title="Staking Days"
                variant="outlined"
                fullWidth
                error={!days.valid}
                InputLabelProps={{ shrink: !days.value ? false : true }}
                onChange={handleDays}
                value={days.value}
                InputProps={{
                    className: classes.textfield,
                    inputComponent: NumberFormatField,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button onClick={handleMaxDays}>Max Length</Button>
                        </InputAdornment>
                    ),
                }}
            />
            <Typography variant="h6" className={classes.maxLength}>
                Choose between <span className={classes.bold}>{minStakeDays}</span> and{' '}
                <span className={classes.bold}>5,555</span> days
            </Typography>
        </React.Fragment>
    );
};

export default SharedDays;
