import React, { useState } from 'react';
import Button from 'components/Button';
import useStyles from '../commonStyles';
import SlideUp from 'components/Transitions/SlideUp';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

const EarlyLateStakeModal = ({ open, data, onClose, onWithdraw }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const withdraw = async () => {
        try {
            setLoading(true);
            await onWithdraw(data?.session);
            close();
        } finally {
            setLoading(false);
        }
    };

    const close = () => {
        onClose();
        setLoading(false);
    };

    return (
        <Dialog
            maxWidth={'md'}
            scroll={'body'}
            onClose={close}
            open={open === true}
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle>
                <Typography variant="h3" className={classes.title}>
                    <ReportProblemOutlinedIcon className={classes.titleIcon} />
                    Attention, {data?.type}!
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant="body1">
                    You will get {data?.payout?.numberWithCommas(0) ?? 0.0} Axion,&nbsp;
                    <span className="error bold">
                        {data?.penalty?.numberWithCommas(0) ?? 0.0} Axion
                    </span>{' '}
                    will be <span className="error bold">penalized!</span>
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={close}>
                    Cancel
                </Button>
                <Button loading={loading} disabled={loading} onClick={withdraw} color="primary">
                    I understand, withdraw anyway
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EarlyLateStakeModal;
