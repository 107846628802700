const apexBareLineStyle = ({ theme, series, min, max, labels, xFormatter }) => {
    const fill = {
        type: 'gradient',
        colors: [theme.brand.NavigationBackground],
        gradient: {
            type: 'vertical',
            shadeIntensity: 0.4,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            gradientToColors: [theme.brand.NavigationBackground],
            // stops: [100],
        },
    };

    return {
        options: {
            chart: {
                id: 'apex-chart',
                toolbar: {
                    show: false,
                },
                height: '100%',
                sparkline: { enabled: true },
            },
            tooltip: {
                theme: 'dark',
                custom: function({seriesIndex, dataPointIndex, w}) {
                    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    return (`<div style="padding:5px;color:${theme.brand.NormalText};background-color:${theme.brand.CardBG}">
                        ${labels[dataPointIndex]} ago<br/>
                        <b>AXN USD Value:</b> ${data}
                    </div>`)
                },
                x: {
                    formatter: xFormatter,
                },
            },
            stroke: {
                curve: 'smooth',
                colors: series.map(() => theme.brand.ChartLine),
            },
            markers: {
                colors: [theme.brand.ChartLine],
            },
            yaxis: {
                show: false,
                min: min - 0.00001,
                max: max + 0.00001,
            },
            fill,
        },
        series: [
            {
                name: 'AXN USD Value',
                data: series,
            },
        ],
    };
};

export default apexBareLineStyle;
