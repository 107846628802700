import React, { useContext } from 'react';
import useStyles from './styles';
import NetworkCard from './NetworkCard';
import { Grid, Typography } from '@material-ui/core';
import { ContractContext } from 'service/provider';

const PageTitle = () => {
    const classes = useStyles();
    const { wallet } = useContext(ContractContext);
    
    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Axion Vesting Engine
                </Typography>

                <Grid container justify="space-between" spacing={2} alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Typography>
                            The table below will show any tokens that are currently vesting for the connected address{wallet?.account && ` (${wallet.account.shortenAddress()})`}.&nbsp;
                            <br className="break-on-large" />
                            If you participated in a Launch on the Ethereum network, please use the dropdown to change your network.&nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NetworkCard />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default PageTitle;
