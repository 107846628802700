import React from 'react';
import './index.css';

// Modules
import Router from './Router';

import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider, useSelector } from 'react-redux';
import { store, persistor } from './redux/config';
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from './utils/variables';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const ConnectedApp = () => {
    const { theme } = useSelector(store => store);
    const muiTheme = createMuiTheme(theme);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Helmet>
                <style>{`
                    body { background-color: ${theme.brand.Body}; }
                    ::-webkit-scrollbar-thumb {
                        background: ${theme.brand.ScrollBar};
                        border: 0px none #ffffff;
                        border-radius: 50px;
                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background: ${theme.brand.ScrollBarHover};
                    }
                    ::-webkit-scrollbar-thumb:active {
                        background: ${theme.brand.ScrollBarActive};
                    }
                `}</style>
            </Helmet>
            <Router />
        </MuiThemeProvider>
    );
};

const App = () => (
    <Provider store={store}>
        <IntercomProvider autoBoot={false} appId={process.env.REACT_APP_INTERCOM || INTERCOM_APP_ID}>
            <GoogleReCaptchaProvider reCaptchaKey="6LcF3IQcAAAAAJw6LIIqaqcsEXGbinMj0-ZFFfBR">
                <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
                    <ConnectedApp />
                </PersistGate>
            </GoogleReCaptchaProvider>
        </IntercomProvider>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));