export const SET_EVENTS = 'SET_EVENTS';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const INIT = {
    Items: [],
};

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_EVENTS:
            return payload;
        case EVENTS_ERROR:
        default:
            return state;
    }
}

export default reducer;