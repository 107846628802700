import React, { useRef } from 'react';

import classNames from 'classnames';
import { sum, uniqueId } from 'lodash';

import AxnValueTooltip from 'components/AxnValueTooltip';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';
import { useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const SharesBarChartMobile = ({ classes, values }) => {
    const id = useRef(uniqueId());
    const { theme } = useSelector(store => store);
    const total = sum(values.map(({ axn }) => axn));

    return (
        <div className={classes.root}>
            <div className={classes.bigPayDay}>
                <Typography className={classes.smallLabel}>Total Bigpaydays:</Typography>
                <Typography className={classes.bigPayDayAmt}>
                    <AxnValueTooltip value={total} suffix="" fixed={0} />
                </Typography>
                <br />
            </div>
            <div className={classes.bars}>
                {values.map(({ axn, date, paid }, i) => {
                    return (
                        <div key={`${id.current}-segment-${i}`} className={classes.bar}>
                            <Typography className={classNames(classes.axion, { [classes.paid]: paid })}>
                                <AxnValueTooltip value={axn} suffix="" fixed={0} />
                            </Typography>

                            <Typography variant="h5" className={classNames(classes.axn, { [classes.paid]: paid })}>
                                axn
                            </Typography>

                            <div
                                className={classNames(classes.segment, classes.down)}
                                style={{
                                    backgroundColor: theme.brand.BPDColors[i],
                                    flexBasis: `25%`,
                                }}
                            >
                                <Typography variant="overline" className={classes.bpd}>
                                    BPD {i + 1}
                                </Typography>
                            </div>
                            <Typography className={classNames(classes.smallLabel, { [classes.paid]: paid })}>{date}{paid && <CheckCircleOutlineIcon className={classes.check} />}</Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(SharesBarChartMobile);
