import React, { useMemo } from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import clsx from 'classnames';
import normalize from 'utils/normalize';

const PageTitle = ({ progress, soldOut, end }) => {
    const classes = useStyles();

    const tickArray = useMemo(() => {
        let maxOfRange = 100;
        let val = 100 - progress;

        // Make sure it doesn't go negative...
        val = Math.max(0, val);
        val = isFinite(val) ? val : 0;

        // Fill array proportionate to position in range
        const normal = normalize(val, 0, maxOfRange, 0, 60);
        return Array.from(new Array(Math.round(normal)));

    // eslint-disable-next-line
    }, [progress, end]);

    return (
        <div className={classes.arcReactor}>
            <div className={classes.arcContainer}>
                <div className={classes.e7}>
                    <div className={clsx(classes.semiArc, classes.e5_1)}>
                        <div className={clsx(classes.semiArc, classes.e5_2)}>
                            <div className={clsx(classes.semiArc, classes.e5_3)}>
                                <div className={clsx(classes.semiArc, classes.e5_4)} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.progressTextContainer}>
                        <Typography className={classes.centerProgressText}>
                            {soldOut ? '0%' : `${100 - progress}%`}
                        </Typography>
                        <Typography>remaining</Typography>
                    </div>
                </div>
                <ul className={classes.marks}>
                    {tickArray.map((_, idx) => (
                        <li key={`line-${idx}`}></li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PageTitle;
