import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import './animated-atom.css';

const AnimatedAtom = ({ showShadow, showCore = true, className, white, active, color }) => {
    const { theme } = useSelector(store => store);

    const outColor = useMemo(() => {
        if (active) return theme.brand.primary;
        if (color && white) return '#FFF';
        if (!color && white) return '#FFF';
        if (color && !white) return color;

        return theme.brand.NormalText
    }, [white, active, color, theme])

    return (
        <div className={className}>
            <svg className="svg_" viewBox="0 0 100 100">
                <g className="svg_ shadow">
                    <ellipse cx="50" cy="100" rx="50" ry="5" />
                </g>

                {showCore && (
                    <g className="svg_ core">
                        <circle
                            style={{ fill: outColor }}
                            cx="50"
                            cy="50"
                            r="10"
                        />
                        <circle
                            style={{ fill: outColor }}
                            cx="50"
                            cy="50"
                            r="10"
                        />
                    </g>
                )}

                <g>
                    <g className={`svg_ ring`} style={{ stroke: outColor }} transform="rotate(-30, 50, 50)">
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                    </g>
                    <g className={`svg_ ring`} style={{ stroke: outColor }} transform="rotate(30, 50, 50)">
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                    </g>
                    <g className={`svg_ ring`} style={{ stroke: outColor }} transform="rotate(90, 50, 50)">
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                        <ellipse cx="50" cy="50" rx="20" ry="50" />
                    </g>
                </g>

                {showShadow && (
                    <filter id="blur" x="-20%" y="-20%" width="140%" height="140%">
                        <feGaussianBlur stdDeviation="2" />
                    </filter>
                )}

                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" className="color1" />
                        <stop offset="100%" className="color2" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default AnimatedAtom;
