import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        maxWidth: 1500,
        padding: theme.spacing(4),
        [theme.breakpoints.only('lg')]: {
            maxWidth: '80vw',
            padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        },
    },
}));

export default useStyles;
