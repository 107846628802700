import axios from 'axios';
import { store } from 'redux/config';
import { BSC_RPC, CONTRACT_INFO, LAUNCH_API, POLYGON_RPC } from 'utils/variables';

const METHODS = {
    addChain: 'wallet_addEthereumChain',
    switchChain: 'wallet_switchEthereumChain',
    addCoin: 'wallet_watchAsset'
}

export const addToMetamask = async (address, symbol, decimals, name) => {
    if (window.ethereum) {
        let item;
        name = name.toLowerCase();

        try {
            const state = store.getState();
            if (state?.axion?.launches) 
                item = state.axion.launches.find((d) => d.title.toLowerCase().includes(name));
            else 
                item = (await axios.get(LAUNCH_API)).data.items.find((d) => d.title.toLowerCase().includes(name));
        } catch (err) { console.error('Could not get icon') }

        window.ethereum.request({
            method: METHODS.addCoin,
            params: {
                type: 'ERC20',
                options: {
                    address: address,
                    symbol: symbol,
                    decimals: decimals,
                    image: item['al-icon'] ? `https://axion.network/${item['al-icon']}` : undefined,
                },
            },
        });
    }
};

export const addAXNToMetamask = async () => {
    if (window.ethereum) {
        return window.ethereum.request({
            method: METHODS.addCoin,
            params: {
                type: 'ERC20',
                options: {
                    address: CONTRACT_INFO.AXN.ADDRESS,
                    symbol: "AXN",
                    decimals: 18,
                    image: 'https://stake.axion.network/metamask-axn-logo.png',
                },
            },
        });
    } else return null;
};

export const addPolygonToMetamask = () => {
    return window?.ethereum?.request({ 
        method: METHODS.addChain, 
        params: [{
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: [POLYGON_RPC],
            blockExplorerUrls: ['https://polygonscan.com/'],
            iconUrls: ['https://cryptologos.cc/logos/polygon-matic-logo.png'],
        }] 
    })
}

export const requestChainSwitchETH = () => {
    return window?.ethereum?.request({ method: METHODS.switchChain, params: [{ chainId: '0x1' }] });
}

export const addBSCToMetamask = () => {
    return window?.ethereum?.request({
        method: METHODS.addChain,
        params: [{
            chainId: '0x38',
            chainName: 'Binance Smart Chain Mainnet',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: [BSC_RPC],
            blockExplorerUrls: ['https://bscscan.com/'],
            iconUrls: ['https://cryptologos.cc/logos/binance-coin-bnb-logo.png'],
        }]
    })
}

export const requestChainSwitchBSC = () => {
    return window?.ethereum?.request({ method: METHODS.switchChain, params: [{ chainId: '0x38' }] });
}