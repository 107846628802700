import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { setSnackbar } from 'redux/actions/snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

const StyledSnackbar = (props) => {
    const {
        link,
        message,
        setSnackbar,
        type = 'error',
        vertical = 'top',
        horizontal = 'center',
        duration = AUTO_HIDE_TIME,
    } = props;

    function handleClose() {
        setSnackbar({ message: '' });
    }

    let open = true;
    let messageToShow = message;

    if (message === '') return null;

    // Replace some error messages with more user friendly ones
    if (message.toLowerCase().includes('execution reverted'))
        messageToShow = `Error: Not enough MATIC for gas. Please ensure your wallet has some MATIC.`;
    else if (message.toLowerCase().includes('transaction was not mined within 50 blocks'))
        messageToShow = `Your transaction is taking longer than usual due to a below average gas price that was set. Check Polygonscan for more info.`;
    else if (message.toLowerCase().includes('insufficient funds for transfer'))
        messageToShow = `Unable to calculate gas. Please enter a slightly smaller amount, or try again after refreshing the page.`;

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={duration}
            onClose={handleClose}
            style={{ zIndex: 99999 }}
        >
            <Alert severity={type}>
                {messageToShow}
                {link && (
                    <>
                        &nbsp;
                        <span
                            onClick={() => window.open(link)}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            Learn More
                        </span>
                    </>
                )}
            </Alert>
        </Snackbar>
    );
};

const mapStateToProps = ({ snackbar }) => ({ ...snackbar });

export default connect(mapStateToProps, { setSnackbar })(StyledSnackbar);
