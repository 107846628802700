import NFT from './contracts/nft';
import Axion from './contracts/axion';
import Staker from './contracts/staker';
import Pledge from './contracts/pledge';
import Staking from './contracts/staking';
import Vesting from './contracts/vesting';
import Utility from './contracts/utility';
import Contract from './contracts/contract';
import MineManager from './contracts/mining';
import Accelerator from './contracts/accelerator';
import { CONTRACT_INFO } from 'utils/variables';

export const loadContracts = (web3, ethWeb3, bscWeb3) => {
    const _contracts = { eth: {}, bsc: {} };

    for (let contract of Object.keys(CONTRACT_INFO)) {
        const params = CONTRACT_INFO[contract];
        if (params.ADDRESS && params.ABI)
            _contracts[contract] = new web3.eth.Contract(params.ABI, params.ADDRESS);
    }

    // Setup any leftover ETH contracts we may need with a web3 provider using infura.
    if (ethWeb3) {
        for (let contract of Object.keys(CONTRACT_INFO.ETH_READ)) {
            const params = CONTRACT_INFO.ETH_READ[contract];
            if (params.ADDRESS && params.ABI)
                _contracts.eth[contract] = new ethWeb3.eth.Contract(params.ABI, params.ADDRESS);
        }
    }

    if (bscWeb3) {
        for (let contract of Object.keys(CONTRACT_INFO.BSC_READ)) {
            const params = CONTRACT_INFO.BSC_READ[contract];
            if (params.ADDRESS && params.ABI)
                _contracts.bsc[contract] = new bscWeb3.eth.Contract(params.ABI, params.ADDRESS);
        }
    }

    return _contracts;
};

export const loadLibrary = (contracts, web3, ethWeb3, bscWeb3) => {
    Contract.initialize({ contracts, web3, ethWeb3, bscWeb3 });

    return {
        NFT: new NFT(),
        Axion: new Axion(),
        Staker: new Staker(),
        Pledge: new Pledge(),
        Staking: new Staking(),
        Vesting: new Vesting(),
        Utility: new Utility(),
        Accelerator: new Accelerator(),
        MineManager: new MineManager(),
    };
};
