import thunk from 'redux-thunk';
import reducers from './reducers';
import rootSaga from './config.saga';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { applyMiddleware, createStore, compose } from 'redux';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['user', 'events', 'theme', 'settings', 'launches', 'accelerator', 'transactions'],
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);

const enhancer = compose(
    applyMiddleware(thunk),
    applyMiddleware(sagaMiddleware),
    process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        typeof window.devToolsExtension !== 'undefined'
        ? // Call the brower extension function to create the enhancer.
          window.__REDUX_DEVTOOLS_EXTENSION__()
        : // Else we return a no-op function.
          (f) => f
);

export const history = createBrowserHistory();
export const store = createStore(persistedReducer, {}, enhancer);
export let persistor = persistStore(store);

async function shouldClearLocalStorage() {
    const clear = '14-clear';
    let value = localStorage.getItem(clear);
    if (!value) {
        persistor.purge();
        localStorage.clear();
        document.cookie.split(';').forEach((c) => {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });

        localStorage.setItem(clear, 'done');
    }
}

window.router = history;
shouldClearLocalStorage();
sagaMiddleware.run(rootSaga);
