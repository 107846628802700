import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Typography, Card as MuiCard, Grid } from '@material-ui/core';

const Card = ({ title, subtitle, children, titleAction }) => {
    const classes = useStyles();

    return (
        <MuiCard className={classes.root}>
            <div className={classes.title}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography
                            className={clsx(classes.titleText, { [classes.bigTitle]: !subtitle })}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    {titleAction && <Grid item>{titleAction}</Grid>}
                </Grid>
                {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
            </div>
            <div className={classes.body}>{children}</div>
        </MuiCard>
    );
};

export default Card;
