import { cloneDeep } from 'lodash';
import { SET_THEME } from 'redux/reducers/theme';
import { darkTheme, supernovaTheme } from 'theme';

// dark, supernova
export const setTheme = (theme) => (dispatch) => {
    let payload;
    switch (theme) {
        case 'dark': 
            payload = cloneDeep(darkTheme);
            break;
        case 'supernova':
            payload = cloneDeep(supernovaTheme);
            break;
        default:
            payload = cloneDeep(darkTheme);
    }

    dispatch({
        type: SET_THEME,
        payload,
    });
};