import React, { useContext, useMemo, useState } from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import Button from 'components/Button';
import useCheckKYC from 'hooks/useCheckKYC';
import { CONTRACT_INFO } from 'utils/variables';
import { openToken } from 'utils/open-etherscan';
import { ContractContext } from 'service/provider';
import { RefreshOutlined } from '@material-ui/icons';
import { setSnackbar } from 'redux/actions/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import LuxyLogo from 'assets/images/Logo_Full_Orange.png';
import WarpLogo from 'assets/images/WarpMarkText.png';
import LegionLogo from 'assets/images/Legion-Logo-White-Horizontal-2048x562.webp';
import SekuritanceLogo from 'assets/images/sekuritance.png';
import VabbleLogo from 'assets/images/vabble.png';

import {
    Badge,
    Grid,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
    Slider,
    CircularProgress,
    useMediaQuery,
} from '@material-ui/core';
import classNames from 'classnames';

const KYC_STATUS_TRANSLATION = {
    "NOT_VERIFIED": "You must complete that process before you can pledge.",
    "VERIFIED": "You have successfully completed the KYC process!",
    "PENDING": "Your KYC is pending review! Please check back shortly.",
    "IN_REVIEW": "Your KYC is pending review! Please check back shortly.",
    "DECLINED": "Your KYC was declined. Please contact Sekuritance for more info.",
    "FAILED": "Your KYC check failed. Please contact Sekuritance for more info.",
    "": "",
};

const PledgeItem = ({ item, userUSDC, refreshBalance, userPledges, refreshPledges}) => {
    const kyc = useCheckKYC();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { launches } = useSelector((store) => store);
    const { library, user } = useContext(ContractContext);
    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'));
    const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState('');

    const userPledge = useMemo(() => {
        const pledge = userPledges.find(
            (pledge) => pledge.id === item.start + item.end + item.totalPledgeAmount
        );

        return {
            tokens: pledge?.tokens || 0,
            amount: pledge?.amount || 0,
        };
    }, [item, userPledges]);

    const userAllocation = useMemo(() => {
        if (library && user) {
            const allo = user.allocations.find((a) => a.pledge.name === item.name);
            if (allo) return allo.allocation
            else return { id: 0, allocation: 0 };
        } else return { id: 0, allocation: 0 };
    }, [library, user, item]);

    const userAllocationRemaining = useMemo(() => {
        if (userAllocation.allocation === 0) return 0;
        return Math.max(userAllocation.allocation - userPledge.amount, 0);
    }, [userAllocation, userPledge]);

    const totalAllocationRemaining = useMemo(() => {
        return item.totalPledgeAmount - item.totalPledged;
    }, [item]);

    const url = useMemo(() => {
        if (launches) {
            const launch = launches.launches.find((l) =>
                l.title.toLowerCase().includes(item.name.toLowerCase())
            );
            if (launch) return launch.link;
        }
        return '';
    }, [launches, item]);

    const refreshUSDC = async () => {
        try {
            setLoading('usdc');
            await refreshBalance();
            dispatch(setSnackbar({ message: 'USDC balance refreshed', type: 'success' }));
        } catch (error) {
            dispatch(setSnackbar({ message: error.message }));
        }
        setLoading('');
    };

    const _pledge = async () => {
        await library.Pledge.sendPledge(item.name, amount, userAllocation.id);
        dispatch(setSnackbar({ message: 'Pledge Successfully Sent!', type: 'success' }));
        refreshBalance();
        refreshPledges();
    };

    const handleAction = async () => {
        setLoading('pledge');

        try {
            if (userUSDC.allowance < amount || userUSDC.allowance === 0) {
                await library.Pledge.approveUSDC();
                dispatch(
                    setSnackbar({
                        message: 'Approval Successful! You can now send your USDC.',
                        type: 'success',
                    })
                );

                if (item.isActive) await _pledge();                
            } else await _pledge();
        } catch (error) {
            dispatch(setSnackbar({ message: error.message }));
        }

        setLoading('');
    };

    const getButtonContent = () => {
        if (!item.isActive) return 'Pledging is Closed';
        if (!user) return 'Connect Wallet to Pledge';
        if (loading === 'pledge') return <CircularProgress size={24} />;
        if (userUSDC.allowance === 0) return 'Approve USDC';
        if (kyc.status !== "VERIFIED" && item.kycRequired) return "KYC Required";
        if (item.start >= parseInt(new Date().getTime() / 1000)) return 'Pledging Not Started';
        if (userAllocation.allocation === 0) return "No Allocation. Please Check Full Details!";
        if (isNaN(amount) || amount < 0) return 'Invalid Pledge Amount';
        if (userAllocationRemaining <= 0) return "You've Used Your Allocation";

        return `Send ${`${amount.numberWithCommas(2)} USDC`}`;
    };

    const getButtonDisabled = () => {
        if (!user) return true;
        if (loading === 'pledge') return true;
        if (kyc.status !== "VERIFIED" && item.kycRequired) return true;
        if (userUSDC.allowance === 0) return false;
        if (!item.isActive && userUSDC.allowance > 0) return true;
        if (isNaN(amount) || amount <= 0) return true;
        if (userUSDC.balance < amount) return true;
        if (userAllocationRemaining <= 0) return true;

        return false;
    };

    const handleAmountChange = (e) => {
        if (isNaN(e.target.value)) return setAmount(0);
        if (userAllocationRemaining <= 0) return setAmount(0);

        const value = parseFloat(e.target.value);
        if (userUSDC.balance >= value) {
            if (value <= userAllocationRemaining) setAmount(`${value}`);
            else setAmount(`${userAllocationRemaining}`);
        } 
        else setAmount(`${userUSDC.balance}`);
    };

    const isWarp = name => name.toLowerCase().includes('warp');

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h3">{item.name} Pledging</Typography>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ float: 'right', alignItems: 'center', display: 'flex' }}>
                        <Badge
                            variant="dot"
                            color="primary"
                            classes={{
                                badge: clsx(classes.badge, {
                                    [classes.green]: item.isActive,
                                    [classes.red]: !item.isActive,
                                }),
                            }}
                        />
                        <Typography align="right">
                            Pledging{item.isActive ? ' Open' : ' Closed'}
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            <div className={classes.mainContent}>
                <Grid container spacing={2}>
                    <Grid item container direction="column" justify="space-between" md={6} xs={12}>
                        <div style={{ textAlign: 'center' }}>
                            {item.name === 'Luxy' && <img src={LuxyLogo} className={classes.luxy} alt="luxy" />}
                            {item.name === "Legion Network" && <img src={LegionLogo} className={classes.luxy} alt="legion" />}
                            {item.name === "Vabble" && <img src={VabbleLogo} className={classes.luxy} alt="vabble" />}
                            {item.name === "Sekuritance" && <img src={SekuritanceLogo} className={classes.luxy} alt="Sekuritance" />}
                            {isWarp(item.name) && <img src={WarpLogo} className={classes.luxy} alt="warp" />}
                            <Typography align="justify">
                                {isWarp(item.name) ? 
                                    `Axion Launch presents WARP, an OHM fork featuring buildable NFT starships! Buy starship parts at a $150 discount. Purchased NFTs will be sent to you within 48 hours after the launch of WARP. Your WARP Starship Parts NFTs will be visible on the WARP webapp. To pledge tokens towards this launch, enter the amount you wish to pledge and press SEND. Once your funds have been submitted, your pledge is final.` : 
                                    `To pledge tokens towards this launch, enter the amount you wish to pledge and press SEND. Once your funds have been submitted, your pledge is final. Upon launch of ${item.name}, your tokens will be available to claim through our Vesting Engine, after any required period of vesting.`
                                }
                            </Typography>
                        </div>

                        <Button
                            fullWidth
                            size="small"
                            override="translucent"
                            classes={{ root: classes.button }}
                            onClick={() => window.open(url, '_blank')}
                        >
                            View Full Details of the {item.name} Launch
                        </Button>
                    </Grid>

                    <Grid item container direction="column" justify="space-between" md={6} xs={12}>
                        <Grid container justify="space-evenly" spacing={2}>
                            {item.kycRequired && (
                                <Grid item xs={12}>
                                    <Typography className={classNames(classes.thin, classes.kyc)} align="center">
                                        <span className={classes.bold}>KYC is Required for this launch.</span>{" "}
                                        {KYC_STATUS_TRANSLATION[kyc.status]}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid
                                item
                                xl={6}
                                xs={12}
                                style={{ textAlign: isXL ? 'center' : 'left' }}
                            >
                                <Typography className={classes.thin}>
                                    <span className={classes.bold}>{isWarp(item.name) ? "NFT Price" : "IDO Token Price"}:</span>&nbsp;
                                    ${item.pricePerToken.toLocaleString('en-US', {
                                        maximumFractionDigits: 4,
                                    })}
                                </Typography>
                                <Typography className={classes.thin}>
                                    <span className={classes.bold}>
                                        {!isXS && 'Pledge'} Currency:
                                    </span>
                                    &nbsp;
                                    <span
                                        className={classes.link}
                                        onClick={() => openToken(item.pledgeToken.address, item.pledgeToken.networkID)}
                                    >
                                        {item.pledgeToken.symbol} ({item.pledgeToken.network} Network)
                                    </span>
                                </Typography>

                                <Typography className={classes.thin}>
                                    <span className={classes.bold}>
                                        Progress:
                                    </span>
                                    &nbsp;
                                    {item.totalPledged.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                                    &nbsp;/&nbsp;
                                    {item.totalPledgeAmount.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}
                                    &nbsp; (
                                    {((item.totalPledged / item.totalPledgeAmount) * 100).toFixed(
                                        2
                                    )}
                                    %)
                                </Typography>
                            </Grid>

                            {user && (
                                <Grid
                                    item
                                    xl={6}
                                    xs={12}
                                    style={{ textAlign: isXL ? 'center' : 'left' }}
                                >
                                    <Typography
                                        className={classes.thin}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: isXL ? 'center' : 'flex-start',
                                        }}
                                    >
                                        <span className={classes.bold}>
                                            {!isXS && 'My'} USDC Balance:
                                        </span>
                                        &nbsp;
                                        {userUSDC.balance.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                        &nbsp;
                                        <IconButton
                                            disabled={loading === 'usdc'}
                                            onClick={refreshUSDC}
                                            style={{ padding: 2 }}
                                        >
                                            <RefreshOutlined className={classes.refresh} />
                                        </IconButton>
                                    </Typography>
                                    <Typography className={classes.thin}>
                                        <span className={classes.bold}>
                                            {isXS ? 'My Pledge' : 'Allocation | Pledged (USDC)'}:
                                        </span>
                                        &nbsp;
                                        {userAllocation.allocation.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                        &nbsp;|&nbsp;
                                        {userPledge.amount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </Typography>
                                    <Typography className={classes.thin}>
                                        <span className={classes.bold}>
                                            {isXS ? 'My Tokens' : 'Allocation | Pledged (Token)'}:
                                        </span>
                                        &nbsp;
                                        {(
                                            userAllocation.allocation * item.tokenPerDollar
                                        ).numberWithCommas(2)}
                                        &nbsp;|&nbsp;
                                        {userPledge.tokens.numberWithCommas(2)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                        {user && item.isActive && isWarp(item.name) && (
                            <Grid container spacing={2} className={classes.warpPledgeContainer}>
                                {new Array(4).fill(0).map((_, i) => {
                                    const val = (i + 1) * 100;
                                    return (
                                        <Grid item sm={3} xs={6} justify='space-evenly'>
                                            <Button
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                onClick={() => setAmount(val)}
                                                style={{ transition: 'all 0.3s ease' }}
                                                disabled={userAllocationRemaining < val}
                                                classes={{ root: amount === val && classes.buttonSelected }}
                                            >
                                                {i + 1}{i === 0 ? " NFT" : " NFTs"}
                                            </Button>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}

                        {user && item.isActive && !isWarp(item.name) && (
                            <Grid
                                container
                                justify="space-evenly"
                                alignItems="center"
                                spacing={2}
                                className={classes.pledgeAmount}
                            >
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justify="center"
                                    xs={12}
                                    sm={9}
                                >
                                    <Slider
                                        min={0}
                                        step={1}
                                        value={amount}
                                        onChange={(_, value) => setAmount(value)}
                                        max={Math.min(userUSDC.balance, userAllocationRemaining, totalAllocationRemaining)}
                                        classes={{
                                            thumb: classes.thumb,
                                            root: classes.sliderRoot,
                                            track: classes.trackRail,
                                            rail: classes.trackRail,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        value={amount}
                                        variant="outlined"
                                        disabled={userAllocationRemaining === 0}
                                        onChange={handleAmountChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">USDC</InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Button
                            fullWidth
                            size="small"
                            override="translucent"
                            onClick={() => handleAction()}
                            disabled={getButtonDisabled()}
                            classes={{ root: classes.button }}
                            style={{ transition: 'all 0.3s ease' }}
                        >
                            {' '}
                            {getButtonContent()}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default PledgeItem;
