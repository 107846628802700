import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    /** BPD / STake */
    stakerTitle: {
        padding: '1rem',
        // borderBottom: `1px solid ${theme.brand.border}`,
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },

    bpd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },

        '& > div': {
            borderRight: `1px solid ${theme.brand.border}`,
        },
        '& > div:last-child': {
            borderRight: `none`,
        },
    },
    bpdItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginRight: 6,
        paddingRight: 6,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
        },
    },
    check: {
        fontSize: 18,
        marginRight: 4,
        color: theme.brand.JavaGreen,
    },
    close: {
        fontSize: 18,
        marginRight: 4,
        color: theme.brand.error,
    },
    bpdText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        marginBottom: 4,
        color: theme.brand.SecondaryText,
    },
    bpdDate: {
        display: 'block',
        fontSize: 14,
        color: theme.brand.GrayChateau,
    },

    stakeFooter: {
        padding: '16px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    confetti: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}));

export default useStyles;
