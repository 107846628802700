import MetamaskLogo from './logos/metamask.svg';
import WalletConnectLogo from './logos/walletconnect-circle.svg';

const providers = [
    {
        name: 'Metamask',
        subtitle: 'Connect to the Metamask browser extension',
        id: 'injected',
        logo: MetamaskLogo,
    },
    {
        name: 'WalletConnect',
        subtitle: 'Connect to Trustwallet or 70+ other wallets via QR code',
        id: 'walletconnect',
        logo: WalletConnectLogo,
        type: 'qrcode',
    },
    // {
    //     name: 'Portis',
    //     subtitle: 'Connect by logging into your Portis account',
    //     id: 'portis',
    //     logo: PortisLogo,
    // },
    // {
    //     name: 'Fortmatic',
    //     subtitle: 'Connect using a phone number or email address',
    //     id: 'fortmatic',
    //     logo: FortmaticLogo,
    // },
    // {
    //     name: 'Frame',
    //     id: 'frame',
    //     logo: FrameLogo,
    // },
];

export default providers;
