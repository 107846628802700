import Contract from './contract';
import { store } from 'redux/config';
import { DEFAULT_GAS_PRICE } from 'utils/variables';
import { addTx, completedTx } from 'redux/actions/transactions';

const dispatch = store.dispatch;

class Utility extends Contract {
    async getInfo() {
        const [nameCost, splitCost] = await Promise.all([
            Contract.contracts.StakeUtilities.methods.nameCost().call(),
            Contract.contracts.StakeUtilities.methods.splitCost().call(),
        ])

        return {
            nameCost: nameCost / 1e18,
            splitCost: splitCost / 1e18,
        };
    }

    /**
     * Change the name of a stake.
     *
     * @param {string} stakeID - The ID of the stake to rename
     * @param {string} name - The new name of the stake
     * @param {object} costs - An object that contains all utility pricing
     */
    async rename(stakeID, name, costs) {
        const gasPrice = await this.getGasPrice();

        let value = costs['nameCost'];
        if (value > 0) value *= 1e18;

        return Contract.contracts.StakeUtilities.methods
            .nameStake(name, stakeID)
            .send({ from: Contract.account, value, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Rename Stake' })));
    }

    /**
     * Split a stake by a percentage
     *
     * @param {string} stakeID - The ID of the stake to rename
     * @param {number} percent - The percent to split by.
     * @param {object} costs - An object that contains all utility pricing
     */
    async split(stakeID, percent, costs) {
        const gasPrice = await this.getGasPrice();

        let value = costs['splitCost'];
        if (value > 0) value *= 1e18;

        return Contract.contracts.StakeUtilities.methods
            .splitStake(stakeID, percent)
            .send({ from: Contract.account, value, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: `Split Stake (${percent}/${100 - percent})` })));
    }
}

export default Utility;
