import { darkColors, darkPalette, NavigationBarHeight, supernovaColors, supernovaPalette } from './global-styles';
var colorManipulator = require('./utils/style');

const basics = (colors) => ({
    brand: colors,
    typography: {
        useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
        h1: {
            fontSize: 30,
            fontWeight: 'bold',
            color: colors.NormalText,
        },
        h2: {
            fontSize: 20,
            fontWeight: '500',
            color: colors.NormalText,
        },
        h3: {
            fontWeight: 500,
            fontSize: 18,
            color: colors.NormalText,
        },
        h4: {
            color: colors.NormalText,
            fontWeight: 500,
            fontSize: 16,
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 14,
            color: colors.SecondaryText,
        },
        h6: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 12,
            color: colors.SecondaryText,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: '400',
            color: colors.SecondaryText,
        },
        body1: {
            fontSize: 15,
            color: colors.SecondaryText,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                transition: 'all .3s ease',
                WebkitTransition: 'all .3s ease',
                MozTransition: 'all .3s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                },
            },
            text: {
                fontSize: 14,
                textTransform: 'none',
            },
        },
        MuiPaper: {},
        MuiInput: {},
        MuiInputBase: {},
        MuiDivider: {},
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                backgroundColor: colors.primary,
                color: colors._id === 'supernova' ? '#000' : colors.NormalText
            },
            arrow: {
                color: colors.primary,
                '&::before': {
                    backgroundColor: colors.primary,
                },
            },
        },
    },
    buttons: {
        outlined: {
            white: {
                padding: '9px 30px',
                color: colors.NormalText,
                border: `3px solid ${colors.border}`,
                '&:hover': {
                    border: `3px solid ${colors.border}`,
                    filter: 'saturate(150%)',
                    backgroundColor: 'rgba(255,255,255, 0.2)',
                    background: 'rgba(255,255,255, 0.2)',
                },
                '& svg.MuiCircularProgress-svg': {
                    color: '#fff',
                },
            },
        },
        contained: {
            translucent: {
                backgroundColor: colors.translucent,
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: '0 10px',
                borderColor: colors.translucentBorder,
                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.translucent, 0.3),
                },
            },
            white: {
                color: '#000',
                backgroundColor: '#fff',

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)('#fff', 0.3),
                },
            },
            primary: {
                color: 'white',
                backgroundColor: colors.axion,

                transition: 'all .5s ease',
                WebkitTransition: 'all .5s ease',
                MozTransition: 'all .5s ease',
                '&:hover': {
                    backgroundColor: colors.RoyalBlue,
                    transform: 'scale(1.02)',
                },
            },
        },
    },
});

export const darkTheme = {
    mode: 'dark',
    ...basics(darkColors),
    mixins: {
        bar: {
            height: NavigationBarHeight,
        },
    },
    palette: {
        ...darkPalette,
        type: 'dark',
    },

    // shadows: ['none'],
    _shadows: {
        card: '#00000033 5px 5px 10px',
        nav: '',
        bottom: '',
        bar: '',
        icon: '0 .18rem .3rem rgba(30,30,45,.5),0 .326rem 3rem rgba(20,20,30,.3)!important',
    },
    _gradients: {
        bottomList: 'linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 80%)',
        topList: 'linear-gradient(180deg,#fff 20%,hsla(0,0%,100%,0))',
        blueTeal: 'linear-gradient(152deg,#025cac,#02a4e4)!important',
        dark: 'linear-gradient(60deg,#29323c,#485563)!important',
        uniswap: 'linear-gradient(124deg,#9d005d,#1c1464)!important',
        header: 'linear-gradient(270deg,#4B79A1,#283E51)!important',
        acceleratorCard: `linear-gradient(152deg, ${darkColors.primary} 15%, ${darkColors.accent} 100%)`

    },
};

export const supernovaTheme = {
    mode: 'supernova',
    ...basics(supernovaColors),
    mixins: {
        bar: {
            height: NavigationBarHeight,
        },
    },
    palette: {
        ...supernovaPalette,
        type: 'dark',
    },

    // shadows: ['none'],
    _shadows: {
        card: '#00000033 5px 5px 10px',
        nav: '',
        bottom: '',
        bar: '',
        icon: '0 .18rem .3rem rgba(30,30,45,.5),0 .326rem 3rem rgba(20,20,30,.3)!important',
    },
    _gradients: {
        bottomList: 'linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 80%)',
        topList: 'linear-gradient(180deg,#fff 20%,hsla(0,0%,100%,0))',
        blueTeal: 'linear-gradient(152deg,#025cac,#02a4e4)!important',
        dark: 'linear-gradient(60deg,#29323c,#485563)!important',
        uniswap: 'linear-gradient(124deg,#9d005d,#1c1464)!important',
        header: 'linear-gradient(270deg,#4B79A1,#283E51)!important',
        acceleratorCard: `linear-gradient(152deg, ${supernovaColors.accent} 15%, ${supernovaColors.primary}7e 100%)`
    },
};

export const themes = [
    { id: 'dark', name: "Axion" },
    { id: 'supernova', name: "Supernova" },
]

export default darkTheme;
