import React, { useMemo } from 'react';
import useStyles from './styles';
import { Typography, Grid, Slider } from '@material-ui/core';

const SplitStake = ({ data, percent, setPercent }) => {
    const classes = useStyles();

    const splitNumbers = useMemo(() => {
        let principal = 0;
        let shares =0
        if (data?.stake) {
            shares = data.stake.shares;
            principal = data.stake.principal;
        };

        const principalA = principal - (principal * (percent / 100));
        const principalB = principal - principalA;

        const sharesA = shares - (shares * (percent / 100));
        const sharesB = shares - sharesA;

        return { 
            stakeA: {
                principal: principalA,
                shares: sharesA
            }, 
            stakeB: {
                principal: principalB,
                shares: sharesB
            }
        }
    }, [data, percent])

    return (
        <div>
            <Typography>
                Split your stake into two.
                Your principal and shares will be split based on the percentage you choose.
                The length of the stake will remain the same.
            </Typography>
            <br />

            <Typography variant="h4" align="center">
                {percent}%
            </Typography>
            <Typography variant="h4" align="center">
                Split Percentage
            </Typography>

            <div>
                <center>
                    <Slider 
                        max={99} 
                        min={1} 
                        value={percent} 
                        className={classes.slider}
                        onChange={(_, val) => setPercent(val)} 
                        marks={[
                            { value: 10, label: <Typography variant="h6">10%</Typography> },
                            { value: 50, label: <Typography variant="h6">50%</Typography> },
                            { value: 90, label: <Typography variant="h6">90%</Typography> },
                        ]}
                    />
                </center>
            </div>
           
            <Grid container justify="space-evenly">
                <Grid item>
                    <Typography variant="h4" align="center">Stake A</Typography>
                    <Typography>Principal: {splitNumbers.stakeA.principal.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                    <Typography>Shares: {splitNumbers.stakeA.shares.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" align="center">Stake B</Typography>
                    <Typography>Principal: {splitNumbers.stakeB.principal.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                    <Typography>Shares: {splitNumbers.stakeB.shares.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default SplitStake;
