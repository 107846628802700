import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

// Modules
import { history } from './redux/config';
import { UseWalletProvider } from 'use-wallet';
import { getIntercomID } from './utils/intercom';
import { useIntercom } from 'react-use-intercom';
import ContractProvider from './service/provider';
import { Widget } from '@maticnetwork/wallet-widget';
import { useDispatch, useSelector } from 'react-redux';
import { get as getEvents } from 'redux/actions/events';
import { get as getLaunches } from 'redux/actions/launches';
import { CONNECTOR_SETTINGS, WIDGET } from 'utils/variables';
import { get as getAccelerator } from 'redux/actions/accelerator';

// Containers
import Buy from './containers/Buy';
import Tools from './containers/Tools';
import Stake from './containers/Stake';
import Pledge from 'containers/Pledge';
import Voting from './containers/Voting';
import Bridge from './containers/Bridge';
import Launch from './containers/Launch';
import Vesting from './containers/Vesting';
import Collider from './containers/Collider';
import Verify from './containers/VerifyWallet';
import Dashboard from './containers/Dashboard';
import Accelerator from 'containers/Accelerator';
import NFTCollection from './containers/NFTCollection';

// Components
import Snackbar from 'components/Snackbar';
import Notification from 'components/Notification';
import AlertBar from 'components/AlertBar';
import Navigation from 'components/Navigation';
import ScrollToTop from 'components/ScrollToTop';
import CookieConsent from './components/CookieConsent';
import RedirectRoute from './components/util/RedirectRoute';
import RouteAnalytics from './components/util/RouteAnalytics';
import { requestChainSwitchETH } from 'utils/add-to-metamask';

const widget = new Widget(WIDGET);
const routes = [
    { path: '/buy', component: Buy },
    { path: '/', component: Dashboard },
    { path: '/stake', component: Stake },
    { path: '/tools', component: Tools },
    // { path: '/bridge', component: Bridge },
    { path: '/voting', component: Voting },
    { path: '/launch', component: Launch },
    { path: '/verify', component: Verify },
    { path: '/pledge', component: Pledge },
    { path: '/vote/:id', component: Voting },
    { path: '/vesting', component: Vesting },
    { path: '/nft', component: NFTCollection },
    { path: '/collider', component: Collider },
    { path: '/accelerator/:token?', component: Accelerator },
];

const _Router = () => {
    const { boot } = useIntercom();
    const dispatch = useDispatch();
    const { chain } = useSelector((store) => store);
    const [widgetCreated, setWidgetCreated] = useState(false);

    const _getEvents = () => {
        dispatch(getEvents());
    };
    const _getLaunches = () => {
        dispatch(getLaunches());
    };
    const _getAccelerator = () => {
        dispatch(getAccelerator());
    };

    const _setupBridgeWidget = async () => {
        await widget.create();
        setWidgetCreated(true);
        widget.on('approveInit', requestChainSwitchETH);
        widget.on('depositInit', requestChainSwitchETH);
        widget.on('load', () => {
            setTimeout(() => {
                const shadow = document.getElementsByClassName('matic-widget__iframe')[0];
                if (shadow?.shadowRoot) {
                    shadow.shadowRoot.styleSheets[1].insertRule(
                        '.b-polyglon-home_feature__tabs { display: none !important; }'
                    );
                    shadow.shadowRoot.styleSheets[1].insertRule(
                        '.b-polyglon-home_feature__message { padding-top: 2% !important; }'
                    );
                    shadow.shadowRoot.querySelectorAll('a')[0].href =
                        'https://support.axion.network';
                }
            }, 1000);
        });
    };

    useEffect(() => {
        _getEvents();
        _getLaunches();
        _getAccelerator();
        // _setupBridgeWidget();

        // Intercom
        const id = getIntercomID();
        boot({
            userId: id.random_id,
            name: id.random_name,
        });

        // Auto refresh data
        const eventInterval = setInterval(() => { _getEvents() }, 60000 * 5); // 5 mins
        const priceInterval = setInterval(() => { _getAccelerator() }, 60000); // 1 minute

        return () => {
            clearInterval(eventInterval);
            clearInterval(priceInterval);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <CookieConsent />
            <UseWalletProvider chainId={chain} connectors={{ ...CONNECTOR_SETTINGS }}>
                <ContractProvider>
                    <Router history={history}>
                        <Snackbar />
                        <AlertBar />
                        <Notification />
                        <RouteAnalytics />
                        <Navigation>
                            <Switch>
                                <ScrollToTop>
                                    {routes.map((r) => {
                                        return (
                                            <Route
                                                exact
                                                key={r.path}
                                                path={r.path}
                                                render={(props) => {
                                                    return (
                                                        <r.component
                                                            {...props}
                                                            widget={widget}
                                                            widgetCreated={widgetCreated}
                                                        />
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                    <RedirectRoute fromPath="/claim" toPath="/" />
                                    <RedirectRoute fromPath="/bridge" toPath="/" />
                                    <RedirectRoute fromPath="/staking" toPath="/stake" />
                                    <RedirectRoute fromPath="/auction" toPath="/accelerator" />
                                </ScrollToTop>
                            </Switch>
                        </Navigation>
                    </Router>
                </ContractProvider>
            </UseWalletProvider>
        </React.Fragment>
    );
};

export default _Router;
