import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        padding: `${theme.spacing(1.25)}px ${theme.spacing(3)}px`,
        borderBottom: `1px solid ${theme.brand.border}`,

        '&:last-child': {
            borderBottom: 'none',
        }
    },
    title: {
        fontWeight: 300,
        fontSize: '1.1rem',

        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    subtitle: {
        fontWeight: 300,
        fontSize: '0.7rem',
    },
    value: {
        fontWeight: 500,
        color: theme.brand.SecondaryText,
        fontSize: '1.1rem',
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    amount: {
        fontWeight: 300,
        textAlign: 'right',
        maxHeight: 20, // Fixes weird bug when amount = 0.
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8rem',
        },
    },
    divider: {
        borderBottom: `1px solid ${theme.brand.border}`,
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    divider2: {
        paddingBottom: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    green: {
        color: theme.brand.JavaGreen,
    },
    row: {
        margin: `${theme.spacing(0.5)}px 0`,
    },
    bonus: {
        fontSize: '0.9rem',
    },
    sup: {
        fontWeight: 300,
        fontSize: '0.7rem',
    },
    terms: {
        textAlign: 'right',
    },
    term: {
        fontWeight: 300,
        fontSize: '0.7rem',
        marginTop: theme.spacing(0.75) * -1,
    },
    pointer: {
        cursor: 'pointer',
    },
    colliderOrange: {
        color: theme.brand.colliderOrange,
    },
    white: {
        color: theme.brand.NormalText,
    },
    gold: {
        color: "#ffd700",
    },
    silver: {
        color: "#8c8c8c",
    }
}));

export default useStyles;
