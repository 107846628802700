import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    icon: {
        color: 'white',
        backgroundColor: theme.brand.primary,
        margin: theme.spacing(1),
    },
    supernovaGlow: {
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 200,
    },
    subtitle: {
        fontSize: '0.8rem',
        fontWeight: 300,
    },
    stat: {
        fontSize: '1.1rem',
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    link: {
        cursor: 'pointer',
    },
    substat: {
        textAlign: 'right',
        fontSize: '0.8rem',
    },
    titleContainer: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(4),
        },
    },
    statContainer: {
        marginRight: theme.spacing(2),
    },
    statRow: {
        padding: theme.spacing(0.2),
        borderBottom: `1px solid ${theme.brand.border}`,
        '&:last-child': {
            border: 'none',
        },
    },
    loading: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
}));

export default useStyles;
