import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
}));

export default useStyles;
