import React, { useContext, useState } from 'react';

import Web3 from 'web3';
import Card from '../Card';
import clsx from 'classnames';
import BN from 'bignumber.js';
import tokens from '../tokens';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import Flames from 'assets/images/flames.gif';
import UsdcIcon from 'assets/images/coinIcons/usdc.svg';
import Axion from 'assets/images/axion-logo.png';
import TypeSwitch from '../TypeSwitch/TypeSwitch';
import { Grid, Typography } from '@material-ui/core';
import { ETHEREUM_TOKEN, AXN_BURN_ADDRESS_LINK } from 'utils/variables';
import TextTransition, { presets } from 'react-text-transition';
import { ContractContext } from 'service/provider';

const AcceleratorStatistics = () => {
    const classes = useStyles();
    const [type, setType] = useState('currentAccelerator');

    const { contractInfo } = useContext(ContractContext)
    const { accelerator } = useSelector(store => store);

    const handleToggle = (mode) => {
        setType(mode === 'basic' ? 'currentAccelerator' : 'acceleratorAllTimes');
    };

    const renderAnimatedText = (text) => (
        <TextTransition
            inline
            text={text}
            pringConfig={presets.slow}
            direction={type === 'currentAccelerator' ? 'up' : 'down'}
        />
    );

    if (!accelerator || accelerator.loading || !accelerator?.stats) return null;
    return (
        <Card
            className={classes.card}
            title={<span>Accelerator Statistics</span>}
            titleAction={
                <TypeSwitch
                    onClick={(m) => handleToggle(m)}
                    basicText={'Today'}
                    advancedText={'All-time'}
                    active={type === 'currentAccelerator' ? 'basic' : 'advanced'}
                />
            }
        >
            <div className={classes.root}>
                <Grid
                    container
                    justify="space-around"
                    alignItems="center"
                    className={classes.boughtGrid}
                >
                    <Grid item xs={6} sm={4}>
                        <center>
                            <img src={Axion} alt="sold" className={classes.img} />

                            <Typography>Axion Sold</Typography>

                            <Typography className={classes.summarySub}>
                                {renderAnimatedText((accelerator.stats[type].payouts / 1e18).numberWithCommas(0))}
                            </Typography>

                            <Typography className={classes.summarySub}>
                                {renderAnimatedText(`$${(accelerator.stats[type].payouts / 1e18 * contractInfo?.axion?.usdPerAXN).numberWithCommas(0)}`)}
                            </Typography>
                        </center>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <div
                            className={classes.link}
                            onClick={() => window.open(AXN_BURN_ADDRESS_LINK)}
                        >
                            <center>
                                <img src={Flames} alt="flames" className={classes.img} />

                                <Typography>Axion Burned</Typography>

                                <Typography className={classes.summarySub}>
                                    {renderAnimatedText((accelerator.stats[type].axionBought / 1e18).numberWithCommas(0))}
                                </Typography>

                                <Typography className={classes.summarySub}>
                                    {renderAnimatedText(`$${(accelerator.stats[type].axionBought / 1e18 * contractInfo?.axion?.usdPerAXN).numberWithCommas(0)}`)}
                                </Typography>
                            </center>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <center>
                            <img src={UsdcIcon} alt="usdc" className={classes.img} />
                            <Typography>Added to OTC Pool</Typography>

                            <Typography className={classes.summarySub}>
                                {renderAnimatedText((type === "currentAccelerator" ? accelerator.stats[type].tokenBought / 1e6 : contractInfo?.accelerator?.otcUsdcAllTime).numberWithCommas(2))} USDC
                            </Typography>

                            <Typography className={classes.summarySub}>
                                ${renderAnimatedText((type === "currentAccelerator" ? accelerator.stats[type].tokenBought / 1e6 : contractInfo?.accelerator?.otcUsdcAllTime).numberWithCommas(2))}
                            </Typography>
                        </center>
                    </Grid>
                </Grid>
                {/* <Grid
                    container
                    alignItems="center"
                    justify="space-evenly"
                    className={classes.tokenGrid}
                >
                    {tokens.map((token) => {
                        const _address = Web3.utils.toChecksumAddress(token.address);
                        let totalSpent = '0';
                        if (_address === ETHEREUM_TOKEN.tokenAddress) {
                            totalSpent = new BN(accelerator.stats?.[type]?.totalEth ?? '0')
                                .div(1e18)
                                .toString();
                        } else {
                            if (type === 'acceleratorAllTimes') {
                                totalSpent = new BN(
                                    accelerator.stats?.[type]?.tokenTotals?.[_address] ?? '0'
                                )
                                    .div(`1e${token.decimals}`)
                                    .toString();
                            } else {
                                totalSpent = new BN(accelerator.stats?.[type]?.amounts?.[_address] ?? '0')
                                    .div(`1e${token.decimals}`)
                                    .toString();
                            }
                        }

                        return (
                            <Grid item xs={6} sm={4} lg={2} key={token.address}>
                                <div className={classes.token}>
                                    <img
                                        alt="token"
                                        className={classes.tokenImg}
                                        src={token.logoDark ?? token.logo}
                                    />
                                    <Typography className={classes.summaryTitle}>
                                        {token.name}
                                    </Typography>
                                    <Typography className={classes.summarySub}>
                                        {renderAnimatedText(
                                            `${totalSpent.numberWithCommas(
                                                token.decimalsToDisplay
                                            )}`
                                        )}
                                    </Typography>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid> */}
            </div>
        </Card>
    );
};

export default AcceleratorStatistics;
