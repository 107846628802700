import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: 30,
        borderBottom: `1px solid ${theme.brand.border}`,
        marginBottom: theme.spacing(2),
    },

    paper: {
        backgroundColor: theme.brand.CardBG,
    },
    hidden: {
        display: 'none',
    },
    actionBtn: {
        marginRight: theme.spacing(0.5),
    },

    warningTitle: {
        fontSize: 18,
        color: theme.brand.error,
    },
    dialogInformation: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    actionDialogPaper: {
        maxWidth: 'calc(100% - 16px) !important',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: 85,
        },
    },
    cardTitle: {
        padding: 0,
        paddingTop: 24,
    },
    confetti: {
        textAlign: 'center',
    },
    verticalAlign: {
        alignItems: 'center',
    },
    icon: {
        cursor: 'pointer',
        width: 25,
    },
    iconContainer: {
        display: 'flex',
        minWidth: 60,
        justifyContent: 'space-between',
    },
    availableExplain: {
        fontSize: '1rem',
        cursor: 'pointer',
    },
}));

export default useStyles;
