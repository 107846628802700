import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    voteContainer: {
        display: 'flex',
        minHeight: '50px',
        flexDirection: 'row',
        justifyContent: 'space-evenly',

        '& > div:last-child': {
            border: 'none',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    voteOption: {
        display: 'flex',
        flexWrap: 'no-wrap',
        alignItems: 'center',
        justifyContent: 'center',

        width: '100%',
        minHeight: '50px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.mode === 'dark' ? 'rgba(0,0,0,0.15)' : 'rgba(244,245,253 ,0.15)',

        borderRight: `1px solid ${
            theme.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
        }`,

        '&:hover': {
            backgroundColor: theme.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(244,245,253, 1)',
        },

        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${
                theme.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
            }`,
        },
    },
    disabled: {
        opacity: '0.3',
        cursor: 'not-allowed',
        backgroundColor: 'rgba(0,0,0,1)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,1)',
        },
    },
    selected: {
        cursor: 'not-allowed',
        backgroundColor: theme.mode === 'dark' ? theme.brand.NavigationBackground : '#eeeeee',
        '&:hover': {
            backgroundColor: theme.mode === 'dark' ? theme.brand.NavigationBackground : '#eeeeee',
        },
    },
    multiVoteContainer: {
        textAlign: 'center',
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.brand.border}`,
    },
}));

export default useStyles;
