import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Use
import { useTheme } from '@material-ui/core';
import useStyles from './styles';
import { useHistory } from 'react-router';

export default function MediaCard({
    href,
    internal,
    title,
    description,
    lightImage,
    darkImage,
    ...props
}) {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles(props);

    const handleClick = () => {
        if (internal) history.push(href);
        else window.open(href);
    };

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} className={classes.actionArea}>
                <CardMedia
                    className={classes.media}
                    image={theme.mode === 'dark' ? darkImage : lightImage}
                />
                <CardContent classes={{ root: classes.content }}>
                    <Typography gutterBottom variant="h5" color="textPrimary">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button onClick={handleClick} size="small" color="primary">
                    Visit
                </Button>
            </CardActions>
        </Card>
    );
}
