import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {},
    table: {},
    header: {
        padding: 30,
        borderBottom: `1px solid ${theme.brand.border}`,
        marginBottom: theme.spacing(2),
    },
    tabs: {
        width: '100%',
    },
    paper: {
        backgroundColor: theme.brand.CardBG,
    },
    hidden: {
        display: 'none',
    },
    actionBtn: {
        marginRight: theme.spacing(0.5),
    },
    name: {
        display: 'flex',
        alignItems: 'center',
    }, 
    warningTitle: {
        fontSize: 18,
        color: theme.brand.error,
    },
    dialogInformation: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    actionDialogPaper: {
        maxWidth: 'calc(100% - 16px) !important',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: 85,
        },
    },
    cardTitle: {
        padding: 0,
        paddingTop: 24,
    },
    confetti: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    verticalAlign: {
        alignItems: 'center',
    },
}));

export default useStyles;
