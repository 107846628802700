import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    slider: {
        width: '90% !important',
        margin: `${theme.spacing(2)}px 0`
    },
    textboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: `${theme.spacing(4)}px 0`
    },
    textbox: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '90%'
        }
    },
    max: {
        opacity: 0.5,
        fontSize: '0.8rem',
    }
}));

export default useStyles;
