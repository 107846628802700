import React from 'react';

import useStyles from './styles';
import Page from 'components/Page';
import PageTitle from './PageTitle';
import { Grid } from '@material-ui/core';
import ToolsCard from 'components/ToolsCard';
import Meta from '../../components/util/Meta';
import Background from 'components/Background/Background';

// Images
import Voting from 'assets/images/voting.jpeg';
import AxionCalc from 'assets/images/calc.png';
import AxionSupport from 'assets/images/AxionSupportTool.jpg';
import AxionStatsDark from 'assets/images/axion-stats-dark.png';
import AxionTodayDark from 'assets/images/axion-today-dark.png';
import AxionStatsLight from 'assets/images/axion-stats-light.png';
import AxionTodayLight from 'assets/images/axion-today-light.png';


const COMMUNITY_TOOLS = [
    {
        title: 'Axion Today',
        darkImage: AxionTodayDark,
        lightImage: AxionTodayLight,
        href: 'https://axion.today',
        description: 'Interact with the latest Axion stats and updates on your computer or phone!',
    }
];

const OFFICIAL_TOOLS = [
    {
        title: 'Support & Knowledge Base',
        darkImage: AxionSupport,
        lightImage: AxionSupport,
        href: 'https://support.axion.network/en/',
        description: "Get all the information you need with Axion's in-depth knowledge base, featuring articles and breakdowns of all of Axion's core functionalities. Our support team is ready to assist via our intuitive live chat system.",
    },
    {
        title: 'Axion Calculator',
        darkImage: AxionCalc,
        lightImage: AxionCalc,
        href: 'https://axion.network/how-to-buy-axion/accelerator#calc',
        description: 'The Axion calculator helps you estimate how much interest and liquid rewards you could earn when staking Axion. Includes a price slider so you can see just how much you could be earning in the future!',
    },
    {
        title: 'Voting',
        darkImage: Voting,
        lightImage: Voting,
        internal: true,
        href: '/voting',
        description: 'Gives Axion shareholders a chance to vote on prominent changes to the Axion ecosystem, as long as the shareholder has at least one stake of 1M+ AXN for 1825+ days.',
    }
];

const Tools = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Meta title="Axion | Tools" />
            <Background />
            <Page classes={{ root: classes.page }} header={<PageTitle type="official" />}>
                <Grid container spacing={4}>
                    {OFFICIAL_TOOLS.map((t, i) => (
                        <Grid key={i} item xs={12} md={6} classes={{ root: classes.item }}>
                            <ToolsCard
                                href={t.href}
                                title={t.title}
                                internal={t.internal}
                                darkImage={t.darkImage}
                                lightImage={t.lightImage}
                                description={t.description}
                                classes={{ root: classes.card }}
                            />
                        </Grid>
                    ))}
                </Grid>

                <br />

                <PageTitle type="community" />
                <Grid container spacing={4}>
                    {COMMUNITY_TOOLS.map((t, i) => (
                        <Grid key={i} item xs={12} md={6} classes={{ root: classes.item }}>
                            <ToolsCard
                                href={t.href}
                                title={t.title}
                                darkImage={t.darkImage}
                                lightImage={t.lightImage}
                                description={t.description}
                                classes={{ root: classes.card }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Page>
        </React.Fragment>
    );
};

export default Tools;
