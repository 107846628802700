const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: theme.spacing(2),
        },
    },
    donut: {
        marginRight: 12,
        borderRadius: '50%',
    },
    stats: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    },
    stat: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '34%',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },

    /** Margins */
    mr: {
        marginRight: theme.spacing(2),
    },
});

export default styles;
