import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    paper: {
        borderRadius: 15,
        overflow: 'hidden',
        backgroundColor: theme.brand.CardBG,
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
    },
    icon: {
        fontSize: '1.25rem',
        color: theme.brand.NormalText,
        marginRight: theme.spacing(1),
    },
    art: {
        padding: 0,
        marginBottom: -5,
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    contentContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'space-between'
    },
    descriptionContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    descriptionText: {
        fontSize: '0.875rem',
    },
    traitContainer: {
        padding: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    close: {
        padding: 3
    },
    trait: {
        borderRadius: 10,
        textAlign: 'center',
        padding: theme.spacing(1),
        backgroundColor: theme.brand.CardBG,
        border: `1px solid ${ theme.brand.border}`,
    },
    traitValue: {
        fontWeight: 500
    },
    traitType: {
        fontWeight: 300
    },
    traitTitle: {
        display: 'flex',
        margin: theme.spacing(2, 0),
    },
    actionButtonContainer: {
        gap: 2,
        width: '100%',
        display: 'flex',
        minHeight: '50px',
        flexDirection: 'row',
        marginTop: theme.spacing(2),
        justifyContent: 'space-evenly',
    },
    actionButton: {
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
        flexWrap: 'no-wrap',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.brand.border,
        '&:hover': {
            filter: 'brightness(75%)'
        },
    },
    traitCard: {
        cursor: 'pointer',

        '&:hover': {
            filter: 'brightness(85%)'
        }
    },
    active: {
        filter: 'brightness(85%)'
    }
}));

export default useStyles;
