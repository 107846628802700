const barHeight = 24;
const dotSize = 8;

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height: 160,

        padding: '40px 20px',

        '@media (max-width: 860px)': {
            display: 'none',
        },
    },
    bar: {
        display: 'flex',
        flex: 1,

        height: barHeight * 3,

        marginTop: 46,
    },
    segment: {
        borderTopRightRadius: barHeight / 2,
        borderBottomRightRadius: barHeight / 2,

        position: 'relative',

        height: barHeight,

        flex: 1,
    },
    up: {
        borderBottomLeftRadius: barHeight / 2,
    },
    down: {
        borderTopLeftRadius: barHeight / 2,
    },
    line: {
        height: 25,
        width: 1,
        left: 0,
    },
    lineDown: {
        bottom: 0,
        marginTop: barHeight,
    },
    lineUp: {
        top: 0,
        marginTop: -barHeight,
    },
    dotContainer: {
        display: 'flex',
        alignItems: 'center',

        position: 'relative',
    },
    dot: {
        height: dotSize,
        width: dotSize,
        borderRadius: dotSize / 2,

        marginLeft: -(dotSize / 2) + 1,
    },
    dotBottom: {
        marginTop: -(dotSize / 2),
    },
    dotTop: {
        marginTop: -(dotSize + barHeight),
    },
    bpd: {
        position: 'absolute',
        marginLeft: 12,

        fontSize: 12,
        fontWeight: 500,
    },
    info: {
        position: 'absolute',
    },
    infoBottom: {
        top: 12,
    },
    infoTop: {
        bottom: 12,
    },
    infoLine: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    axion: {
        color: theme.brand.SecondaryText,

        fontSize: 17,
        fontWeight: 500,

        lineHeight: 1.2,
    },
    axionLabel: {
        fontSize: '0.75rem',
    },
    smallLabel: {
        color: theme.brand.NormalText,

        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',

        lineHeight: 1.2,
        whiteSpace: 'nowrap',
    },
    bigPayDay: {
        position: 'absolute',
        bottom: barHeight,
        right: 0,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    bigPayDayAmt: {
        color: theme.brand.SecondaryText,
        fontSize: 30,
        fontWeight: 500,
        lineHeight: 1.2,
    },
    paid: {
        color: theme.brand.JavaGreen
    },
    check: {
        fontSize: '0.8rem',
        marginLeft: theme.spacing(0.5)
    }
});

export default styles;
