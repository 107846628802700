import React from 'react';

import clsx from 'classnames';
import useStyles from './styles';

const Card = ({ children, glossy = true, button, ...props }) => {
    const classes = useStyles(props);

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.glossy]: glossy,
                [classes.glossyButton]: glossy && button,
            })}
            {...props}
        >
            {children}
        </div>
    );
};

export default Card;
