import React, { useContext, useState } from 'react';

// Components
import Page from 'components/Page';
import Button from 'components/Button';
import PageTitle from './PageTitle/PageTitle';
import Meta from '../../components/util/Meta';

// Modules
import useStyles from './styles';
import Background from 'components/Background/Background';
import { ContractContext } from 'service/provider';
import axios from 'axios';
import { base, routes } from 'api/requests';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';

const VerifyWallet = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [pin, setPin] = useState('');
    const { web3, wallet, user } = useContext(ContractContext);
    
    const signMessage = async () => {
        const msg = `Sign this message to confirm ownership of this wallet.\nPin: ${pin}`;

        try {
            const signature = await web3.currentProvider.request({
                method: 'personal_sign',
                params: [msg, wallet.account],
                from: wallet.account,
            });

            try {
                await axios.post(base + routes.postHacked, { pin: msg, signature });
                dispatch(setSnackbar({ message: 'Success!', type: 'success' }));

                // POST failed
            } catch (e) {
                dispatch(setSnackbar({ message: e.message }));
                console.error(e);
            }

            // Signiture Failed
        } catch (err) {
            dispatch(setSnackbar({ message: err.message }));
        }
    };

    const set = e => {
        const val = e.target.value;

        if(val.length < 10)
            setPin(val)
    }

    return (
        <React.Fragment>
            <Background />
            <Meta title={`Axion | Verify Your Wallet`} />
            <Page classes={{ inner: classes.inner }} header={<PageTitle />}>

                <div className={classes.main} >
                    <TextField fullWidth label="Verification Pin" variant="outlined" margin="dense" value={pin} onChange={set}/>
                    <br />
                    <Button fullWidth variant="outlined" onClick={signMessage} disabled={!pin.length || !user}>
                        Verify Your Wallet
                    </Button>
                </div>
            </Page>
        </React.Fragment>
    );
};

export default VerifyWallet;
