import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import Page from 'components/Page';
import Meta from 'components/util/Meta';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ProposalDetail from './ProposalDetail';
import { useHistory } from 'react-router-dom';
import PageTitle from './PageTitle/PageTitle';
import { ContractContext } from 'service/provider';
import ProposalOverview from './ProposalOverview';
import { setSnackbar } from 'redux/actions/snackbar';
import Background from 'components/Background/Background';
import { getProposals, getVotes, getVotesFromCache } from 'utils/voting';

const Voting = ({ match }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [id, setId] = useState(null);
    const { contracts } = useContext(ContractContext);
    const [proposals, setProposals] = useState([]);
    const [voteData, setVoteData] = useState({ valid: false, done: false });

    const _getVotes = async (pid, data = proposals) => {
        setId(pid);
        const proposal = data.find((p) => p.id === pid);
        let votesByAddress = getVotesFromCache(pid);

        // Vote started AFTER move to Polygon
        if (proposal.start > 1632866400) {
            const voteData = await getVotes(pid, proposal, contracts);
            votesByAddress = voteData;
        }

        setVoteData(votesByAddress);
    };

    useEffect(() => {
        const _getData = async () => {
            const result = await getProposals();
            setProposals(result);

            if (result.find((p) => p.id === match.params.id)) {
                _getVotes(match.params.id, result);
            } else if (match.params.id) {
                dispatch(
                    setSnackbar({ message: `Proposal not found. Redirected to all proposals.` })
                );
                history.push('/voting');
            }
        };

        _getData();

        // eslint-disable-next-line
    }, [contracts]);

    return (
        <React.Fragment>
            <Background />
            <Meta title="Axion | Voting" />
            <Page classes={{ inner: classes.inner }} header={!id && <PageTitle />}>
                <br />
                {!id && (
                    <Grid container spacing={2}>
                        {proposals.map((p) => (
                            <Grid item md={6} xs={12} key={p.id}>
                                <ProposalOverview
                                    key={p.id}
                                    proposal={p}
                                    viewDetail={() => _getVotes(p.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}

                {id && proposals && (
                    <ProposalDetail
                        voteData={voteData}
                        proposal={proposals.find((p) => p.id === id)}
                        refetchVotes={() => _getVotes(match.params.id, proposals, contracts)}
                    />
                )}
            </Page>
        </React.Fragment>
    );
};

export default Voting;
