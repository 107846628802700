import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    slider: {
        width: '90% !important',
        margin: `${theme.spacing(2)}px 0`
    }
}));

export default useStyles;
