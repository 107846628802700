import { makeStyles } from '@material-ui/core/styles';
import bgImg from 'assets/images/bg4.jpeg';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    particles: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        userSelect: 'none',
        zIndex: -1,

        '&:before': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            backgroundSize: 'cover',
            // backgroundPosition: 'bottom',
            backgroundImage: `url(${bgImg})`,
            opacity: theme.mode === 'dark' ? 0.2 : 0,
        },
    },
}));

export default useStyles;
