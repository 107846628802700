import web3 from 'web3';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import words from 'assets/data/random_words.json';
import { HAS_VOTED_URL } from '../utils/variables';

const KEY = 'intercom-id';
export const getIntercomID = () => {
    let id = localStorage.getItem(KEY);
    if (!id) {
        id = {
            random_id: uuidv4(),
            random_name: getRandomName(),
        };
        localStorage.setItem(KEY, JSON.stringify(id));
        return id;
    }

    return JSON.parse(id);
};

const capFirst = (s) => s.charAt(0).toUpperCase() + s.slice(1);
const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const getRandomName = () => {
    const word1 = words.adjectives[getRandomInt(0, words.adjectives.length + 1)];
    const word2 = words.nouns[getRandomInt(0, words.nouns.length + 1)];
    return `${capFirst(word1)} ${capFirst(word2)}`;
};

export const hasVoted = async (addr, update) => {
    try {
        const response = await axios.get(
            `${HAS_VOTED_URL}?address=${web3.utils.toChecksumAddress(addr)}`
        );
        update({ customAttributes: { has_voted: response.data.voted } });
    } catch (err) {
        update({ customAttributes: { has_voted: null } });
    }
};
