import React, { useState, useContext, useRef, useMemo } from 'react';

// Modules
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import getStakeInfo from 'utils/get-stake-info';
import { ContractContext } from 'service/provider';
import { setSnackbar } from 'redux/actions/snackbar';
import { trackStakeEvent, trackError } from 'utils/analytics';
import { STAKE_TYPES, AVAILABLE_DAYS_AFTER_END, CONFETTI_SETTINGS, SECONDS_IN_DAY } from 'utils/variables';

// Components
import Card from 'components/Card';
import ActiveTable from './Active';
import Reward from 'react-rewards';
import HistoryTable from './History';
import { Tabs, Tab } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardTitle from 'components/Card/CardTitle';
import EditModal from 'components/Modals/EditModal';
import ActionsModal from 'components/Modals/ActionsModal';
import EarlyLateStakeModal from 'components/Modals/EarlyLateStakeModal';

const StakeTables = (props) => {
    const rewardRef = useRef();
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { web3, user, library, getWalletDetails, contractInfo } = useContext(ContractContext);

    const [tab, setTab] = useState(0);
    const [edit, setEdit] = useState(null);
    const [actions, setActions] = useState(null);
    const [withdraw, setWithdraw] = useState(null);

    const tableKeys = useMemo(() => {
        if (user)
            return Object.values(STAKE_TYPES).filter((key) => user.sortedSessions[key]?.length > 0);
        else return [];
    }, [user]);

    const handleWithdraw = async (session) => {
        try {
            await library.Staking.withdraw(session.id);
            getWalletDetails();
            trackStakeEvent({ 
                amount: session.principal, 
                days: session.stakeDays, 
                type: 'withdraw' 
            });
        } catch (error) {
            dispatch(setSnackbar({ message: error.message }));
            trackError({ type: 'withdraw', description: error.message });
        }
    };

    const handleShowWithdrawDialog = async (session) => {
        const { payout, penalty, isLate, isEarly } = await getSessionDetails(session);

        if (isLate || isEarly) {
            const time = `${isEarly ? 'Early ' : 'Late '} Unstake`;
            setWithdraw({ payout, penalty, session, open: true, type: time });
            return;
        }
        return handleWithdraw(session);
    }

    const getSessionDetails = async (session) => {
        const result = await library.Staking.getStakePayoutAndPenalty(session);
        const payout = web3.utils.fromWei(result[0]);
        const penalty = web3.utils.fromWei(result[1]);

        const endMS = session.end.getTime();
        const earlyPenaltyWindow = endMS;
        const latePenaltyWindow = endMS + AVAILABLE_DAYS_AFTER_END * SECONDS_IN_DAY * 1000;

        const isLate = Date.now() > latePenaltyWindow;
        const isEarly = Date.now() < earlyPenaltyWindow;

        return { payout, penalty, endMS, earlyPenaltyWindow, latePenaltyWindow, isLate, isEarly };
    };

    const getMaxShareStakeInfo = stake => {
        const amount = Number(stake.principal) + stake.interest;
        const shareRate = contractInfo.staking.shareRate / 1e18;
        return getStakeInfo(amount, 5555, shareRate);
    }

    const handleOpenActions = async (stake) => {
        const { payout, penalty, isLate } = await getSessionDetails(stake);
        const s = { ...stake, payout, penalty };
        const stakeInfo = getMaxShareStakeInfo(stake);
        setActions({ open: true, payout, penalty, isLate, stake: s, ...stakeInfo });
    }

    const handleOpenEdit = (stake) => {
        const stakeInfo = getMaxShareStakeInfo(stake);
        setEdit({ 
            stake, 
            open: true, 
            ...stakeInfo,
            amount: Number(stake.principal) + stake.interest, 
            shareRate: contractInfo.staking.shareRate / 1e18, 
        });
    }

    return (
        <div className={classes.root}>
            <Card>
                <div className={classes.confetti}>
                    <Reward ref={rewardRef} type="confetti" config={{ ...CONFETTI_SETTINGS }}>
                        <></>
                    </Reward>
                </div>

                <CardTitle classes={{ root: classes.cardTitle }}>
                    <Tabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        classes={{ root: classes.tabs }}
                    >
                        {tableKeys.map((key) => (
                            <Tab
                                key={key}
                                label={`${key} (${user?.sortedSessions[key]?.length})`}
                            />
                        ))}
                    </Tabs>
                </CardTitle>

                <CardBody>
                    <ActiveTable
                        onEdit={(stake) => handleOpenEdit(stake)}
                        onWithdraw={(stake) => handleShowWithdrawDialog(stake)}
                        data={user?.sortedSessions?.[STAKE_TYPES.ACTIVE_STAKES] ?? []}
                        classes={{ table: tableKeys[tab] !== STAKE_TYPES.ACTIVE_STAKES && classes.hidden }}
                    />
                    <ActiveTable
                        onEdit={(stake) => handleOpenEdit(stake)}
                        onActions={(stake) => handleOpenActions(stake)}
                        data={user?.sortedSessions?.[STAKE_TYPES.MATURED_STAKES] ?? []}
                        classes={{ table: tableKeys[tab] !== STAKE_TYPES.MATURED_STAKES && classes.hidden }}
                    />
                    <HistoryTable
                        title="Stake History"
                        data={user?.sortedSessions?.[STAKE_TYPES.COMPLETED_STAKES] ?? []}
                        classes={{ table: tableKeys[tab] !== STAKE_TYPES.COMPLETED_STAKES && classes.hidden }}
                    />
                </CardBody>
            </Card>

            <EarlyLateStakeModal
                data={withdraw}
                open={withdraw?.open}
                onWithdraw={handleWithdraw}
                onClose={() => setWithdraw({ ...withdraw, open: false })}
            />

            <EditModal 
                data={edit}
                open={edit?.open}
                onClose={() => setEdit({ ...edit, open: false })}
            />

            <ActionsModal
                data={actions}
                open={actions?.open}
                onClose={() => setActions({ ...actions, open: false })}
            />
        </div>
    );
};

export default StakeTables;
