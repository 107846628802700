import { makeStyles } from '@material-ui/core/styles';
import font from 'assets/fonts/Retro-Computer.ttf.woff';

const useStyles = makeStyles((theme) => ({
    colliderEngagedText: {
        fontSize: '1.4rem',
        textAlign: 'center',
        letterSpacing: '0.15rem',
        fontFamily: `Retro Computer`,
        color: theme.brand.colliderOrange,
    },
    typeWriterContainer: {
        fontWeight: 300,
        fontSize: '1rem',
        letterSpacing: '0.2em',
        marginTop: theme.spacing(2),
    },
    '@font-face': {
        fontFamily: 'Retro Computer',
        src: `url(${font})`,
    }
}));

export default useStyles;
