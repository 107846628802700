import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';

const CardTitle = ({ children, row, column = true, ...props }) => {
    const classes = useStyles(props);

    const rootClasses = clsx(classes.root, {
        [classes.row]: row,
        [classes.column]: column,
    });

    return (
        <div className={rootClasses} {...props}>
            {children}
        </div>
    );
};

export default CardTitle;
