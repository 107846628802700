import ABI from '../abi.json';
import Contract from './contract';
import { store } from 'redux/config';
import { MaxUint256 } from '@ethersproject/constants';
import { DEFAULT_GAS_PRICE, ETHEREUM_TOKEN, ZERO_ADDRESS } from 'utils/variables';
import { addTx, completedTx } from 'redux/actions/transactions';
const dispatch = store.dispatch;

class Staking extends Contract {
    async getInfo() {
        const [minStakeAmount, interestFields, maxDays, active] = await Promise.all([
            Contract.contracts.StakeMinter.methods.minimumStakeAmount().call(),
            Contract.contracts.StakeManager.methods.getInterestFields().call(),
            Contract.contracts.StakeUpgrader.methods.getMaxShareMaxDays().call(),
            Contract.contracts.StakeUpgrader.methods.getMaxShareEventActive().call(),
        ]);

        return {
            shareRate: interestFields.shareRate,
            maxShare: { active, maxDays: parseInt(maxDays) },
            minStakeAmount: +Contract.web3.utils.fromWei(minStakeAmount),
        };
    }

    /**
     * Create a new Axion stake.
     *
     * @param {string|number} amount - Amount of axion to stake
     * @param {string|number} days - # of days to stake
     */
    async stake(amount, days) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.StakeMinter.methods
            .stake(amount, days)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(
                addTx({ 
                    id, 
                    description: `Stake ${(amount / 1e18).toLocaleString()} AXN for ${(+days).toLocaleString()} days` 
                })
            ));
    }

    /**
     * Withdraw a stake.
     *
     * @param {string|number} stakeID - The sessionID of the stake to withdraw
     */
    async withdraw(stakeID) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.StakeBurner.methods.burnStake(stakeID)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: `Withdraw Stake` })));
    }

    /**
     * Convert a stake to a NFT.
     *
     * @param {string|number} stakeID - The sessionID of the stake to convert
     * @param {string|number} cost - The cost in MATIC to convert the stake
     */
    async convertToNFT(stakeID, cost) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.StakeUtilities.methods.convertToNft(stakeID)
            .send({ from: Contract.account, gasPrice, value: cost * 1e18 })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: `Mint Stake NFT` })));
    }

    /**
     * Restake a matured stake. Optionally include additional AXN
     * as a topup to the stake,
     *
     * @param {object} stake - The stake object
     * @param {string|number} stakeDays - The amount of days for the restake
     * @param {string|number} topUp - The topup amount, or 0 if there is none
     */
    async restake(stake, stakeDays, topUp) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.StakeReminter.methods.remintStake(stake.id, stakeDays, topUp)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Restake Matured Stake' })));
    }

    /**
     * Upgrades a stake to max length (5,555 days) and adds any accumulated
     * interest to the principal.
     *
     * @param {string|number} stakeID - The sessionID of the stake to upgrade
     */
    async upgrade(stakeID) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.StakeUpgrader.methods.maxShareUpgrade(stakeID)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: `Upgrade Stake` })));
    }

    /**
     * Approve AXN spending for the Staking contract
     */
    async approve() {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.AXNContract.methods
            .approve(Contract.contracts.StakingContract.options.address, MaxUint256)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Stake Approval' })));
    }

    /**
     * Withdraws the selected div token from liquid rewards.
     *
     * @param {string} address - The address of the token to withdraw
     */
    async withdrawDivForToken(address) {
        const gasPrice = await this.getGasPrice();
        return Contract.contracts.VC.methods
            .withdrawDivTokens(address)
            .send({ from: Contract.account, gasPrice })
            .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
            .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Withdraw Rewards' })));
    }
    
    /**
    * Withdraws the selected div token from liquid rewards
    * to the provided address. (NOTE: Not currently in use)
    *
    * @param {string} address - The address of the token to withdraw
    * @param {string} to - The address to send the divs to
    */
    withdrawDivForTokenTo(address, to) {
       return Contract.contracts.VC.methods
           .withdrawDivTokensTo(to, address)
           .send({ from: Contract.account })
           .on('receipt', (payload) => dispatch(completedTx(payload.transactionHash)))
           .on('transactionHash', (id) => dispatch(addTx({ id, description: 'Withdraw Rewards External' })));
   }

    getVentureAuctionTokens() {
        return Contract.contracts.VC.methods.getDivTokens().call();
    }

    getVentureAuctionInterestEarned(tokenAddress) {
        return Contract.contracts.VC.methods.getTokenInterestEarned(Contract.account, tokenAddress).call();
    }

    async getVentureAuctionTokenInfo(tokenAddress) {
        const tokenContract = new Contract.web3.eth.Contract(ABI.ERC20.ABI, tokenAddress);
        const tokenName = await tokenContract.methods.name().call();
        const tokenSymbol = await tokenContract.methods.symbol().call();
        const tokenDecimals = +(await tokenContract.methods.decimals().call());

        return {
            tokenName,
            tokenSymbol,
            tokenDecimals,
        };
    }

    async getVentureAuctionDivs() {
        const vcaTokens = await this.getVentureAuctionTokens();
        const excludedTokens = [ZERO_ADDRESS, ABI.mainnet.Tokens.USDC?.toLowerCase() || ZERO_ADDRESS];
        const vcaDivs = [];

        if (vcaTokens) {
            for (const tokenAddress of vcaTokens.filter((x) => !excludedTokens.includes(x.toLowerCase()))) {
                
                // Get interest
                const interest = await this.getVentureAuctionInterestEarned(tokenAddress);
                let interestEarnedUSDC = 0;
                if (interest !== '0') {
                    try {
                        interestEarnedUSDC = await this.getTokenToUsdcAmountsOutAsync(tokenAddress, interest);
                    } catch (error) { console.log('Cannot get USD value for interest', error) }
                }

                // Check if Ethereum
                if (tokenAddress === ETHEREUM_TOKEN.tokenAddress) {
                    vcaDivs.push({
                        ...ETHEREUM_TOKEN,
                        interestEarnedToken: interest / 1e18,
                        interestEarnedUSDC: interestEarnedUSDC / 1e6,
                    });
                } else {
                    const { tokenName, tokenSymbol, tokenDecimals } = await this.getVentureAuctionTokenInfo(tokenAddress);
                    vcaDivs.push({
                        tokenName,
                        tokenSymbol,
                        tokenAddress,
                        tokenDecimals,
                        interestEarnedUSDC: interestEarnedUSDC / 1e6,
                        interestEarnedToken: interest / 10 ** tokenDecimals,
                    });
                }
            }
        }

        return vcaDivs;
    }

    getStakePayoutAndPenalty(stake) {
        const principal = Contract.web3.utils.toWei(`${stake.principal}`);
        const interest = Contract.web3.utils.toWei(`${stake.interest}`);
        return Contract.contracts.StakeBurner.methods.getPayoutAndPenalty(principal, stake.startSeconds, stake.stakeDays, interest).call();
    }
}

export default Staking;
