import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    iframe: {
        width: '100%',
        borderRadius: 5,
        aspectRatio: 16 / 9,
    },
}));

export default useStyles;
