import React from 'react';
import clsx from 'classnames';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const TypeSwitch = ({ active, onClick, basicText = 'Basic', advancedText = 'Advanced' }) => {
    const classes = useStyles();

    return (
        <div>
            <Typography>
                <span className={classes.options}>
                    <span
                        onClick={() => onClick('basic')}
                        className={clsx(classes.option, classes.basic, {
                            [classes.active]: active === 'basic',
                        })}
                    >
                        {basicText}
                    </span>
                    <span
                        onClick={() => onClick('advanced')}
                        className={clsx(classes.option, classes.advanced, {
                            [classes.active]: active === 'advanced',
                        })}
                    >
                        {advancedText}
                    </span>
                </span>
            </Typography>
        </div>
    );
};

export default TypeSwitch;
