import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useStyles from './styles';
import { cloneDeep } from 'lodash';
// import Button from 'components/Button';
import CustomTable from 'components/CustomTable';
import PledgeItem from './PledgeItem/PledgeItem';
import { Typography } from '@material-ui/core';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import Button from 'components/Button/Button';

const TableHeaders = [
    'Project',
    'Start',
    'End',
    ''
];

const translation = {
    Project: 'name',
    'Start': 'start',
    'End': 'end',
};

const PledgeTable = ({ data = [], userUSDC, refreshBalance, userPledges, refreshPledges }) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [expanded, setExpanded] = useState('');
    const [sort, setSort] = useState({ order: 'desc', orderBy: 'Start' });

    useEffect(() => {
        if (!expanded && items.length > 0)
            setExpanded(items.sort((a, b) => a.start - b.start)[items.length - 1]?.name);
    }, [items]);

    useEffect(() => {
        let ordered;
        const clonedStakes = cloneDeep(data);

        const key = translation[sort.orderBy];

        if (key === 'name')
            ordered = clonedStakes.sort((a, b) =>
                sort.order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key])
            );
        else
            ordered = clonedStakes.sort((a, b) =>
                sort.order === 'asc' ? b[key] - a[key] : a[key] - b[key]
            );

        setItems(ordered.splice(perPage * page, perPage));
    }, [data, sort, page, perPage]);

    // Pagination
    const handleChangePage =(e, page) => { setPage(page) }
    const handleChangeRows = (e) => { setPerPage(e.target.value) }
    const handleOnSort = (newOrder, index) => { setSort({ order: newOrder, orderBy: TableHeaders[index] }) }
    const handleOpenRow = (item) => {
        if (expanded === item) setExpanded('');
        else setExpanded(item);
    }

    return (
        <CustomTable
            page={page}
            count={data.length}
            onSort={handleOnSort}
            initialOrderIndex={1}
            rowsPerPage={perPage}
            headers={TableHeaders}
            pagination={items.length > 5}
            onChangePage={handleChangePage}
            onChangeRows={handleChangeRows}
            classes={{ root: classes.table }}
            rows={items.map((item, idx) => {
                if (!item) return { columns: SkeletonTableRow({ count: 8 }) };
                return {
                    expanded: expanded === item.name,
                    handleOpenRow: () => handleOpenRow(item.name),
                    expandedItemComponent: (
                        <PledgeItem 
                            item={item} 
                            userUSDC={userUSDC} 
                            userPledges={userPledges} 
                            refreshPledges={refreshPledges} 
                            refreshBalance={refreshBalance}
                        />
                    ),
                    columns: [
                        <Typography>{item.name}</Typography>,
                        <Typography>{moment.unix(item.start).format('MMM. DD, YYYY, h:mm A')}</Typography>,
                        <Typography>{moment.unix(item.end).format('MMM. DD, YYYY, h:mm A')}</Typography>,
                        <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                             <Button
                                fullWidth
                                size="small"
                                override="translucent"
                                onClick={() => handleOpenRow(idx)}
                                style={{transition: 'all 0.3s ease'}}
                            >{expanded === item.name ? "Hide Details" : "Show Details"}</Button>
                        </div>
                    ]
                };
            })}
        />
    );
};

export default PledgeTable;
