import React, { useContext, useMemo, useState } from 'react';
import useStyles from './styles';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { setChain } from 'redux/actions/chain';
import { ContractContext } from 'service/provider';
import { ETH_CHAIN, MATIC_CHAIN, BSC_CHAIN } from 'utils/variables';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { addPolygonToMetamask, requestChainSwitchETH, requestChainSwitchBSC, addBSCToMetamask } from 'utils/add-to-metamask';

const NetworkCard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { wallet } = useContext(ContractContext);
    const { chain } = useSelector(store => store);

    const chainName = useMemo(() => {
        if (chain === MATIC_CHAIN) return "Polygon"
        if (chain === ETH_CHAIN) return "Ethereum"
        if (chain === BSC_CHAIN) return "BSC"
        else return "Unknown"
    }, [chain]);

    const switchChain = async (e) => {
        if (e.target.value === "Ethereum") {
            await requestChainSwitchETH();
            dispatch(setChain(ETH_CHAIN));
            wallet.reset()
        } else if (e.target.value === "Polygon") {
            await addPolygonToMetamask();
            dispatch(setChain(MATIC_CHAIN))
            wallet.reset()
        } else if (e.target.value === "BSC") {
            try {
                await requestChainSwitchBSC();
                dispatch(setChain(BSC_CHAIN))
                wallet.reset()
            } catch (err) {
                if (err.code === 4902) {
                    await addBSCToMetamask();
                    await requestChainSwitchBSC();
                    dispatch(setChain(BSC_CHAIN))
                    wallet.reset()
                }
            }
        }
    }

    return (
        <div className={classes.column}>
            <TextField
                select
                fullWidth
                margin="dense"
                label="Network"
                variant="outlined"
                SelectProps={{
                    open: open,
                    value: chainName,
                    onChange: switchChain,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                    MenuProps: {
                        disableScrollLock: true,
                        getContentAnchorEl: null,
                        classes: { paper: classes.selectMenu },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                    },
                }}
            >
                {['Polygon', "Ethereum", "BSC"].map((network, idx) => (
                    <MenuItem disabled={network === chainName} value={network} key={idx}>
                        <Typography>{network}</Typography>
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default NetworkCard;
