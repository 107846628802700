import Web3 from "web3";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { POLYGON_RPC, ETH_CHAIN, MATIC_CHAIN, POLYGONSCAN_GAS_API } from "utils/variables";

const _getGweiPolygonscan = async () => {
    const response = await axios.get(POLYGONSCAN_GAS_API);
    return Math.round(parseInt(response.data.result, 16) / 1e9);
}

const _getGweiGasstation = async () => {
    const response = await axios.get(`https://gasstation-mainnet.matic.network/`);
    return Math.round(response.data.fast);
}

const _getGweiEtherscan = async () => {
    const response = await axios.get('https://ethgasstation.info/json/ethgasAPI.json');
    return Math.round(response.data.average / 10);
}

const _getGweiWeb3 = async (web3) => {
    const response = await web3.eth.getGasPrice();
    return Math.round(parseInt(response) / 1e9)
}

const _getGweiAPI = async (chain) => {
    let gwei = null;

    if (chain === ETH_CHAIN) {
        try { gwei = await _getGweiEtherscan() } 
        catch (err) { console.error('Error getting gwei from Etherscan', err) }
    }
    else {
        try {
            try { gwei = await _getGweiPolygonscan() } 
            catch (err) { 
                console.error('Error getting gwei from Polygonscan', err);
    
                try { gwei = await _getGweiGasstation() } 
                catch (err) {
                    console.error('Error getting gwei from Gasstation', err);
                    return gwei;
                }
            }
            
            if (!gwei) gwei = await _getGweiGasstation()
        } 
        catch (err) { console.error('Error getting gwei from polygon', err) }
    }

    return gwei;
}

/**
 * Hook that will get the current gas price
 */
const useGasPrice = () => {
    const [web3, setWeb3] = useState(null);
    const [gasPrice, setGasPrice] = useState(30);
    const { chain } = useSelector(store => store);

    useEffect(() => {
        if (chain === MATIC_CHAIN) 
            setWeb3(new Web3(POLYGON_RPC))
        else setWeb3(null)
    }, [chain])

    useEffect(() => {  
        const _getGasPrice = async () => {
            let gwei = 30;
            if (web3) {
                try { gwei = await _getGweiWeb3(web3) } 
                catch { gwei = await _getGweiAPI(chain) }
            } else gwei = await _getGweiAPI(chain);
            setGasPrice(Math.max(30, gwei));
        }
        
        _getGasPrice();
        const interval = setInterval(() => { _getGasPrice() }, 15000);
        return () => clearInterval(interval);
    }, [web3, chain]); 

    return gasPrice;
};

export default useGasPrice;
