import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 10,
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255, 255, 255, .05)',
        boxShadow: '4px 4px 12px rgb(0 0 0 / 20%)',
    },
    noTopRadius: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    noBottomRadius: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    row: {
        padding: theme.spacing(2),
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    titleText: {
        fontWeight: 300,
        fontSize: '1.1rem',
        color: theme.brand.SecondaryText,

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.05rem',
        },
    },
    bigTitle: {
        fontSize: '1.4rem',
        padding: theme.spacing(0.75),

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
    subtitle: {
        fontSize: '0.85rem',
    },
}));

export default useStyles;
