import React, { useContext } from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { ContractContext } from 'service/provider';
import useCheckKYC from 'hooks/useCheckKYC';
import KycStatus from 'components/KycStatusBox/KycStatus';

const PageTitle = () => {
    const classes = useStyles();
    const { wallet } = useContext(ContractContext);
    
    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Axion Pledge Engine
                </Typography>
                <br />
                <Typography>
                    The table below will show any projects that are currently loaded into the Axion Pledge Engine.
                    <br className={classes.brOnLarge} />
                    For each project, it will inform you of your allocation based on {wallet?.account ? "your" : "a"} connected wallet{wallet?.account && ` (${wallet.account.shortenAddress()})`}.&nbsp;
                </Typography>
            </div>
            <KycStatus />
        </div>
    );
};

export default PageTitle;
